

































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "../components/BaseComponent";
import MenuComponent from "../components/MenuComponent.vue";
import AppCuesComponent from "../components/AppCuesComponent.vue";
import MobileNavComponent from "../components/MobileNavComponent.vue";
import AccountSelectorComponent from "../components/AccountSelectorComponent.vue";
import FooterComponent from "../components/FooterComponent.vue";
import LogoComponent from "../components/LogoComponent.vue";

@Component({
  components: {
    MenuComponent,
    AccountSelectorComponent,
    LogoComponent,
    FooterComponent,
    AppCuesComponent,
    MobileNavComponent,
  },
})
export default class NavComponent extends BaseComponent {
  @Prop()
  private menu: any;
  @Prop()
  private isError: any;
}
