




import { isFeatureEnabledForCurrentUser } from '@/lib/shared';
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as features from '../lib/feature-constants';
import BaseComponent from './BaseComponent';

@Component
export default class LogoComponent extends BaseComponent {
  @Prop() public isMobile: any;

  get logoHref(): string {
    if (isFeatureEnabledForCurrentUser(features.insightsMenu, this.currentUser)) {
      return "/#/insights";
    }
    return "/#/projects";
  }
}
