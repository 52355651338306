
































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CurrentUser, MenuMeta } from '../store/models';
import { getCurrentUser, performLogin, setCurrentOrg, forgotPasswordEmail, changePassword } from '../store/rest.service';
import { rules } from '../lib/validation';

@Component
export default class LoginComponent extends Vue {
  private username: string = '';
  private password: string = '';
  private rules = rules;
  private isFailedLogin: boolean = false;
  private isLoggingIn: boolean = false;

  private isChangingPassword: boolean = false;
  private isEnteringEmail: boolean = false;
  private changedPasswordConfirm: string = '';
  private changedPassword: string = '';
  private changePasswordEmail: string = '';
  private changePasswordCode: string = '';
  private changePasswordMessage: string = '';

  private login() {
    if ((this.$refs.form as any).validate()) {
      this.performLogin();
    }
  }

  private performLogin() {
    this.isLoggingIn = true;
    performLogin(this.username, this.password).then((response) => {
      // Check to see if we are being redirected using the location header.
      if (response !== '') {
        window.location.href = response;
      } else {
        this.loginCheck(true);
      }
    }).catch((error) => {
      // Check for 403 - user's account has been reset and a new password must be set.
      // Check for 409 - password has expired and a new password must be set.
      const response = error.response || null;
      if (response ? (response.status === 409 || response.status === 403) : false) {
        this.changePassword();
        this.changePasswordMessage = 'A new password must be set. ' + (response.headers['X-Message'] || '');
      } else {
        this.loginCheck(true);
      }
    });
  }

  private loginCheck(isFailedLogin: boolean) {
    this.isFailedLogin = isFailedLogin;
    getCurrentUser().then((response) => {
      this.isFailedLogin = false;
      this.$emit('login-success', response);
    }).catch((error) => {
      this.$emit('login-failed');
    }).finally(() => {
      this.isLoggingIn = false;
    });
  }

  private resetForm() {
    (this.$refs.form as any).reset();
    this.changePasswordMessage = '';
  }

  private changePassword() {
    this.isEnteringEmail = true;
    this.resetForm();
  }

  private submitChangedPassword() {
    if ((this.$refs.form as any).validate()) {
      if (this.changedPassword !== this.changedPasswordConfirm) {
        this.changePasswordMessage = 'The entered passwords do not match.';
      } else {
        // Call end-point to set password.
        changePassword(this.changePasswordCode, this.changePasswordEmail, this.changedPassword).then((response) => {
          this.isChangingPassword = false;
          this.resetForm();
          this.changePasswordMessage = 'Password successfully changed.';
        }).catch((error) => {
          const firstError: any = error.response.data.errors[0];
          this.changePasswordMessage =
          firstError.expiredCode ? firstError.expiredCode.message
          : firstError.codeMismatch ? firstError.codeMismatch.message
          : firstError.jsonSchemaValidationError ? firstError.jsonSchemaValidationError.message
          : 'There was a problem changing your password.';
        });
      }
    }
  }

  private submitChangePasswordEmail() {
    if ((this.$refs.form as any).validate()) {
      // Call end-point to request new password for email address.
      forgotPasswordEmail(this.changePasswordEmail).then((response) => {
        this.isEnteringEmail = false;
        this.isChangingPassword = true;
        const email = this.changePasswordEmail;
        this.resetForm();
        this.changePasswordEmail = email;
      }).catch((error) => {
        const firstError: any = error.response.data.errors[0];
        this.changePasswordMessage =
        firstError.noSuchUserId ? firstError.noSuchUserId.message
        : firstError.codeDeliveryFailure ? firstError.codeDeliveryFailure.message
        : firstError.limitExceeded ? firstError.limitExceeded.message
        : 'There was a problem sending the password recovery email.';
      });
    }
  }

  private cancelChangePassword() {
    this.isChangingPassword = false;
    this.isEnteringEmail = false;
    this.resetForm();
  }
}
