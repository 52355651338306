import Vue from 'vue';
import Component from 'vue-class-component';
import { rules } from '../lib/validation';
import { noDataText } from '../lib/autocomplete';
import { mapSearchParams, removeCommonProperties, formatCurrency, formatInt, formatToNumber, getRefTypeNameFromCode, getDiversityNameFromCode, setCurrentUser, zeroIfUndefined, getDisplayNameFromShortRecords, isRoleEnabled, getNistoProfilesConnectionClass, isFeatureEnabledForCurrentUser, isFeatureEnabled } from '../lib/shared';
import { CurrentUser, UserInfo, ShortRecord } from '../store/models';
import { getCurrentUser, setCurrentOrg } from '../store/rest.service';
import { timeZoneAbbreviation, dateTimeFormat, dateFormat } from '../lib/datetime';
import * as colors from '../lib/colors';
import { getIconUrl, getImageUrl } from '../lib/images';
import * as features from '../lib/feature-constants';
import * as _ from 'lodash';

@Component({})
export default class BaseComponent extends Vue {

    // React to changes in the Current User / Org.
    public currentUser: CurrentUser = this.$store.getters['session/CURRENT_USER'] as CurrentUser;
    public currentOrg: string = this.$store.getters['local/CURRENT_ORG'];
    public features = features;

    // Colors.
    protected colors = colors;

    // Validation rules.
    protected rules = rules;

    // Autocomplete no data text.
    protected noDataText = noDataText;

    // Currency format.
    protected formatCurrency = formatCurrency;

    // Locale timezone.
    protected timeZoneAbbreviation = timeZoneAbbreviation;

    // Format for dates.
    protected dateFormat: string = dateFormat;

    // Format for dateTimes.
    protected dateTimeFormat: string = dateTimeFormat;

    // Integer format.
    protected formatInt = formatInt;

    // Number format.
    protected formatToNumber = formatToNumber;
    protected zeroIfUndefined = zeroIfUndefined;

    // Pagination etc.
    protected mapSearchParams = mapSearchParams;

    // Common properties.
    protected removeCommonProperties = removeCommonProperties;

    // Reference data.
    protected getRefTypeNameFromCode = getRefTypeNameFromCode;
    protected getDiversityNameFromCode = getDiversityNameFromCode;
    protected getDisplayNameFromShortRecords = getDisplayNameFromShortRecords;

    // Nisto Profiles.
    protected getNistoProfilesConnectionClass = getNistoProfilesConnectionClass;

    // Images.
    protected getIconUrl = getIconUrl;
    protected getImageUrl = getImageUrl;

    // If this component should only be displayed if the user has a specific feature, then override here.
    protected feature: string = '';

    // Base route to redirect to when changing current organization - can be overidden if the route is the same as the current route.
    protected baseRoute: string = '';
    private unWatch: any;

    // Check if role is enabled.
    public isRoleEnabled(role: string) {
        return isRoleEnabled (this.$store.getters['session/CURRENT_USER'] as CurrentUser, role);
    }

    // Returns whether the component should be displayed, based on enabled user features.
    public isEnabled() {
        return this.feature === '' ? true : this.isFeatureEnabled(this.feature);
    }

    // Check if feature is enabled.
    public isFeatureEnabled(feature: string, enabledFeatures: string[]|null = null) {
        return enabledFeatures ? isFeatureEnabled(feature, enabledFeatures) : isFeatureEnabledForCurrentUser(feature, this.$store.getters['session/CURRENT_USER'] as CurrentUser);
    }

    // Returns whether the current user is a member of a given organization.
    public isMemberOf(orgId: string): boolean {
        return this.currentUser.userinfo.memberOf ? this.currentUser.userinfo.memberOf.some((memberOf: any) => memberOf['identifier'] === orgId) : false;
    }

    // Returns whether the current user is an admin for a given organization.
    public isAdminFor(orgId: string): boolean {
        return this.currentUser.personRecord.person.adminFor ? this.currentUser.personRecord.person.adminFor.some((adminFor: any) => adminFor['identifier'] === orgId) : false;
    }

    // Returns whether the current user is an admin for a given organization.
    public isPlannerForCurrentOrg(): boolean {
        return this.currentUser.personRecord.person.plannerFor ? this.currentUser.personRecord.person.plannerFor.some((plannerFor: any) => plannerFor['identifier'] === this.currentOrg) : false;
    }

    // Test whether this is the case where a component must be loaded in new mode.
    public isNewRoute(): boolean {
        return this.$route.path.endsWith('/new');
    }

    // Force a route change by appending a query string to the route which is incremented each time.
    public changeRoute(path: string, forceReload: boolean = false) {
        if (this.$route.path === path && forceReload) {
            let counter: number = +this.$route.query['route'];
            counter = isNaN(counter) ? 0 : counter;
            path += '?route=' + (counter += 1).toString();
        }
        this.$router.push({ path }).catch((err) => {});
    }

    public getCurrentOrgName(currentOrgId: string): string {
        const org = this.getCurrentOrg(currentOrgId) as ShortRecord;
        const orgName: string = org ? org.displayName as string : '';
        return this.splitOrgName(orgName);
    }

    public getCurrentOrg(currentOrgId: string): ShortRecord {
        return((this.currentUser.userinfo.memberOf as ShortRecord[]).find((x) => x.identifier === currentOrgId) as ShortRecord);
    }

    public splitOrgName(orgName: string): string {
        if (orgName as string !== '') {
          const afterName: string[] = orgName.split (' - ');
          return afterName.length > 1 ? afterName[1] as string : afterName[0] as string;
        } else {
          return '';
        }
    }

    protected changeCurrentOrg() {
        if (this.baseRoute !== '') {
            this.navigateRoute(this.baseRoute);
        }
    }

    protected navigateRoute(route: string) {
        this.$router.push(route).catch((err) => { });
    }

    protected newItem() {

    }

    protected authRequired() {
        return this.baseRoute !== '' && this.baseRoute.indexOf("/external/") === -1;
    }

    protected mounted() {
        // Watch for changes to the Current Organization and refresh the views.
        if (this.$store && this.authRequired()) {
            this.unWatch = this.$store.watch(
                (state, getters) => getters['session/CURRENT_USER'],
                (newValue, oldValue) => {
                    this.currentUser = this.$store.getters['session/CURRENT_USER'] as CurrentUser;
                    this.currentOrg = this.$store.getters['local/CURRENT_ORG'];
                    if ((newValue.userinfo as UserInfo).currentOrg !== (oldValue.userinfo as UserInfo).currentOrg) {
                        this.changeCurrentOrg();
                    }
                },
            );
        }
        if (this.isNewRoute()) {
            this.newItem();
        }
    }

    protected beforeDestroy() {
        if (this.unWatch) {
            this.unWatch();
        }
    }

    protected getCurrentUser() {
        getCurrentUser().then((response) => {
            setCurrentUser(this.$store, (response as CurrentUser));
        }).catch((error) => {
            this.$emit('error', error);
        });
    }

    protected setCurrentOrg(orgId: string) {
        setCurrentOrg(orgId).then((response) => {
            this.getCurrentUser();
            }).catch((error) => {
            this.$emit('error', error);
        });
    }

    protected isReportVisible(reportName: string): boolean {
        return (this.isFeatureEnabled(`${reportName}-enabled`) || this.isFeatureEnabled(`${reportName}-disabled`));
      }
}
