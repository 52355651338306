import Vue from 'vue';
import router from './router';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import Vue2Filters from 'vue2-filters';
import './stylus/main.styl';
import App from './App.vue';
import { store } from './store/VuexStore.js';
import VueAnalytics from 'vue-analytics';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import clickout from 'clickout-event';

Vue.use(Vuetify, {
  theme: {
    primary: '#18374B',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#CD4158',
    info: '#18374B',
    success: '#4CAF50',
    warning: '#FFC107',
    button: '#CD4158',
  },
});

Vue.use(VueMoment, {
  moment,
});

Vue.use(Vue2Filters);

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  router,
});

Vue.filter('moment_custom', (value: any, format: any) => {
  if (value === null || value === undefined || format === undefined) {
    return '';
  }
  if (format === 'from') {
    return moment(value).fromNow();
  }
  return moment(value).format(format);
});

Vue.use(clickout);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
