import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import createMutationsSharer from 'vuex-shared-mutations';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex-nistolink',
  storage: window.localStorage,
  modules: ['local'],
})

const vuexSessionStorage = new VuexPersist({
  key: 'vuex-nistolink',
  storage: window.sessionStorage,
  modules: ['session'],
})

const sessionStore = {
  namespaced: true,
  state: {
    currentUser: {},
    logOut: false,
    filterDates: { dateFrom: '', dateTo: '' },
    pagingState: { route: '', pagination: { sortBy: '', descending: false, page: 0, rowsPerPage: 0 } },
  },
  getters : {
    CURRENT_USER : state => {
      return state.currentUser;
    },
    LOG_OUT : state => {
      return state.logOut;
    },
    FILTER_DATES : state => {
      return state.filterDates;
    },
    PAGING_STATE : state => {
      return state.pagingState;
    },
  },
  mutations: {
    SET_CURRENT_USER : (state, payload) => {
      state.currentUser = payload
    },
    SET_FILTER_DATES : (state, payload) => {
      state.filterDates = payload
    },
    SET_PAGING_STATE : (state, payload) => {
      state.pagingState = payload
    },
  },
  actions : {},
}

const localStore = {
  namespaced: true,
  state: {
    currentOrganization: '',
    tandcAccepted: false,
  },
  getters : {
    CURRENT_ORG : state => {
      return state.currentOrganization;
    },
    TANDC_ACCEPTED : state => {
      return state.tandcAccepted;
    },
  },
  mutations: {
    SET_CURRENT_ORG : (state, payload) => {
      state.currentOrganization = payload
    },
    SET_TANDC_ACCEPTED : (state, payload) => {
      state.tandcAccepted = payload
    },
  },
  actions : {},
}

export const store = new Vuex.Store({
  modules: {
    session: sessionStore,
    local: localStore,
  },
  plugins: [vuexSessionStorage.plugin, vuexLocalStorage.plugin, createMutationsSharer({ predicate: ['local/SET_CURRENT_ORG', 'session/SET_FILTER_DATES'] })],
})