import {
  Person,
  PersonMeta,
  Meta,
  VendorMeta,
  OrganizationMeta,
  AddressMeta,
  UserMeta,
  Vendor,
  IndigenousCommunity,
  SearchParams,
  ResourceSearchResult,
  SearchResults,
  Benefit,
  SearchExpenses,
  BenefitMeta,
  VerificationMeta,
  BenefitState,
  BenefitStateMeta,
  SearchIndigenousEmployments,
  Project,
  ProjectMeta,
  ImpactTargetsMeta,
  SearchSubProjects,
  SearchFacet,
  SearchFacetDetail,
  Spend,
  SpendMeta,
  TotalsAggregate,
  TotalsAggregateValue,
  TotalsAggregateValueEmployment,
  TotalsAggregateValueTnb,
  SocialProcurementTotalsAggregate,
  TnbTotalsAggregate,
  EmploymentTotalsAggregate,
  Expense,
  ExpenseMeta,
  PaymentMeta,
  RevenueMeta,
  ProjectVendor,
  ProjectVendorMeta,
  ServicesMeta,
  TotalsItem,
  GeneralSearchCriteria,
  Customer,
  CustomerMeta,
  OccupationAggregate,
  StackedBarMeta,
  TreeMapMeta,
  CommunityInvestmentTreeMap,
  LineChartMeta,
  UserAccountSettings,
  BulletGraphMeta,
  PurchaseOrderMeta,
  ProjectPropertiesMeta,
  DiversityDeclarationsMeta,
  AffiliatedVendorsMeta,
  CategoryAggregateTotalsValue,
  PayeeSearchCriteria,
  DiversityDeclarationMeta,
  Employment,
  DiversityMeta,
  EmploymentMeta,
  ReportDataTable,
  ReportDataTableRow,
  AggSearchCriteria,
  ImageUrlsMeta,
  TargetDataTable,
  TargetDataTableItem,
  FacetDetailMeta,
  IndigenousTotalsAggregate,
  OccupationDiversityMeta,
  TotalsAggregateValueOccupation,
  ProjectSearchCriteria,
  DonutMeta,
  EIRData,
} from "./models";
import * as _ from "lodash";

export const EmptyDiversityDeclarationMeta: DiversityDeclarationMeta = {
  values: [],
};
export const EmptyDiversityDeclarationsMeta: DiversityDeclarationsMeta = {
  indigenousImpact: EmptyDiversityDeclarationMeta,
  impactESG: EmptyDiversityDeclarationMeta,
  impactStatus: EmptyDiversityDeclarationMeta,
  impactSocial: EmptyDiversityDeclarationMeta,
  socialFocusedOrgs: EmptyDiversityDeclarationMeta,
};
export const EmptyImpactTargetsMeta: ImpactTargetsMeta = {
  diverseCommunityContribution: 0,
  diverseEmployment: 0,
  diverseProjectBenefit: 0,
  socialProcurement: 0,
  indigenousProjectBenefit: 0,
  indigenousCommunityContribution: 0,
  indigenousEmployment: 0,
};
export const EmptyVendorMeta: VendorMeta = {
  impactTargets: EmptyImpactTargetsMeta,
};
export const EmptyAddressMeta: AddressMeta = {
  addressCode: "primary",
  addressName: "Primary",
  addrLines: [""],
  countryName: "",
  orgCity: "",
  postalCode: "",
  stateOrProvince: "",
};
export const EmptyOrganizationMeta: OrganizationMeta = {
  addresses: [EmptyAddressMeta],
};
export const EmptyVendor: Vendor = {
  organization: EmptyOrganizationMeta,
  vendor: EmptyVendorMeta,
  diversityDeclarations: EmptyDiversityDeclarationsMeta,
};
export const EmptyIndigenousCommunity: IndigenousCommunity = {
  common: {
    recordType: ["Organization", "IndigenousCommunity", "Beneficiary"],
  },
  organization: {},
  IndigenousCommunity: {},
};
export const EmptyCustomerMeta: CustomerMeta = {};
export const EmptyCustomer: Customer = {
  customer: EmptyCustomerMeta,
};
export const EmptyMeta: Meta = {
  id: "",
  recordType: "",
  status: "",
  version: "1",
  sortKey: "",
  createdTime: "",
  lastUpdated: "",
  etag: "",
};
export const EmptyUserMeta: UserMeta = {
  userId: "",
  // emailValidated: false,
  password: "",
};
export const EmptyPersonMeta: PersonMeta = {
  email: "",
  adminFor: [],
  contactFor: [],
  memberOf: [],
  projectAccess: [],
  description: "",
  familyName: "",
  givenNames: "",
  phone: "",
};
export const EmptyPerson: Person = {
  common: {
    // identifier: '',
    recordType: ["Person"],
  },
  person: EmptyPersonMeta,
  // user: {},
};
export const EmptyPayeeSearchCriteria: PayeeSearchCriteria = {
  clientsOf: "",
  payeesOf: "",
  payersOf: "",
  subVendorsOf: "",
};
export const EmptySearchParams: SearchParams = {
  page: "1",
  ipp: "10",
  sortby: "default",
  sortdir: "asc",
  terms: "",
};
export const EmptySearchResults: SearchResults = {
  resultMeta: {
    matched: 0,
    included: 0,
  },
  results: [],
};
export const EmptyResourceSearchResult: ResourceSearchResult = {
  searchResults: EmptySearchResults,
};
export const EmptySearchFacetDetail: SearchFacetDetail = {
  label: "",
  code: "",
  allSelected: true,
  facets: [],
};
export const EmptySearchFacet: SearchFacet = {
  label: "",
  value: "",
  path: "",
  count: 0,
  isSelected: false,
};
// Search Benefits.
export const EmptySearchExpenses: SearchExpenses = {
  expenseClasses: [],
  projects: [],
  beneficiaries: [],
  payees: [],
  payers: [],
  unsdg: [],
  dateFrom: "",
  dateTo: "",
};
export const EmptyAggSearchCriteria: AggSearchCriteria = {
  hasPayee: "",
  hasPayer: "",
  hasProject: "",
  hasBeneficiary: "",
  isClient: false,
};
export const EmptyProjectBenefitMeta: BenefitMeta = {
  benefitKind: "",
  benefitInvestment: undefined,
  benefitInvestmentArea: "",
  benefitInvestmentCategory: "",
  benefitId: "",
  description: "",
  benefitAmount: null,
  benefitCurrency: "CAD",
  benefitDate: "",
  benefitCategory: "project-benefit",
  hasBeneficiary: "",
  hasProject: "",
  hasSubmitter: "",
  hasPayer: "",
  hasPayee: "",
};
export const EmptyCommunityInvestmentBenefitMeta: BenefitMeta = {
  benefitKind: "",
  benefitInvestment: undefined,
  benefitInvestmentArea: "",
  benefitInvestmentCategory: "",
  benefitId: "",
  description: "",
  benefitAmount: null,
  benefitCurrency: "CAD",
  benefitDate: "",
  benefitCategory: "community-investment",
  hasBeneficiary: "",
  // hasProject: '',
  hasSubmitter: "",
  hasPayer: "",
  hasPayee: "",
};
export const EmptyVerificationMeta: VerificationMeta = {
  hasVerifier: "",
  verifiedTime: "",
  verifierComment: "",
  submittedTime: "",
  submitterComment: "",
  verificationState: "submitted",
};
export const EmptyProjectBenefit: Benefit = {
  benefit: EmptyProjectBenefitMeta,
};
export const EmptyCommunityInvestment: Benefit = {
  benefit: EmptyCommunityInvestmentBenefitMeta,
};
export const EmptyBenefitStateMeta: BenefitStateMeta = {
  newState: "",
  comment: "",
  submitter: "",
};
export const EmptyBenefitState: BenefitState = {
  benefitState: EmptyBenefitStateMeta,
};

// Search Indigenous Employments.
export const EmptySearchIndigenousEmployments: SearchIndigenousEmployments = {
  project: "",
  organizations: [],
  jobFocuses: [],
  dateFrom: "",
  dateTo: "",
};
/*
export const EmptyIndigenousEmploymentMeta: IndigenousEmploymentMeta = {
  employmentComment: 'No comment.',
  employmentCurrency: 'CAD',
  employmentDate: '',
  hasCommunityAffiliation: '',
  hasEmployer: '',
  hasProject: '',
  hasSubmitter: '',
  hourlyRate: 0,
  hoursPerDay: 8,
  isTraining: false,
  occupation: '',
  totalEmployees: 1,
  daysWorked: 1,
  employmentValue: null,
  diversityCode: '',
};
*/
// Search Projects.
export const EmptyProjectSearchCriteria: ProjectSearchCriteria = {
  clients: [],
  statuses: [],
  organizations: [],
};
export const EmptySearchSubProjects: SearchSubProjects = {
  parentProject: "",
};
export const EmptyProjectPropertiesMeta: ProjectPropertiesMeta = {
  branch: "",
  businessUnit: "",
  region: "",
  province: "",
  discipline: "",
};
export const EmptyProjectMeta: ProjectMeta = {
  projectId: "",
  projectName: "",
  ownerVendor: "",
  hasSubmitter: "",
  startDate: "",
  hasContact: [],
  impactTargets: EmptyImpactTargetsMeta,
};
export const EmptyPurchaseOrderMeta: PurchaseOrderMeta = {
  poAmount: 0,
  poCurrency: "CAD",
  poDate: "",
  poId: "",
};
export const EmptyProject: Project = {
  project: EmptyProjectMeta,
  purchaseOrder: EmptyPurchaseOrderMeta,
  properties: EmptyProjectPropertiesMeta,
};
export const EmptyAffiliatedVendorsMeta: AffiliatedVendorsMeta = {
  isLocal: false,
  vendorCode: "",
  vendorId: "",
  vendorName: "",
  isInvited: false,
  indigenousPartner: "",
};
export const EmptyProjectVendorMeta: ProjectVendorMeta = {
  projectId: "",
  vendorId: "",
  isInvited: false,
  isLocal: false,
};
export const EmptyProjectVendor: ProjectVendor = {
  projectVendor: EmptyProjectVendorMeta,
};
export const EmptyExpenseMeta: ExpenseMeta = {
  expenseClass: "",
  expenseId: "",
  expenseKind: "",
  expenseType: "",
};
export const EmptyEmploymentExpenseMeta: ExpenseMeta = {
  expenseClass: "employment",
  expenseId: "N/A",
  expenseKind: "cash",
  expenseType: "project-employment",
};
export const EmptyPaymentMeta: PaymentMeta = {
  amount: null,
  currency: "CAD",
  effectiveDate: "",
  hasPayer: "",
  hasPayee: "",
  hasProject: "",
  // hasProvisionalCreator: "",
  hasSubmitter: "",
  // hasBeneficiary: "",
  description: "",
};
export const EmptyRevenueMeta: RevenueMeta = {
  revenueClass: "",
  revenueId: "",
  revenueKind: "",
  revenueType: "",
};
export const EmptyDiversityMeta: DiversityMeta = {
  hasCommunityAffiliation: "",
  diversityCode: "",
};
export const EmptyEmploymentMeta: EmploymentMeta = {
  daysWorked: 1,
  hourlyRate: 0,
  hoursPerDay: 0,
  isTraining: false,
  totalEmployees: 1,
};
export const EmptyExpense: Expense = {
  expense: EmptyExpenseMeta,
  payment: EmptyPaymentMeta,
  revenue: EmptyRevenueMeta,
};
export const EmptyEmployment: Employment = {
  expense: EmptyEmploymentExpenseMeta,
  payment: EmptyPaymentMeta,
  employment: EmptyEmploymentMeta,
};
export const EmptySpendMeta: SpendMeta = {
  description: "",
  spendType: "",
  spendId: "",
  spendDate: "",
  spendAmount: null,
  spendCurrency: "CAD",
  hasPayer: "",
  hasBeneficiary: "",
  hasProject: "",
  hasSubmitter: "",
};
export const EmptyServicesMeta: ServicesMeta = {
  servicesRevenueType: "",
  servicesSpendType: "",
};
export const EmptySpend: Spend = {
  expense: EmptyExpenseMeta,
  payment: EmptyPaymentMeta,
  services: EmptyServicesMeta,
};
// General search citeria for various types of search.
export const EmptyGeneralSearchCriteria: GeneralSearchCriteria = {
  expenseTypes: [],
  filters: [],
  facets: [],
  projects: [],
  subVendors: [],
  organizations: [],
  clients: [],
  locations: [],
  jobFocuses: [],
  beneficiaries: [],
  dateFrom: "",
  dateTo: "",
};
export const EmptyTotalsAggregateValue: TotalsAggregateValue = {
  value: 0,
  valueFormatted: "0",
  percentage: 0,
  percentageFormatted: "0%",
};
export const EmptyCurrencyTotalsAggregateValue: TotalsAggregateValue = {
  value: 0,
  valueFormatted: "$0",
  percentage: 0,
  percentageFormatted: "0%",
};
export const EmptyTotalsAggregateValueOccupation: TotalsAggregateValueOccupation = {
  averageRate: EmptyCurrencyTotalsAggregateValue,
  totalManHours: EmptyTotalsAggregateValue,
  totalWages: EmptyCurrencyTotalsAggregateValue,
};
export const EmptyTotalsAggregateValueEmployment: TotalsAggregateValueEmployment = {
  hours: EmptyTotalsAggregateValue,
  wages: EmptyCurrencyTotalsAggregateValue,
  headCount: EmptyTotalsAggregateValue,
};
export const EmptyTotalsAggregateValueTnb: TotalsAggregateValueTnb = {
  diverse: EmptyTotalsAggregateValue,
  indigenous: EmptyTotalsAggregateValue,
};
export const EmptyTnbTotalsAggregate: TnbTotalsAggregate = {
  total: EmptyTotalsAggregateValue,
  overall: EmptyTotalsAggregateValueTnb,
  projectBenefits: EmptyTotalsAggregateValueTnb,
  communityContributions: EmptyTotalsAggregateValueTnb,
  employment: EmptyTotalsAggregateValueTnb,
};
export const EmptyOccupationDiversityMeta: OccupationDiversityMeta = {
  occupation: "",
  occupationDescription: null,
  overall: EmptyTotalsAggregateValueOccupation,
  diverseUnknown: EmptyTotalsAggregateValueOccupation,
  diverseNoAnswer: EmptyTotalsAggregateValueOccupation,
  diverseNo: EmptyTotalsAggregateValueOccupation,
  indigenous: EmptyTotalsAggregateValueOccupation,
  diverse: EmptyTotalsAggregateValueOccupation,
  indigenousAndDiverse: EmptyTotalsAggregateValueOccupation,
  unknownAndNoAnswer: EmptyTotalsAggregateValueOccupation,
};
export const EmptyEmploymentTotalsAggregate: EmploymentTotalsAggregate = {
  overall: EmptyTotalsAggregateValueEmployment,
  diverseUnknown: EmptyTotalsAggregateValueEmployment,
  diverseNoAnswer: EmptyTotalsAggregateValueEmployment,
  diverseNo: EmptyTotalsAggregateValueEmployment,
  indigenous: EmptyTotalsAggregateValueEmployment,
  diverse: EmptyTotalsAggregateValueEmployment,
  indigenousAndDiverse: EmptyTotalsAggregateValueEmployment,
  unknownAndNoAnswer: EmptyTotalsAggregateValueEmployment,
  occupations: [],
};
export const EmptyCategoryAggregateTotalsValue: CategoryAggregateTotalsValue = {
  goods: EmptyTotalsAggregateValue,
  services: EmptyTotalsAggregateValue,
  equipment: EmptyTotalsAggregateValue,
  unknown: EmptyTotalsAggregateValue,
  total: EmptyTotalsAggregateValue,
};
export const EmptyIndigenousTotalsAggregate: IndigenousTotalsAggregate = {
  directIndigenousSpend: EmptyTotalsAggregateValue,
  certifiedBusinessSpend: EmptyTotalsAggregateValue,
  communityOwnedSpend: EmptyTotalsAggregateValue,
  relationshipSpend: EmptyTotalsAggregateValue,
  selfDeclaredBusinessSpend: EmptyTotalsAggregateValue,
};
export const EmptySocialProcurementTotalsAggregate: SocialProcurementTotalsAggregate = {
  overall: EmptyTotalsAggregateValue,
  esb: EmptyTotalsAggregateValue,
  esbDiverse: EmptyTotalsAggregateValue,
  esbIndigenous: EmptyTotalsAggregateValue,
  localSpend: EmptyTotalsAggregateValue,
  smeSpend: EmptyTotalsAggregateValue,
  sociallyFocusedSpend: EmptyTotalsAggregateValue,
  thirdPartyCertifiedSpend: EmptyTotalsAggregateValue,
  expenseDiversityCategories: {
    esb: EmptyCategoryAggregateTotalsValue,
    esbDiverse: EmptyCategoryAggregateTotalsValue,
    esbIndigenous: EmptyCategoryAggregateTotalsValue,
    localSpend: EmptyCategoryAggregateTotalsValue,
    smeSpend: EmptyCategoryAggregateTotalsValue,
    sociallyFocusedSpend: EmptyCategoryAggregateTotalsValue,
    thirdPartyCertifiedSpend: EmptyCategoryAggregateTotalsValue,
    overall: EmptyCategoryAggregateTotalsValue,
    unknown: EmptyCategoryAggregateTotalsValue,
  },
};
export const EmptyReportDataTableRow: ReportDataTableRow = {
  children: [],
  data: [],
};
export const EmptyReportDataTable: ReportDataTable = {
  title: "",
  headers: [],
  parentHeaders: [],
  rows: [],
  totalRow: EmptyReportDataTableRow,
  total: EmptyTotalsAggregateValue,
  totalTitle: "",
  color: "",
  hideHeaders: false,
  pageBreakAfter: false,
  parentsBold: true,
  hideIfZeroTotal: false,
  className: "",
  autoCalculateTotalRow: false,
  totalText: "TOTAL",
};
export const EmptyTargetDataTable: TargetDataTable = {
  isFixedTargetLine: false,
  className: "",
  targets: [],
};
export const EmptyTargetDataTableItem: TargetDataTableItem = {
  children: [],
  color: "",
  percentage: 0,
  target: 0,
  title: "",
};
export const EmptyFacetDetailMeta: FacetDetailMeta = {
  identifier: "",
  displayName: "",
  recordType: [],
};
export const EmptyTotalsAggregate: TotalsAggregate = {
  facetDetail: EmptyFacetDetailMeta,
  totalProjectExpenses: EmptyTotalsAggregateValue,
  totalProjectRevenue: EmptyTotalsAggregateValue,
  socialProcurement: EmptySocialProcurementTotalsAggregate,
  diverse: EmptySocialProcurementTotalsAggregate,
  indigenous: EmptyIndigenousTotalsAggregate,
  employment: EmptyEmploymentTotalsAggregate,
  tnb: EmptyTnbTotalsAggregate,
};
export const EmptyOccupationAggregate: OccupationAggregate = {
  occupationName: "",
  occupationCode: "",
  totalEmploymentValue: 0,
  totalIndigenousEmploymentValue: 0,
  totalIndigenousManHours: 0,
  totalNonIndigenousEmploymentValue: 0,
  totalManHours: 0,
};
export const EmptyLineChartMeta: LineChartMeta = {
  title: "",
  xAxis: "",
  xAxisType: "",
  yAxis: "",
  plotOptions: {},
  series: {},
  colors: [],
  formatNumbersToCurrency: false,
  height: 0,
  legendEnabled: false,
  invertAxis: true,
  showTable: false,
  theme: "light",
  exportingEnabled: true,
  legendColor: "",
};
export const EmptyDonutMeta: DonutMeta = {
  title: "",
  series: {},
  formatNumbersToCurrency: false,
  height: 0,
  legendEnabled: false,
  legendRightAlign: false,
  legendLeftAlign: false,
  theme: "light",
  exportingEnabled: true,
  legendColor: "",
  isCurrency: true,
  isPercentage: false,
};
export const EmptyStackedBarMeta: StackedBarMeta = {
  title: "",
  xAxis: "",
  yAxis: "",
  categories: [],
  series: {},
  totalStacks: 0,
  colors: [],
  formatNumbersToCurrency: false,
  height: 0,
  legendEnabled: false,
  legendRightAlign: false,
  invertAxis: true,
  showTable: false,
  theme: "light",
  exportingEnabled: true,
  legendColor: "",
  isCurrency: true,
  isPercentage: false,
};
export const EmptyTreeMapMeta: TreeMapMeta = {
  title: "",
  data: [],
  allowTraversingTree: false,
};
export const EmptyBulletGraphMeta: BulletGraphMeta = {
  categories: [],
  series: [],
  plotBands: [],
  title: "",
  height: 0,
  spacingTop: 0,
  theme: "light",
  exportingEnabled: true,
  marginLeft: 200,
  legendColor: "",
  backgroundColor: "",
};
export const EmptyCommunityInvestmentTreeMap: CommunityInvestmentTreeMap = {
  data: [],
};
export const EmptyUserAccountSettings: UserAccountSettings = {
  communityInvestment: 0,
  employmentTarget: 0,
  projectBenefitsTarget: 0,
  companyName: "Company Name",
  city: "Location",
  email: "test@test.com",
  familyName: "Last Name",
  givenNames: "First Name",
  timezone: 0,
};
export const EmptyTotalsItem: TotalsItem = {
  amount: 0,
  description: "",
  percentage: 0,
  percentageFormatted: "",
  percentageDescription: "",
  color: "",
  backgroundColor: "",
  cssClass: "totals-item",
  children: [],
  isFullWidth: false,
  isCurrency: true,
};
export const EmptyImageUrlsMeta: ImageUrlsMeta = {
  logo: "",
};
export const EmptyData: EIRData = {
  reportMeta: {
    dataset: "",
    id: "",
    isProject: false,
    row: "",
    column: "",
    effectiveToDate: "",
  },
  totals: {
    employeeAnalysis: [],
    cross_analysis: [],
  },
};
export const EmptyEIRDatas = {
  age: _.cloneDeep(EmptyData),
  education: _.cloneDeep(EmptyData),
  gender: _.cloneDeep(EmptyData),
  orientation: _.cloneDeep(EmptyData),
  ethnicity: _.cloneDeep(EmptyData),
  indigenous: _.cloneDeep(EmptyData),
  immigration: _.cloneDeep(EmptyData),
  veteran: _.cloneDeep(EmptyData),
  disability: _.cloneDeep(EmptyData),
  barriers: _.cloneDeep(EmptyData),
  religion: _.cloneDeep(EmptyData),
  refugee: _.cloneDeep(EmptyData),
};
