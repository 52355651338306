





















































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import { getAvatarInitials } from '../lib/shared';
import { getIconUrl } from '@/lib/images';
import { ShortRecord } from '@/store/models';
import * as features from '../lib/feature-constants';

@Component
export default class AccountSelectorComponent extends BaseComponent {

  @Prop() public isMobile: any;
  public features = features;
  private menuItems: ShortRecord[] = [];
  private currentUserOrgs: ShortRecord[] = [];
  private selectedAccount: string|null = null;
  private updateNavComponent: number = 0;
  private unWatch1: any;
  private unWatch2: any;
  private unWatch3: any;

  protected beforeDestroy() {
      if (this.unWatch1) {
          this.unWatch1();
      }
      if (this.unWatch2) {
          this.unWatch2();
      }
      if (this.unWatch3) {
          this.unWatch3();
      }
  }

  protected mounted() {
    this.setCurrentUser();
    if (this.$store.getters['local/CURRENT_ORG'] !== '') {
      this.setCurrentOrg(this.$store.getters['local/CURRENT_ORG']);
    }
    // Watch for changes to the Current User and refresh page.
    if (this.$store) {
      this.unWatch1 = this.$store.watch(
          (state, getters) => getters['session/CURRENT_USER'],
          (newValue, oldValue) => {
            this.setCurrentUser();
          },
      );
      this.unWatch2 = this.$store.watch(
          (state, getters) => getters['local/CURRENT_ORG'],
          (newValue, oldValue) => {
            if (this.selectedAccount !== newValue) {
              this.selectedAccount = newValue;
            }
          },
      );
      this.unWatch3 = this.$store.watch(
          (state, getters) => getters['session/LOG_OUT'],
          (newValue, oldValue) => {
            if (newValue === true) {
              window.location.href = ('/');
            }
          },
      );
    }
    // this.createAppCuesWidget();
  }

  @Watch('selectedAccount')
  private onSelectedAccountChanged(val: string, oldVal: string) {
    if (val !== oldVal && oldVal !== null) {
      this.setCurrentOrg(val);
      this.navigate();
    }
  }

  private getAvatarInitials(orgName: string) {
    return getAvatarInitials(this.splitOrgName(orgName));
  }

  private getSelectedOrgName(): string {
    return this.getCurrentOrgName(this.selectedAccount as string);
  }

  private getCurrentUserAvatarInitials() {
    return getAvatarInitials(this.getCurrentOrgName(this.selectedAccount as string));
  }

  private navAdminSite() {
    window.location.href = process.env.VUE_APP_ADMIN_URL;
  }

  private setCurrentUser() {
    if (this.currentUserOrgs.length === 0) {
      if (this.currentUser.userinfo.memberOf && this.currentUser.userinfo.memberOf.length > 0) {
        this.currentUserOrgs = (this.currentUser.userinfo.memberOf as ShortRecord[]);
        this.currentUserOrgs.forEach((part, index, arr) => {
          arr[index].displayName = this.currentUser.userinfo.formattedName + ' - ' + part.displayName;
        });
      } else {
        this.currentUserOrgs = [{ displayName: this.currentUser.userinfo.formattedName }];
      }
      if (this.$store.getters['local/CURRENT_ORG'] !== '') {
        this.selectedAccount = this.$store.getters['local/CURRENT_ORG'];
      } else {
        this.selectedAccount = this.currentUserOrgs[0].identifier as string;
      }
      this.currentUserOrgs.forEach((item, index, arr) => {
        this.menuItems.push(item);
      });
    }
  }

  private navBulkImport() {
    this.navigate('/bulk-import');
  }

  private navViewAccount() {
    this.navigate('/account-settings');
  }

  private navVendors() {
    this.navigate('/vendors');
  }

  private getAccountIcon(): string {
    return getIconUrl('account.png');
  }

  private navigate(path: string = '') {
    if (path !== '') {
      this.changeRoute(path);
      this.updateNavComponent++;
    }
    this.$emit('navigate');
  }
}
