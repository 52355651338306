  export function convertToM(amount: number): string {
    let converted: string = '0';
    if (amount > 0) {
      if (amount < 1000000000) {
        if (amount < 10000) {
          converted = (amount / 1000).toFixed(2).replace('.00', '') + 'K';
        } else {
          converted = (amount / 1000000).toFixed(2).replace('.00', '') + 'M';
        }
      } else {
        converted = (amount / 1000000000).toFixed(2).replace('.00', '') + 'B';
      }
    }
    return '$' + converted;
  }

  export function convertToPercentValue(amount: number, total: number): number {
    return (amount > 0 ? total === 0 ? 100 : parseFloat(((amount / total) * 100).toFixed(2).replace('.00', '')) : 0);
  }

  export function convertToPercent(amount: number, total: number, bracketed: boolean = true, addPercentage: boolean = true): string {
    const percentFormatted: number = convertToPercentValue(amount, total);
    return bracketed ? '(' + percentFormatted + (addPercentage ? '%' : '') + ')' : percentFormatted + (addPercentage ? '%' : '');
  }
