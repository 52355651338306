











import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppCuesComponent extends Vue {

  protected mounted() {
    this.createAppCuesWidget();
  }

  private createAppCuesWidget() {
    // @ts-ignore
    const widget = AppcuesWidget(Appcues.user());
    widget.init("#app-cues", {
        position: "right",
        header: "<h1>Nisto Link Tutorials</h1>",
    });
  }
}
