import moment from 'moment-timezone';

export const dateTimeFormat: string = 'MMM D, YYYY HH:mm ';
export const dateFormat: string = 'MMM D, YYYY';
export const isoDateFormat: string = 'YYYY-MM-DD';

export function getCurrentYearStart(): string {
    const currentDate = new Date();
    const year: number = currentDate.getFullYear();
    return year.toString() + '-01-01';
}

export function getCurrentDate(): string {
    return formatDate(new Date());
}

export function getLastYearStart(): string {
    const currentDate: Date = new Date();
    const year: number = currentDate.getFullYear() - 1;
    return year.toString() + '01-01';
}

export function getLastYearCurrentDate(): string {
    const currentDate: Date = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);
    return formatDate(currentDate);
}

export function getPreviousYearCurrentDate(year: number): string {
    const currentDate: Date = new Date();
    currentDate.setFullYear(year);
    return formatDate(currentDate);
}

export function getLastYearDate(date: string): string {
    const givenDate: Date = getDate(date);
    givenDate.setFullYear(givenDate.getFullYear() - 1);
    return formatDate(givenDate);
}

export function getLastYearDateEnd(date: string): string {
    const year: number = getDate(date).getFullYear() - 1;
    return year.toString() + '-12-31';
}

export function getLastYearDateStart(date: string): string {
    const year: number = getDate(date).getFullYear() - 1;
    return year.toString() + '-01-01';
}

export function getDate(date: string): Date {
    return date === '' ? new Date() : parseDate(date);
}

export function parseDate(date: string): Date {
    const parts: string[] = date.split('-');
    return new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
}

export function timeZoneAbbreviation(): string {
    return moment.tz(moment.tz.guess()).zoneAbbr();
}

export function formatDateForDisplay(date: string): string {
    return moment(date).format(dateFormat);
}

export function formatDate(date: Date): string {
    let month = ('0' + (date.getMonth() + 1).toString());
    month = month.substr(month.length - 2);
    let day = ('0' + date.getDate().toString());
    day = day.substr(day.length - 2);
    return date.getFullYear().toString() + '-' + month + '-' + day;
}

export function toIsoDateString(date: string): string {
    if (date) {
        // In safari '/' doesn't work in the moment date parsing.
        date = date.replace(/\//g, "-");
        let isoDate = moment(date).format(isoDateFormat);
        if (isoDate === 'Invalid date') {
            if (date.includes('-')) {
                isoDate = moment(date, 'DD-MM-YYYY').format(isoDateFormat);
            }
        }
        return isoDate;
    } else {
        return date;
    }
}

export function getYears(yearsInThePast: number = 5): number[] {
    const currentYear: number = getYear();
    const years: number[] = [ currentYear ];
    for (let i = 0; i < yearsInThePast; i++) {
        years.push(currentYear - i);
    }
    return years;
}

export function getYear(): number {
    return new Date().getFullYear();
}

// Returns number of days between 2 dates.
export function daysBetween(firstDate: Date, secondDate: Date): number {
    return Math.round(Math.abs((+firstDate) - (+secondDate)) / 8.64e7);
}

// Hard-coded list of timezones.
export function getTimezones(): any[] {
    return [
        {
            "value": -12,
            "text": "(GMT -12:00) Eniwetok, Kwajalein",
        },
        {
            "value": -11,
            "text": "(GMT -11:00) Midway Island, Samoa",
        },
        {
            "value": -10,
            "text": "(GMT -10:00) Hawaii",
        },
        {
            "value": -9,
            "text": "(GMT -9:00) Alaska",
        },
        {
            "value": -8,
            "text": "(GMT -8:00) Pacific Time (US & Canada)",
        },
        {
            "value": -7,
            "text": "(GMT -7:00) Mountain Time (US & Canada)",
        },
        {
            "value": -6,
            "text": "(GMT -6:00) Central Time (US & Canada), Mexico City",
        },
        {
            "value": -5,
            "text": "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
        },
        {
            "value": -4,
            "text": "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
        },
        {
            "value": -3.5,
            "text": "(GMT -3:30) Newfoundland",
        },
        {
            "value": -3,
            "text": "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
        },
        {
            "value": -2,
            "text": "(GMT -2:00) Mid-Atlantic",
        },
        {
            "value": -1,
            "text": "(GMT -1:00) Azores, Cape Verde Islands",
        },
        {
            "value": 0,
            "text": "(GMT) Western Europe Time, London, Lisbon, Casablanca",
        },
        {
            "value": 1,
            "text": "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
        },
        {
            "value": 2,
            "text": "(GMT +2:00) Kaliningrad, South Africa",
        },
        {
            "value": 3,
            "text": "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
        },
        {
            "value": 3.5,
            "text": "(GMT +3:30) Tehran",
        },
        {
            "value": 4,
            "text": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
        },
        {
            "value": 4.5,
            "text": "(GMT +4:30) Kabul",
        },
        {
            "value": 5,
            "text": "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
        },
        {
            "value": 5.5,
            "text": "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
        },
        {
            "value": 5.75,
            "text": "(GMT +5:45) Kathmandu",
        },
        {
            "value": 6,
            "text": "(GMT +6:00) Almaty, Dhaka, Colombo",
        },
        {
            "value": 7,
            "text": "(GMT +7:00) Bangkok, Hanoi, Jakarta",
        },
        {
            "value": 8,
            "text": "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
        },
        {
            "value": 9,
            "text": "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
        },
        {
            "value": 9.5,
            "text": "(GMT +9:30) Adelaide, Darwin",
        },
        {
            "value": 10,
            "text": "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
        },
        {
            "value": 11,
            "text": "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
        },
        {
            "value": 12,
            "text": "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
        },
    ];
}
