import { Aggregate, TotalsAggregate, TotalsAggregateValue, OccupationAggregate, EmploymentTotalsAggregate, ExpenseTotalsMeta, DiverseSpendTotalsMeta, EmploymentTotalsMeta, TotalsAggregateValueEmployment, EmploymentTotalsMetaValue, TotalsMeta, SummaryTotalsMeta, RevenueTotalsMeta, ExpenseDiversityMeta, CategoryAggregateTotalsValue, OccupationDiversityMeta, OccupationMeta, TotalsAggregateValueOccupation } from '../store/models';
import { EmptyEmploymentTotalsAggregate, EmptyOccupationDiversityMeta, EmptySocialProcurementTotalsAggregate, EmptyTnbTotalsAggregate, EmptyTotalsAggregate, EmptyTotalsAggregateValue, EmptyTotalsAggregateValueEmployment, EmptyTotalsAggregateValueOccupation, EmptyTotalsAggregateValueTnb } from '../store/models-empty';
import { convertToPercent, convertToPercentValue } from '../lib/currency';
import { formatCurrencyToString } from '../lib/shared';
import * as _ from 'lodash';

export function convertToTotalsAggregateValue(value: number|undefined, percentageOfTotal: number = 0, isCurrency: boolean = true, roundedToNearestDollar: boolean = true): TotalsAggregateValue {
    let totalsAggregateValue = _.cloneDeep(EmptyTotalsAggregateValue);
    if (value !== undefined) {
        totalsAggregateValue = {
            value: value ? parseFloat((value as number).toFixed(2).replace('.00', '')) : 0,
            valueFormatted: '',
            percentage: convertToPercentValue(value, percentageOfTotal),
            percentageFormatted: convertToPercent(value, percentageOfTotal, false, true),
        };
    } else {
        value = 0;
    }
    totalsAggregateValue.valueFormatted = isCurrency ? formatCurrencyToString(value.toString(), roundedToNearestDollar) : value.toString();
    return totalsAggregateValue;
}
export function processOccupationMetaForDiversityType(employment: EmploymentTotalsMeta|null, employmentMetaType: string, occupations: OccupationDiversityMeta[]) {
    if (employment) {
        const employmentMeta: EmploymentTotalsMetaValue|undefined = employment[employmentMetaType];
        if (employmentMeta !== undefined) {
            for (const occupation of employmentMeta.occupations) {
                let existingOccupation: OccupationDiversityMeta|undefined = occupations.find((x) => x.occupation === occupation.occupation);
                if (existingOccupation === undefined) {
                    existingOccupation = _.cloneDeep(EmptyOccupationDiversityMeta);
                    existingOccupation.diverse = _.cloneDeep(EmptyTotalsAggregateValueOccupation);
                    existingOccupation.diverseNo = _.cloneDeep(EmptyTotalsAggregateValueOccupation);
                    existingOccupation.diverseNoAnswer = _.cloneDeep(EmptyTotalsAggregateValueOccupation);
                    existingOccupation.diverseUnknown = _.cloneDeep(EmptyTotalsAggregateValueOccupation);
                    existingOccupation.indigenous = _.cloneDeep(EmptyTotalsAggregateValueOccupation);
                    existingOccupation.indigenousAndDiverse = _.cloneDeep(EmptyTotalsAggregateValueOccupation);
                    existingOccupation.overall = _.cloneDeep(EmptyTotalsAggregateValueOccupation);
                    existingOccupation.unknownAndNoAnswer = _.cloneDeep(EmptyTotalsAggregateValueOccupation);
                    existingOccupation.occupation = occupation.occupation;
                    existingOccupation.occupationDescription = occupation.occupationDescription ? occupation.occupationDescription : occupation.occupation;
                    occupations.push(existingOccupation);
                }
                if (existingOccupation !== undefined) {
                    // Map to the internal property names.
                    let mappedType: string = '';
                    switch (employmentMetaType) {
                        case 'diverse-yes':
                            mappedType = 'diverse';
                            break;
                        case 'diverse-no':
                            mappedType = 'diverseNo';
                            break;
                        case 'diverse-no-answer':
                            mappedType = 'diverseNoAnswer';
                            break;
                        case 'diverse-unknown':
                            mappedType = 'diverseUnknown';
                            break;
                        default:
                            mappedType = employmentMetaType;
                    }
                    const existingOverallOccupationType: TotalsAggregateValueOccupation = existingOccupation['overall'] as TotalsAggregateValueOccupation;
                    const existingOccupationType: TotalsAggregateValueOccupation = existingOccupation[mappedType] as TotalsAggregateValueOccupation;
                    if (occupation.averageRate) { existingOccupationType.averageRate = convertToTotalsAggregateValue(occupation.averageRate, undefined, true, false); }
                    existingOccupationType.totalManHours = convertToTotalsAggregateValue(occupation.totalManHours, undefined, false);
                    existingOccupationType.totalWages = convertToTotalsAggregateValue(occupation.totalWages);
                    // Add to the overall total.
                    existingOverallOccupationType.totalManHours = convertToTotalsAggregateValue(existingOverallOccupationType.totalManHours.value + occupation.totalManHours, undefined, false);
                    existingOverallOccupationType.totalWages = convertToTotalsAggregateValue(existingOverallOccupationType.totalWages.value + occupation.totalWages);
                }
            }
        }
    }
}
export function convertEmploymentTotalsMetaToTotalsAggregateValueEmployment(employment: EmploymentTotalsMeta|null, employmentMetaType: string, employmentTotal?: EmploymentTotalsMetaValue): TotalsAggregateValueEmployment {
    const totalsAggregate: TotalsAggregateValueEmployment = _.cloneDeep(EmptyTotalsAggregateValueEmployment);
    if (employment) {
        const employmentMeta: EmploymentTotalsMetaValue|undefined = employment[employmentMetaType];
        totalsAggregate.hours =
            convertToTotalsAggregateValue(employmentMeta !== undefined ? employmentMeta.totalManHours : 0, employmentTotal ? employmentTotal.totalManHours : undefined, false);
        totalsAggregate.wages =
            convertToTotalsAggregateValue(employmentMeta !== undefined ? employmentMeta.totalWages : 0, employmentTotal ? employmentTotal.totalWages : undefined);
        totalsAggregate.headCount =
            convertToTotalsAggregateValue(employmentMeta !== undefined ? employmentMeta.totalHeadCount : 0, employmentTotal ? employmentTotal.totalHeadCount : undefined, false);
    }
    return totalsAggregate;
}
export function initTotalsAggregate(): TotalsAggregate {
    const totalsAggregate: TotalsAggregate = _.cloneDeep(EmptyTotalsAggregate);

    // Fix weird bug where clone deep is not properly cloning.
    totalsAggregate.tnb = _.cloneDeep(EmptyTnbTotalsAggregate);
    totalsAggregate.tnb.employment = _.cloneDeep(EmptyTotalsAggregateValueTnb);
    totalsAggregate.tnb.communityContributions = _.cloneDeep(EmptyTotalsAggregateValueTnb);
    totalsAggregate.tnb.overall = _.cloneDeep(EmptyTotalsAggregateValueTnb);
    totalsAggregate.tnb.projectBenefits = _.cloneDeep(EmptyTotalsAggregateValueTnb);
    totalsAggregate.socialProcurement = _.cloneDeep(EmptySocialProcurementTotalsAggregate);
    totalsAggregate.diverse = _.cloneDeep(EmptySocialProcurementTotalsAggregate);
    totalsAggregate.employment = _.cloneDeep(EmptyEmploymentTotalsAggregate);
    totalsAggregate.totalProjectExpenses = _.cloneDeep(EmptyTotalsAggregateValue);
    totalsAggregate.totalProjectRevenue = _.cloneDeep(EmptyTotalsAggregateValue);
    return totalsAggregate;
}
export function convertAggregateToTotalsAggregate(aggregate: Aggregate, displayIndigenousProjectRevenue: boolean = false, useTotalIndigenousProjectRevenueAsDis: boolean = false): TotalsAggregate {
    const totalsAggregate: TotalsAggregate = initTotalsAggregate();
    const totalsMeta: TotalsMeta = (aggregate.totals as TotalsMeta);
    totalsAggregate.facetDetail = aggregate.facetDetail;

    // Overall totals.
    const expenseTotals: ExpenseTotalsMeta|null =
        totalsMeta.expenses ? (totalsMeta.expenses.expenseTotals as ExpenseTotalsMeta) : null;
    totalsAggregate.totalProjectExpenses =
        convertToTotalsAggregateValue(expenseTotals ? expenseTotals.totalSpend : 0);

    // Revenue totals.
    const revenueTotals: RevenueTotalsMeta|null =
        totalsMeta.revenue ? (totalsMeta.revenue as RevenueTotalsMeta) : null;
    totalsAggregate.totalProjectRevenue =
        convertToTotalsAggregateValue(revenueTotals ? revenueTotals.revenueTotals.totalProjectRevenue : 0);

    // Social Procurement.
    const socialProcurementMeta: DiverseSpendTotalsMeta|null =
    totalsMeta.expenses ? (totalsMeta.expenses.socialProcurement as DiverseSpendTotalsMeta) : null;

    totalsAggregate.socialProcurement.overall =
        convertToTotalsAggregateValue(socialProcurementMeta ? (socialProcurementMeta.totalDiverseSpend + socialProcurementMeta.totalIndigenousSpend + socialProcurementMeta.diversityDetail.totalThirdPartyCertifiedSpend + socialProcurementMeta.diversityDetail.totalSociallyFocusedSpend + socialProcurementMeta.diversityDetail.totalSmeSpend + socialProcurementMeta.diversityDetail.totalLocalSpend) : 0, totalsAggregate.totalProjectExpenses.value);

    totalsAggregate.socialProcurement.esb =
        convertToTotalsAggregateValue(socialProcurementMeta ? (socialProcurementMeta.totalDiverseSpend + socialProcurementMeta.totalIndigenousSpend) : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.socialProcurement.esbIndigenous =
        convertToTotalsAggregateValue(socialProcurementMeta ? socialProcurementMeta.totalIndigenousSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.socialProcurement.esbDiverse =
        convertToTotalsAggregateValue(socialProcurementMeta ? socialProcurementMeta.totalDiverseSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.socialProcurement.thirdPartyCertifiedSpend =
        convertToTotalsAggregateValue(socialProcurementMeta ? socialProcurementMeta.diversityDetail.totalThirdPartyCertifiedSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.socialProcurement.sociallyFocusedSpend =
        convertToTotalsAggregateValue(socialProcurementMeta ? socialProcurementMeta.diversityDetail.totalSociallyFocusedSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.socialProcurement.smeSpend =
        convertToTotalsAggregateValue(socialProcurementMeta ? socialProcurementMeta.diversityDetail.totalSmeSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.socialProcurement.localSpend =
        convertToTotalsAggregateValue(socialProcurementMeta ? socialProcurementMeta.diversityDetail.totalLocalSpend : 0, totalsAggregate.totalProjectExpenses.value);

    // Categorized Social Procurement.
    const expenseDiversityMeta: ExpenseDiversityMeta|null = (totalsMeta.expenseDiversity as ExpenseDiversityMeta);
    if (expenseDiversityMeta) {
        totalsAggregate.socialProcurement.expenseDiversityCategories.overall = getExpenseDiversityCategoryOverall(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value);
        totalsAggregate.socialProcurement.expenseDiversityCategories.unknown = getExpenseDiversityCategory(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value, "none");
        totalsAggregate.socialProcurement.expenseDiversityCategories.esb = getExpenseDiversityCategory(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value, "impactESG"); // THIS IS WRONG
        totalsAggregate.socialProcurement.expenseDiversityCategories.esbIndigenous = getExpenseDiversityCategory(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value, "indigenousImpact");
        totalsAggregate.socialProcurement.expenseDiversityCategories.esbDiverse = getExpenseDiversityCategory(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value, "impactESG");
        totalsAggregate.socialProcurement.expenseDiversityCategories.localSpend = getExpenseDiversityCategory(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value, "localOwned");
        totalsAggregate.socialProcurement.expenseDiversityCategories.smeSpend = getExpenseDiversityCategory(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value, "sme");
        totalsAggregate.socialProcurement.expenseDiversityCategories.thirdPartyCertifiedSpend = getExpenseDiversityCategory(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value, "impactSocial");
        totalsAggregate.socialProcurement.expenseDiversityCategories.sociallyFocusedSpend = getExpenseDiversityCategory(expenseDiversityMeta, totalsAggregate.totalProjectExpenses.value, "socialFocusedOrgs");
    }

    // Diverse.
    const diverseMeta: DiverseSpendTotalsMeta|null =
    totalsMeta.expenses ? (totalsMeta.expenses.diverseSpend as DiverseSpendTotalsMeta) : null;
    totalsAggregate.diverse.esb =
        convertToTotalsAggregateValue(diverseMeta ? (diverseMeta.totalDiverseSpend + diverseMeta.totalIndigenousSpend) : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.diverse.esbIndigenous =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.totalDirectIndigenousSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.diverse.esbDiverse =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.totalDiverseSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.diverse.thirdPartyCertifiedSpend =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.diversityDetail.totalThirdPartyCertifiedSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.diverse.sociallyFocusedSpend =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.diversityDetail.totalSociallyFocusedSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.diverse.smeSpend =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.diversityDetail.totalSmeSpend : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.diverse.localSpend =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.diversityDetail.totalLocalSpend : 0, totalsAggregate.totalProjectExpenses.value);

    // Indigneous Details.
    totalsAggregate.indigenous.directIndigenousSpend =
        convertToTotalsAggregateValue(getDISTotal(totalsMeta), totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.indigenous.certifiedBusinessSpend =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.indigenousDetail ? diverseMeta.indigenousDetail.totalIndigenousCertifiedBusinessSpend : 0 : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.indigenous.communityOwnedSpend =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.indigenousDetail ? diverseMeta.indigenousDetail.totalIndigenousCommunityOwnedSpend : 0 : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.indigenous.relationshipSpend =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.indigenousDetail ? diverseMeta.indigenousDetail.totalIndigenousRelationshipSpend : 0 : 0, totalsAggregate.totalProjectExpenses.value);
    totalsAggregate.indigenous.selfDeclaredBusinessSpend =
        convertToTotalsAggregateValue(diverseMeta ? diverseMeta.indigenousDetail ? diverseMeta.indigenousDetail.totalIndigenousSelfDeclaredBusinessSpend : 0 : 0, totalsAggregate.totalProjectExpenses.value);

    // Employment.
    const employmentMeta: EmploymentTotalsMeta|null =
        (aggregate.totals as TotalsMeta).employment ? ((aggregate.totals as TotalsMeta).employment as EmploymentTotalsMeta) : null;

    // Overall.
    if (employmentMeta) {
        totalsAggregate.employment.overall = convertEmploymentTotalsMetaToTotalsAggregateValueEmployment(employmentMeta, 'overall');
        totalsAggregate.employment.diverse = convertEmploymentTotalsMetaToTotalsAggregateValueEmployment(employmentMeta, 'diverse-yes', employmentMeta.overall);
        totalsAggregate.employment.diverseNo = convertEmploymentTotalsMetaToTotalsAggregateValueEmployment(employmentMeta, 'diverse-no', employmentMeta.overall);
        totalsAggregate.employment.indigenous = convertEmploymentTotalsMetaToTotalsAggregateValueEmployment(employmentMeta, 'indigenous', employmentMeta.overall);
        totalsAggregate.employment.diverseNoAnswer = convertEmploymentTotalsMetaToTotalsAggregateValueEmployment(employmentMeta, 'diverse-no-answer', employmentMeta.overall);
        totalsAggregate.employment.diverseUnknown = convertEmploymentTotalsMetaToTotalsAggregateValueEmployment(employmentMeta, 'diverse-unknown', employmentMeta.overall);
        totalsAggregate.employment.indigenousAndDiverse = {
            headCount: convertToTotalsAggregateValue((totalsAggregate.employment.diverse.headCount.value + totalsAggregate.employment.indigenous.headCount.value), employmentMeta.overall.totalHeadCount, false),
            hours: convertToTotalsAggregateValue((totalsAggregate.employment.diverse.hours.value + totalsAggregate.employment.indigenous.hours.value), employmentMeta.overall.totalManHours, false),
            wages: convertToTotalsAggregateValue((totalsAggregate.employment.diverse.wages.value + totalsAggregate.employment.indigenous.wages.value), employmentMeta.overall.totalWages),
        };
        totalsAggregate.employment.unknownAndNoAnswer = {
            headCount: convertToTotalsAggregateValue((totalsAggregate.employment.diverseUnknown.headCount.value + totalsAggregate.employment.diverseNoAnswer.headCount.value), employmentMeta.overall.totalHeadCount, false),
            hours: convertToTotalsAggregateValue((totalsAggregate.employment.diverseUnknown.hours.value + totalsAggregate.employment.diverseNoAnswer.hours.value), employmentMeta.overall.totalManHours, false),
            wages: convertToTotalsAggregateValue((totalsAggregate.employment.diverseUnknown.wages.value + totalsAggregate.employment.diverseNoAnswer.wages.value), employmentMeta.overall.totalWages),
        };
        // Combine all the occupation data together for each employment meta type.
        const occupations: OccupationDiversityMeta[] = [];
        // processOccupationMetaForDiversityType(employmentMeta, 'overall', occupations);
        processOccupationMetaForDiversityType(employmentMeta, 'diverse-yes', occupations);
        processOccupationMetaForDiversityType(employmentMeta, 'diverse-no', occupations);
        processOccupationMetaForDiversityType(employmentMeta, 'indigenous', occupations);
        processOccupationMetaForDiversityType(employmentMeta, 'diverse-no-answer', occupations);
        processOccupationMetaForDiversityType(employmentMeta, 'diverse-unknown', occupations);
        totalsAggregate.employment.occupations = occupations;
    }
    // Tangible Net Benefits (TNB).
    // Overall.
    totalsAggregate.tnb.total =
        convertToTotalsAggregateValue(expenseTotals ? (expenseTotals.totalCommunityContributions + expenseTotals.totalProjectContributions + totalsAggregate.employment.indigenousAndDiverse.wages.value) : 0, totalsAggregate.totalProjectRevenue.value);
    totalsAggregate.tnb.overall.diverse =
        convertToTotalsAggregateValue(expenseTotals ? ((expenseTotals.totalDiverseCommunityContributions ? expenseTotals.totalDiverseCommunityContributions : 0) + (expenseTotals.totalDiverseProjectContributions ? expenseTotals.totalDiverseProjectContributions : 0) + totalsAggregate.employment.diverse.wages.value) : 0, totalsAggregate.totalProjectRevenue.value);
    totalsAggregate.tnb.overall.indigenous =
        convertToTotalsAggregateValue(expenseTotals ? ((expenseTotals.totalIndigenousCommunityContributions ? expenseTotals.totalIndigenousCommunityContributions : 0) + (expenseTotals.totalIndigenousProjectContributions ? expenseTotals.totalIndigenousProjectContributions : 0) + totalsAggregate.employment.indigenous.wages.value) : 0, totalsAggregate.totalProjectRevenue.value);
    // Community Contribution.
    totalsAggregate.tnb.communityContributions.indigenous =
        convertToTotalsAggregateValue(expenseTotals ? (expenseTotals.totalIndigenousCommunityContributions ? expenseTotals.totalIndigenousCommunityContributions : 0) : 0, totalsAggregate.totalProjectRevenue.value);
    totalsAggregate.tnb.communityContributions.diverse =
        convertToTotalsAggregateValue(expenseTotals ? (expenseTotals.totalDiverseCommunityContributions ? expenseTotals.totalDiverseCommunityContributions : 0) : 0, totalsAggregate.totalProjectRevenue.value);
    // Project Benefits.
    totalsAggregate.tnb.projectBenefits.indigenous =
        convertToTotalsAggregateValue(expenseTotals ? (expenseTotals.totalIndigenousProjectContributions ? expenseTotals.totalIndigenousProjectContributions : 0) : 0, totalsAggregate.totalProjectRevenue.value);
    totalsAggregate.tnb.projectBenefits.diverse =
        convertToTotalsAggregateValue(expenseTotals ? (expenseTotals.totalDiverseProjectContributions ? expenseTotals.totalDiverseProjectContributions : 0) : 0, totalsAggregate.totalProjectRevenue.value);
    // Employment.
    totalsAggregate.tnb.employment.indigenous =
        convertToTotalsAggregateValue(totalsMeta.expenses ? (totalsMeta.expenses.employment ? totalsMeta.expenses.employment.totalIndigenousEmploymentValue : 0) : 0 , totalsAggregate.totalProjectRevenue.value);
    totalsAggregate.tnb.employment.diverse =
        convertToTotalsAggregateValue(totalsAggregate.employment.diverse.wages.value, totalsAggregate.totalProjectRevenue.value);
    // console.log(JSON.stringify(totalsAggregate));
    return totalsAggregate;
}
export function getTNBTotal(totals: TotalsMeta|SummaryTotalsMeta, includeCommunityContributions: boolean = true, includeIndigenousEmployment: boolean = true): number {
    const expenseTotal: number =
        totals.expenses ?
            ((includeCommunityContributions
                ? totals.expenses.expenseTotals.totalIndigenousCommunityContributions
                    ? totals.expenses.expenseTotals.totalIndigenousCommunityContributions : 0
                : 0) +
            (totals.expenses.expenseTotals.totalIndigenousProjectContributions
                ? totals.expenses.expenseTotals.totalIndigenousProjectContributions : 0)) : 0;
    const employmentIndigenousTotal: number = includeIndigenousEmployment ? totals.expenses ? totals.expenses.employment ? totals.expenses.employment.totalIndigenousEmploymentValue : 0 : 0 : 0;
    const tnbTotal: number = employmentIndigenousTotal + expenseTotal;
    return tnbTotal;
}
export function getDISTotal(totals: TotalsMeta|SummaryTotalsMeta): number {
    return totals.expenses ? totals.expenses.diverseSpend ? totals.expenses.diverseSpend.totalDirectIndigenousSpend : 0 : 0;
}
export function getTNBs(totals: TotalsMeta|SummaryTotalsMeta): string {
    const totalProjectRevenue: number = totals.revenue ?
                                            (totals.revenue as RevenueTotalsMeta).revenueTotals ?
                                                (totals.revenue as RevenueTotalsMeta).revenueTotals.totalProjectRevenue
                                                : (totals as SummaryTotalsMeta).revenue.projectRevenue
                                                : 0;
    const tnbTotal = getTNBTotal(totals);
    return formatCurrencyToString(tnbTotal.toString()) as string + ' ' + convertToPercent(tnbTotal, totalProjectRevenue, true, true);
}
export function getSocialProcurement(totals: TotalsMeta): string {
    const totalProjectSpend: number = totals.expenses ? totals.expenses.expenseTotals.totalProjectSpend : 0;
    const socialProcurementTotal: number = totals.expenses ?
                                            totals.expenses.socialProcurement ?
                                                totals.expenses.socialProcurement.totalIndigenousSpend +
                                                totals.expenses.socialProcurement.totalDiverseSpend +
                                                totals.expenses.socialProcurement.diversityDetail.totalLocalSpend +
                                                totals.expenses.socialProcurement.diversityDetail.totalSmeSpend +
                                                totals.expenses.socialProcurement.diversityDetail.totalSociallyFocusedSpend +
                                                totals.expenses.socialProcurement.diversityDetail.totalThirdPartyCertifiedSpend : 0 : 0;
    return formatCurrencyToString(socialProcurementTotal.toString()) as string + ' ' + convertToPercent(socialProcurementTotal, totalProjectSpend, true, true);
}
export function getDIS(totals: TotalsMeta|SummaryTotalsMeta): string {
    const totalProjectSpend: number = totals.expenses ? totals.expenses.expenseTotals.totalProjectSpend : 0;
    const diverseTotal: number = totals.expenses ? totals.expenses.diverseSpend ? totals.expenses.diverseSpend.totalDirectIndigenousSpend ? totals.expenses.diverseSpend.totalDirectIndigenousSpend : 0 : 0 : 0;
    return formatCurrencyToString(diverseTotal.toString()) as string + ' ' + convertToPercent(diverseTotal, totalProjectSpend, true, true);
}
export function getIS(totals: TotalsMeta|SummaryTotalsMeta): string {
    const totalProjectSpend: number = totals.expenses ? totals.expenses.expenseTotals.totalProjectSpend : 0;
    const diverseTotal: number = totals.expenses ? totals.expenses.diverseSpend ? totals.expenses.diverseSpend.totalIndigenousSpend : 0 : 0;
    return formatCurrencyToString(diverseTotal.toString()) as string + ' ' + convertToPercent(diverseTotal, totalProjectSpend, true, true);
}
export function getExpenseDiversityCategory(expenseDiversityMeta: ExpenseDiversityMeta, overallTotal: number, category: string): CategoryAggregateTotalsValue {
    const goodsTotal: number = expenseDiversityMeta.goods ? expenseDiversityMeta.goods.diversityClasses[category] ? (expenseDiversityMeta.goods.diversityClasses[category].totalExpense) : 0 : 0;
    const servicesTotal: number = expenseDiversityMeta.services ? expenseDiversityMeta.services.diversityClasses[category] ? (expenseDiversityMeta.services.diversityClasses[category].totalExpense) : 0 : 0;
    const equipmentTotal: number = expenseDiversityMeta.equipment ? expenseDiversityMeta.equipment.diversityClasses[category] ? (expenseDiversityMeta.equipment.diversityClasses[category].totalExpense) : 0 : 0;
    const unknownTotal: number = expenseDiversityMeta.overallTotal ? expenseDiversityMeta.overallTotal.diversityClasses[category] ? (expenseDiversityMeta.overallTotal.diversityClasses[category].totalExpense) : 0 : 0;
    const total: number = goodsTotal + servicesTotal + equipmentTotal + unknownTotal;
    return {
        goods: convertToTotalsAggregateValue(goodsTotal, overallTotal),
        services: convertToTotalsAggregateValue(servicesTotal, overallTotal),
        equipment: convertToTotalsAggregateValue(equipmentTotal, overallTotal),
        unknown: convertToTotalsAggregateValue(unknownTotal, overallTotal),
        total: convertToTotalsAggregateValue(total, overallTotal),
    };
}
export function getExpenseDiversityCategoryOverall(expenseDiversityMeta: ExpenseDiversityMeta, overallTotal: number): CategoryAggregateTotalsValue {
    const goodsTotal: number = expenseDiversityMeta.goods ? expenseDiversityMeta.goods.overall ? expenseDiversityMeta.goods.overall.totalClassExpenses : 0 : 0;
    const servicesTotal: number = expenseDiversityMeta.services ? expenseDiversityMeta.services.overall ? expenseDiversityMeta.services.overall.totalClassExpenses : 0 : 0;
    const equipmentTotal: number = expenseDiversityMeta.equipment ? expenseDiversityMeta.equipment.overall ? expenseDiversityMeta.equipment.overall.totalClassExpenses : 0 : 0;
    const unknownTotal: number = expenseDiversityMeta.overallTotal ? expenseDiversityMeta.overallTotal.overall ? expenseDiversityMeta.overallTotal.overall.totalClassExpenses : 0 : 0;
    const total: number = goodsTotal + servicesTotal + equipmentTotal + unknownTotal;
    return {
        goods: convertToTotalsAggregateValue(goodsTotal, overallTotal),
        services: convertToTotalsAggregateValue(servicesTotal, overallTotal),
        equipment: convertToTotalsAggregateValue(equipmentTotal, overallTotal),
        unknown: convertToTotalsAggregateValue(unknownTotal, overallTotal),
        total: convertToTotalsAggregateValue(total, overallTotal),
    };
}
export function formatTotalForDisplay(total: TotalsAggregateValue): string {
    if (total.value === 0) {
      return '-';
    } else {
      return total.valueFormatted.replace('$', '');
    }
}
/*
export function convertAggregateToOccupationAggregate(aggregate: Aggregate): OccupationAggregate[] {
    const occupationAggregate: OccupationAggregate[] = [];
    if (!_.isNil(aggregate.totals.employment.occupations)) {
        const occupations = aggregate.totals.employment.occupations;
        for (const property of Object.keys(occupations)) {
            occupationAggregate.push({ occupationName: occupations[property].occupationDisplayName ? occupations[property].occupationDisplayName : occupations[property].displayName,
                                  occupationCode: occupations[property].occupationCode,
                                  totalEmploymentValue: occupations[property].totalEmploymentValue,
                                  totalIndigenousEmploymentValue: occupations[property].totalIndigenousEmploymentValue,
                                  totalNonIndigenousEmploymentValue: occupations[property].totalEmploymentValue - occupations[property].totalIndigenousEmploymentValue,
                                  totalManHours: occupations[property].totalManHours,
                                  totalIndigenousManHours: occupations[property].totalIndigenousManHours,
                                } as OccupationAggregate);
                            }
    }
    return occupationAggregate;
}

export function convertAggregateToEmploymentTotals(aggregate: Aggregate): EmploymentTotals {
    let totalIndigenousEmploymentValue: number = 0;
    let totalIndigenousManHours: number = 0;
    if (!_.isNil(aggregate.totals.employment.occupations)) {
        const occupations = aggregate.totals.employment.occupations;
        for (const property of Object.keys(occupations)) {
            totalIndigenousEmploymentValue += occupations[property].totalIndigenousEmploymentValue;
            totalIndigenousManHours += occupations[property].totalIndigenousManHours;
        }
    }
    return {
        totalIndigenousEmploymentValue,
        totalIndigenousManHours,
    };
}
*/
