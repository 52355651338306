export const colorDirectIndigenousSpend: string = '#F5C64D';
export const colorTangibleNetBenefits: string = '#124CA0';
export const colorProjectBenefits: string = '#794D81';
export const colorCommunityInvestment: string = '#D68A69';
// export const colorEmployment: string = '#54B0A4';
export const colorDarkBlue: string = '#18374B';
export const colorRoyalBlue: string = '#124CA0';
export const colorMediumBlue: string = '#4D79B8';
export const colorLightBlue: string = '#88A5D0';
export const colorGreen: string = '#76CD73';
export const colorWhite: string = '#FFFFFF';
export const colorBlack: string = '#000000';
export const colorLightGrey: string = '#EBF0F2';
export const colorGrey: string = '#C9C9C9';
export const colorDarkGrey: string = '#666666';
export const colorSkyBlue: string = '#D1EFF2';

export const colorSocial: string = '#124CA0';
export const colorLocal: string = '#54B0A4';
export const colorLocalInsights: string = '#D6955B';

export const colorSmallEnterprise: string = '#71AF54';

export const colorEmployment: string = '#367A98';
export const colorIndigenousEmployment: string = '#689BB2';
export const colorNonDeclaredEmployment: string = '#90B6C6';
export const colorDiverseEmployment: string = '#B9D0DB';
export const colorNonDiverseEmployment: string = '#E1EBEF';
export const colorIndigenousAndDiverseEmployment: string = '#6457A4';

export const colorLightIndigenousEmployment: string = '#367A98';
export const colorLightNonDeclaredEmployment: string = '#689BB2';
export const colorLightDiverseEmployment: string = '#90B6C6';
export const colorLightNonDiverseEmployment: string = '#B9D0DB';

export const colorTotalTnb: string = '#D76779';
export const colorIndigenousTnb: string = '#D68A69';
export const colorDiverseTnb: string = '#F5C64D';
export const colorProjectBenefitsTnb: string = '#794D81';
export const colorEmploymentTnb: string = '#367A98';

export const colorSelfDeclared: string = '#F5C64D';
export const colorRelationship: string = '#F8D479';
export const colorIndigenousOwned: string = '#FAE2A6';
export const colorCertifiedIndigenousOwned: string = '#FCF1D2';

export const colorThirdPartyStackedBCorp: string = '#D7665A';
export const colorThirdPartyLivingWageEmployer: string = '#E18C83';
export const colorThirdPartyCertifiedSocialEnterprise: string = '#E7A39C';
export const colorThirdPartyCommunityContributionCompany: string = '#EFC2BD';
export const colorThirdPartyCommunityServiceCoops: string = '#F7E0DE';
export const colorThirdPartyNotForProfitIndigenous: string = '#F2E7E6';

export const colorDarkPurple: string = '#6457A4';
export const colorLightPurple: string = '#B1ABD2';

export const colors: string [] = [
    'rgba(116, 62, 158, 1)',  //Purple - 0
    'rgba(27, 107, 178, 1)',  //Navy blue - 1
    'rgba(141, 181, 216, 1)', //Light blue - 2
    'rgba(171, 178, 29, 1)',  //Mustard yellow - 3
    'rgba(47, 208, 124, 1)',  //Green shades - 4 to 8
    'rgba(47, 208, 124, 0.8)',
    'rgba(47, 208, 124, 0.6)',
    'rgba(47, 208, 124, 0.4)',
    'rgba(47, 208, 124, 0.2)',
    'rgba(0, 183, 183, 1)',   //Turquoise shades - 9 to 13
    'rgba(0, 183, 183, 0.8)',
    'rgba(0, 183, 183, 0.6)',
    'rgba(0, 183, 183, 0.4)',
    'rgba(0, 183, 183, 0.2)',
];

export function getColorClass(status: string) {
    switch (status) {
      case 'submitted':
        return 'submitted';
      case 'rejected':
        return 'rejected';
      case 'verified':
        return 'verified';
      case 'voided':
        return 'voided';
      default:
        return '';
    }
}
