export const clientMenu: string = 'client-menu';
export const revenueWidgets: string = 'revenue-widgets';
export const contributionsByClientReport: string = 'contrib-by-client-report';
export const employmentMenu: string = 'employ-menu';
export const employmentWidgets: string = 'employ-widgets';
export const communityEmploymentTables: string = 'comm-employ-table';
export const employmentDiversityReports: string = 'employ-div-reports';
export const employmentDiversityWidgetReports: string = 'employ-div-widget-reports';
export const indigenousEmploymentReports: string = 'indig-employ-reports';
export const allowImport: string = 'import';
export const importClients: string = 'import-client';
export const importPayees: string = 'import-payee';
export const importProjects: string = 'import-project';
export const importEmployment: string = 'import-employ';
export const importExpense: string = 'import-expense';
export const insightsMenu = "insights-menu";
