export function getReportItem(id: string, options: any, template: string): any {
    return {
        "template": template,
        "id": id,
        "options": options,
    };
}

export function getHeadingReportItem(heading: string, isSubHeading = false): any {
    return {
        "template": (isSubHeading ? "sub" : '') + "heading.tpl",
        "id": "",
        "options": { heading },
    };
}

export function base64Encode(data: string): string {
    return btoa(unescape(encodeURIComponent(data)));
}

export function base64Decode(data: string): string {
    return decodeURIComponent(escape(atob(data)));
}
