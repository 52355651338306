



























import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import AccountSelectorComponent from '../components/AccountSelectorComponent.vue';
import { getIconUrl } from '@/lib/images';
import { MenuMeta } from '@/store/models';

@Component({
  components: {
    AccountSelectorComponent,
  },
})
export default class MenuComponent extends BaseComponent {
  @Prop() public menu: any;
  @Prop() public isMobile: any;

  private selectedHref: string = '';

  protected mounted() {
    this.mount();
  }

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: any) {
    this.mount();
  }

  private mount() {
    this.selectedHref = this.$router.currentRoute.path;
  }

  private menuClick(href: string = '') {
    if (href !== '') {
      this.selectedHref = href;
      this.changeRoute(href);
    }
    this.$emit('click');
  }

  private isActiveMenuItem(href: string, index: number): boolean {
    return ((this.selectedHref.indexOf(href) === 0) || (this.selectedHref === '/' && index === 0));
  }

  private isHighlightedMenuItem(item: MenuMeta): boolean {
    return item.highlighted ? item.highlighted : false;
  }

  private getAccountIcon(): string {
    return getIconUrl('account.png');
  }
}
