import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/reports/external/:reportType',
      component: () => import('./views/SocialImpactReportExternal.vue'),
    },
    {
      path : '/http*',
      beforeEnter: (to, from, next) => { window.location.href = to.fullPath.substring(1); },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('./views/ErrorView.vue'),
    },
    {
      path: "/",
      beforeEnter: (to, from, next) => {
        next("/insights");
      },
    },
    {
      path: '/insights',
      name: 'insights',
      component: () => import('./views/Insights.vue'),
    },
    {
      path: '/projects',
      component: () => import('./views/Projects.vue'),
    },
    {
      path: '/projects/new-project',
      component: () => import('./views/Projects.vue'),
    },
    {
      path: '/projects/id/:id',
      component: () => import('./views/ProjectSummaryNav.vue'),
      children: [
        {
          path: '',
          name: 'project-insights',
          component: () => import('./views/Insights.vue'),
        },
        {
          path: 'details',
          component: () => import('./views/ProjectSummary.vue'),
        },
        {
          path: 'payees',
          component: () => import('./views/ProjectPayees.vue'),
        },
        {
          path: 'payees/new-payee',
          component: () => import('./views/ProjectPayees.vue'),
        },
        {
          path: 'payees/id/:payeeId',
          component: () => import('./views/ProjectPayees.vue'),
        },
        {
          path: 'payees/new-expense',
          component: () => import('./views/ProjectPayees.vue'),
        },
        {
          path: 'project-benefits',
          component: () => import('./views/ProjectBenefits.vue'),
        },
        {
          path: 'project-benefits/new',
          component: () => import('./views/ProjectBenefits.vue'),
        },
        {
          path: 'employment',
          component: () => import('./views/Employments.vue'),
        },
        {
          path: 'employment/import',
          component: () => import('./views/ImportEmployment.vue'),
        },
      ],
    },
    {
      path: '/vendors',
      component: () => import('./views/Vendors.vue'),
    },
    {
      path: '/vendors/id/:id',
      component: () => import('./views/Vendor.vue'),
    },
    {
      path: '/clients',
      component: () => import('./views/Clients.vue'),
    },
    {
      path: '/clients/id/:id',
      component: () => import('./views/ClientSummaryNav.vue'),
      children: [
        {
          path: '',
          component: () => import('./views/PayeeSummary.vue'),
        },
        {
          path: 'projects',
          component: () => import('./views/ClientProjects.vue'),
        },
      ],
    },
    {
      path: '/payees',
      component: () => import('./views/Payees.vue'),
    },
    {
      path: '/payees/new-payee',
      component: () => import('./views/Payees.vue'),
    },
    {
      path: '/payees/new-expense',
      component: () => import('./views/Payees.vue'),
    },
    {
      path: '/payees/id/:id',
      component: () => import('./views/PayeeSummaryNav.vue'),
      children: [
        {
          path: '',
          component: () => import('./views/PayeeSummary.vue'),
        },
        {
          path: 'projects',
          component: () => import('./views/PayeeProjects.vue'),
        },
        {
          path: 'expenses',
          component: () => import('./views/PayeeExpenses.vue'),
        },
        {
          path: 'new-expense',
          component: () => import('./views/PayeeExpenses.vue'),
        },
      ],
    },
    {
      path: '/communities',
      component: () => import('./views/Communities.vue'),
    },
    {
      path: '/communities/id/:id',
      component: () => import('./views/Community.vue'),
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('./views/Reports.vue'),
    },
    {
      path: '/reports/:reportType',
      name: 'social-impact-report',
      component: () => import('./views/SocialImpactReport.vue'),
    },
    {
      path: '/expenses/import',
      component: () => import('./views/ImportExpense.vue'),
    },
    {
      path: '/projects/import',
      component: () => import('./views/ImportProject.vue'),
    },
    {
      path: '/payees/import',
      component: () => import('./views/ImportPayee.vue'),
    },
    {
      path: '/clients/import',
      component: () => import('./views/ImportPayee.vue'),
    },
    {
      path: '/employment/import',
      name: 'import-employment',
      component: () => import('./views/ImportEmployment.vue'),
    },
    {
      path: '/bulk-import',
      name: 'bulk-import',
      component: () => import('./views/BulkImport.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./views/Logout.vue'),
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('./views/AccountSettings.vue'),
    },
  ],
});
