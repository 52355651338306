import { DiversityReferenceData } from "@/store/models";
import * as features from '../lib/feature-constants';

export function getDiversityRefData(): DiversityReferenceData {
    const data = {
        "refData": {
            "type": "diversity-declarations",
            "description": "Diversity Declaration Names and Codes",
            "values": [{
                    "name": "Certified B-Corp",
                    "categories": [{
                            "code": "certifiedBCorp",
                            "categoryName": "Social Purpose Organizations",
                            "categoryCode": "impactSocial",
                            "itemNameForCategory": "Certified B-Corp"
                        }
                    ]
                }, {
                    "name": "Black Owned Business",
                    "categories": [{
                            "code": "blackOwned",
                            "categoryName": "Third Party Certified Equity Seeking Business",
                            "categoryCode": "impactESG",
                            "itemNameForCategory": "Certified Black Owned Business"
                        }, {
                            "code": "blackFocused",
                            "categoryName": "Self-Declared Equity Seeking Business",
                            "categoryCode": "socialFocusedOrgs",
                            "itemNameForCategory": "Self-Declared Black Owned Business"
                        }
                    ]
                }, {
                    "name": "Disabled Owned Business",
                    "categories": [{
                            "code": "disabledOwned",
                            "categoryName": "Third Party Certified Equity Seeking Business",
                            "categoryCode": "impactESG",
                            "itemNameForCategory": "Certified Disabled Owned Business"
                        }, {
                            "code": "disabledFocused",
                            "categoryName": "Self-Declared Equity Seeking Business",
                            "categoryCode": "socialFocusedOrgs",
                            "itemNameForCategory": "Self-Declared Disabled Owned Business"
                        }
                    ]
                }, {
                    "name": "Immigrant/Refugee Owned Business",
                    "categories": [{
                            "code": "immigrantOwned",
                            "categoryName": "Third Party Certified Equity Seeking Business",
                            "categoryCode": "impactESG",
                            "itemNameForCategory": "Certified Immigrant/Refugee Owned Business"
                        }, {
                            "code": "immigrantFocused",
                            "categoryName": "Self-Declared Equity Seeking Business",
                            "categoryCode": "socialFocusedOrgs",
                            "itemNameForCategory": "Self-Declared Immigrant/Refugee Owned Business"
                        }
                    ]
                }, {
                    "name": "LGBTQ2S+ Owned Business",
                    "categories": [{
                            "code": "lgbtq2spOwned",
                            "categoryName": "Third Party Certified Equity Seeking Business",
                            "categoryCode": "impactESG",
                            "itemNameForCategory": "Certified LGBTQ2S+ Owned Business"
                        }, {
                            "code": "lgbtqs2spFocused",
                            "categoryName": "Self-Declared Equity Seeking Business",
                            "categoryCode": "socialFocusedOrgs",
                            "itemNameForCategory": "Self-Declared LGBTQ2S+ Owned Business"
                        }
                    ]
                }, {
                    "name": "Certified Living Wage Employer",
                    "categories": [{
                            "code": "certifiedLivingWageEmployer",
                            "categoryName": "Social Purpose Organizations",
                            "categoryCode": "impactSocial",
                            "itemNameForCategory": "Certified Living Wage Employer"
                        }
                    ]
                }, {
                    "name": "Person of Color Owned Business",
                    "categories": [{
                            "code": "pocOwned",
                            "categoryName": "Third Party Certified Equity Seeking Business",
                            "categoryCode": "impactESG",
                            "itemNameForCategory": "Certified Person of Color Owned Business"
                        }, {
                            "code": "pocFocused",
                            "categoryName": "Self-Declared Equity Seeking Business",
                            "categoryCode": "socialFocusedOrgs",
                            "itemNameForCategory": "Self-Declared Person of Color Owned Business"
                        }
                    ]
                }, {
                    "name": "Certified Social Enterprise",
                    "categories": [{
                            "code": "certifiedSocialEnterprise",
                            "categoryName": "Social Purpose Organizations",
                            "categoryCode": "impactSocial",
                            "itemNameForCategory": "Certified Social Enterprise"
                        }
                    ]
                }, {
                    "name": "Veteran Owned Business",
                    "categories": [{
                            "code": "veteranOwned",
                            "categoryName": "Third Party Certified Equity Seeking Business",
                            "categoryCode": "impactESG",
                            "itemNameForCategory": "Certified Veteran Owned Business"
                        }, {
                            "code": "veteranFocused",
                            "categoryName": "Self-Declared Equity Seeking Business",
                            "categoryCode": "socialFocusedOrgs",
                            "itemNameForCategory": "Self-Declared Veteran Owned Business"
                        }
                    ]
                }, {
                    "name": "Woman Owned Business",
                    "categories": [{
                            "code": "womenOwned",
                            "categoryName": "Third Party Certified Equity Seeking Business",
                            "categoryCode": "impactESG",
                            "itemNameForCategory": "Certified Woman Owned Business"
                        }, {
                            "code": "womenFocused",
                            "categoryName": "Self-Declared Equity Seeking Business",
                            "categoryCode": "socialFocusedOrgs",
                            "itemNameForCategory": "Self-Declared Woman Owned Business"
                        }
                    ]
                }, {
                    "name": "Community Contribution Company (C3)",
                    "categories": [{
                            "code": "certifiedC3",
                            "categoryName": "Social Purpose Organizations",
                            "categoryCode": "impactSocial",
                            "itemNameForCategory": "Community Contribution Company (C3)"
                        }
                    ]
                }, {
                    "name": "Community Service Co-Ops",
                    "categories": [{
                            "code": "communityServiceCoop",
                            "categoryName": "Social Purpose Organizations",
                            "categoryCode": "impactSocial",
                            "itemNameForCategory": "Community Service Co-Ops"
                        }
                    ]
                }, {
                    "name": "Not For Profit - Indigenous Focus",
                    "categories": [{
                            "code": "notForProfitIndigenous",
                            "categoryName": "Social Purpose Organizations",
                            "categoryCode": "impactSocial",
                            "itemNameForCategory": "Not For Profit - Indigenous Focus"
                        }
                    ]
                }, {
                    "name": "Charity - Indigenous Focus",
                    "categories": [{
                        "code": "charityIndigenousFocus",
                        "categoryName": "Social Purpose Organizations",
                        "categoryCode": "impactSocial",
                        "itemNameForCategory": "Charity - Indigenous Focus"
                      }
                    ]
                }, {
                    "name": "Indigenous Community",
                    "categories": [{
                            "code": "indigenousCommunity",
                            "categoryName": "Indigenous Community",
                            "categoryCode": "indigenousImpact",
                            "itemNameForCategory": "Indigenous Community"
                        }
                    ]
                }, {
                    "name": "Indigenous Owned Business",
                    "categories": [{
                            "code": "indigenousOwned",
                            "categoryName": "Third Party Certified Equity Seeking Business",
                            "categoryCode": "impactESG",
                            "itemNameForCategory": "Certified Indigenous Owned Business"
                        }, {
                            "code": "indigenousOwned",
                            "categoryName": "Indigenous Community",
                            "categoryCode": "indigenousImpact",
                            "itemNameForCategory": "Indigenous Community Owned Business"
                        }, {
                            "code": "indigenousFocused",
                            "categoryName": "Self-Declared Equity Seeking Business",
                            "categoryCode": "socialFocusedOrgs",
                            "itemNameForCategory": "Self-Declared Indigenous Owned Business"
                        }, {
                            "code": "indigenousFocused",
                            "categoryName": "Indigenous Community",
                            "categoryCode": "indigenousImpact",
                            "itemNameForCategory": "Indigenous Focused Organization"
                        }
                    ]
                },
                {
                    "name": "Locally Owned Business",
                    "categories": [{
                            "code": "localOwned",
                            "categoryName": "Local and Small Enterprises",
                            "categoryCode": "impactStatus",
                            "itemNameForCategory": "Locally Owned Business"
                        }
                    ]
                }, {
                    "name": "Small Enterprises",
                    "categories": [{
                            "code": "sme",
                            "categoryName": "Local and Small Enterprises",
                            "categoryCode": "impactStatus",
                            "itemNameForCategory": "Small Enterprises"
                        }
                    ]
                }, {
                    "name": "Not For Profit - General",
                    "categories": [{
                            "code": "notForProfitGeneral",
                            "categoryName": "Social Purpose Organizations",
                            "categoryCode": "impactSocial",
                            "itemNameForCategory": "Not For Profit - General"
                        }
                    ]
                }
            ]
        }
    };
    return data;
}
export function getIndigenousImpactCommunityTestData(): any {
    const data = {
        "reportTitle": "Community Indigenous Impact Report",
        "reportType": "indigenous-impact-community",
        "companyName": "Bluetron Energy",
        "reportLogoUrl": "https://nlstaticassets.z9.web.core.windows.net/bluetron.png",
        "dateRange": "All - Oct 5, 2021",
        "preparedBy": "Steele Jon",
        "preparedOn": "Oct 5, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": [],
            "subVendors": [],
            "organizations": {
                "identifier": "urn:nisto-link:id:first-nation:fn-0438",
                "displayName": "Alexander First Nation"
            },
            "clients": [],
            "jobFocuses": [],
            "beneficiaries": [],
            "dateFrom": "",
            "dateTo": "2021-10-05"
        },
        "organizationName": "Bluetron Energy",
        "title": "Community Indigenous Impact Report",
        "orgAggregate": {
            "facetDetail": {
                "identifier": "urn:nisto-link:id:vendor:bluetron",
                "displayName": "Bluetron Energy",
                "recordType": ["Organization", "Vendor", "Payee"],
                "indigenousOwned": false,
                "vendorCode": "bluetron",
                "defaultExpenseClass": "uncategorized"
            },
            "totalProjectExpenses": {
                "value": 3629,
                "valueFormatted": "$3,629",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 0,
                "valueFormatted": "$0",
                "percentage": 0,
                "percentageFormatted": "0%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 801,
                    "valueFormatted": "$801",
                    "percentage": 22.07,
                    "percentageFormatted": "22.07%"
                },
                "esb": {
                    "value": 801,
                    "valueFormatted": "$801",
                    "percentage": 22.07,
                    "percentageFormatted": "22.07%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 801,
                    "valueFormatted": "$801",
                    "percentage": 22.07,
                    "percentageFormatted": "22.07%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 801,
                            "valueFormatted": "$801",
                            "percentage": 22.07,
                            "percentageFormatted": "22.07%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 801,
                            "valueFormatted": "$801",
                            "percentage": 22.07,
                            "percentageFormatted": "22.07%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 801,
                            "valueFormatted": "$801",
                            "percentage": 22.07,
                            "percentageFormatted": "22.07%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 801,
                            "valueFormatted": "$801",
                            "percentage": 22.07,
                            "percentageFormatted": "22.07%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 3629,
                    "valueFormatted": "$3,629",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 3629,
                    "valueFormatted": "$3,629",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "indigenous": {
                "directIndigenousSpend": {
                    "value": 3629,
                    "valueFormatted": "$3,629",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "certifiedBusinessSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "communityOwnedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "relationshipSpend": {
                    "value": 801,
                    "valueFormatted": "$801",
                    "percentage": 22.07,
                    "percentageFormatted": "22.07%"
                },
                "selfDeclaredBusinessSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 2828,
                    "valueFormatted": "$2,828",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "overall": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 2828,
                        "valueFormatted": "$2,828",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 2828,
                        "valueFormatted": "$2,828",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 6,
            "indigenousCommunityContribution": 0.75,
            "indigenousEmployment": 15,
            "diverseProjectBenefit": 5,
            "diverseEmployment": 4,
            "socialProcurement": 5,
            "diverseCommunityContribution": 4
        },
        "beneficiaryAggregate": null,
        "clientAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999,
                    "type": ["Vendor"]
                },
                "resultMeta": {
                    "matched": 13,
                    "included": 13
                },
                "results": [{
                        "organization": {
                            "orgName": "ATCO Electric",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2350",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3555 Valleyview Road"],
                                    "orgCity": "Calgary",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N9"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "atcoe",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "ATCO Electric",
                            "sortKey": "atco electric",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:atcoe-7156",
                            "etag": "E513605107738379176",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:15+0000",
                            "createdTime": "2021-09-05T02:38:15+0000"
                        },
                        "index": 1,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            },
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "ATCO Structures and Modular",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2351",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3556 Valleyview Road"],
                                    "orgCity": "Calgary",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N10"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "atcos",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "ATCO Structures and Modular",
                            "sortKey": "atco structures and modular",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:atcos-2810",
                            "etag": "E5226821531292954263",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:15+0000",
                            "createdTime": "2021-09-05T02:38:15+0000"
                        },
                        "index": 2,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Canadian Natural Resources Ltd.",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2341",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3546 Valleyview Road"],
                                    "orgCity": "Fort McMurray",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N0"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "cnrl",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Canadian Natural Resources Ltd.",
                            "sortKey": "canadian natural resources ltd.",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:cnrl-5842",
                            "etag": "E2352670122725807535",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:14+0000",
                            "createdTime": "2021-09-05T02:38:14+0000"
                        },
                        "index": 3,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "City of Calgary",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2345",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3550 Valleyview Road"],
                                    "orgCity": "Calgary",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N4"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "coc",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "City of Calgary",
                            "sortKey": "city of calgary",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:coc-2493",
                            "etag": "E986784754073216806",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:14+0000",
                            "createdTime": "2021-09-05T02:38:14+0000"
                        },
                        "index": 4,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "City of Edmonton",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2344",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3549 Valleyview Road"],
                                    "orgCity": "Edmonton",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N3"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "coe",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "City of Edmonton",
                            "sortKey": "city of edmonton",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:coe-5976",
                            "etag": "E6850549199703171198",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:14+0000",
                            "createdTime": "2021-09-05T02:38:14+0000"
                        },
                        "index": 5,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "City of Richmond",
                            "indigenousOwnership": 0,
                            "contactPerson": "Liana Witbey",
                            "contactEmail": "l.witbey@drentals.com",
                            "contactPhone": "403-647-3275",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["354 Highview Road"],
                                    "orgCity": "Calgary",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T2B 0P1"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "cor",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "City of Richmond",
                            "sortKey": "city of richmond",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:cor-4829",
                            "etag": "E5718162738913728222",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:14+0000",
                            "createdTime": "2021-09-05T02:38:14+0000"
                        },
                        "index": 6,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 801,
                                    "totalProjectSpend": 801,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 801,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 801,
                                    "indigenousDetail": {
                                        "totalIndigenousCommunityOwnedSpend": 0,
                                        "totalIndigenousRelationshipSpend": 801,
                                        "totalIndigenousCertifiedBusinessSpend": 0,
                                        "totalIndigenousSelfDeclaredBusinessSpend": 0
                                    },
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "City of Vancouver",
                            "indigenousOwnership": 0,
                            "contactPerson": "Jennifer Digby",
                            "contactEmail": "j.digby@paintingco.com",
                            "contactPhone": "403-645-7980",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["23 Mountain Road"],
                                    "orgCity": "Calgary",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T2N 0P3"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "cov",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "City of Vancouver",
                            "sortKey": "city of vancouver",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:cov-5939",
                            "etag": "E1080160624779271578",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:14+0000",
                            "createdTime": "2021-09-05T02:38:14+0000"
                        },
                        "index": 7,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 2828,
                                    "totalProjectSpend": 2828,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 2828,
                                    "totalIndigenousProjectContributions": 2828,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 2828,
                                    "indigenousDetail": {
                                        "totalIndigenousCommunityOwnedSpend": 0,
                                        "totalIndigenousRelationshipSpend": 0,
                                        "totalIndigenousCertifiedBusinessSpend": 0,
                                        "totalIndigenousSelfDeclaredBusinessSpend": 0
                                    },
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Government of Alberta",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2347",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3552 Valleyview Road"],
                                    "orgCity": "Edmonton",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N6"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "goa",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Government of Alberta",
                            "sortKey": "government of alberta",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:goa-7374",
                            "etag": "E4403956885358857488",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:15+0000",
                            "createdTime": "2021-09-05T02:38:15+0000"
                        },
                        "index": 8,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Government of Canada",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2348",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3553 Valleyview Road"],
                                    "orgCity": "Ottawa",
                                    "stateOrProvince": "Ontario",
                                    "postalCode": "T0H 3N7"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "goc",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Government of Canada",
                            "sortKey": "government of canada",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:goc-8332",
                            "etag": "E7379997749943104549",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:15+0000",
                            "createdTime": "2021-09-05T02:38:15+0000"
                        },
                        "index": 9,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Kelso Developments",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2346",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3551 Valleyview Road"],
                                    "orgCity": "Valleyview",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N5"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "kd",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Kelso Developments",
                            "sortKey": "kelso developments",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:kd-6532",
                            "etag": "E7293440693178169715",
                            "status": "live",
                            "version": "2",
                            "lastUpdated": "2021-09-05T03:47:57+0000",
                            "createdTime": "2021-09-05T02:38:14+0000",
                            "previousVersion": "urn:nisto-link:id:vendor:kd-6532:1"
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 10,
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            },
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Municipality of Westlock",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2343",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3548 Valleyview Road"],
                                    "orgCity": "Westlock",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N2"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "mow",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Municipality of Westlock",
                            "sortKey": "municipality of westlock",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:mow-7843",
                            "etag": "E7355743154487060599",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:14+0000",
                            "createdTime": "2021-09-05T02:38:14+0000"
                        },
                        "index": 11,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Suncor Energy",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2342",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3547 Valleyview Road"],
                                    "orgCity": "Fort McMurray",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N1"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "suncor",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Suncor Energy",
                            "sortKey": "suncor energy",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:suncor-9913",
                            "etag": "E4164822131216053945",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:15+0000",
                            "createdTime": "2021-09-05T02:38:15+0000"
                        },
                        "index": 12,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Telus",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2349",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3554 Valleyview Road"],
                                    "orgCity": "Toronto",
                                    "stateOrProvince": "Ontario",
                                    "postalCode": "T0H 3N8"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "tel",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:bluetron",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Bluetron Energy"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Telus",
                            "sortKey": "telus",
                            "transactionId": "urn:nisto-link:id:tx:2021-09-05T02-38-06.652Z-7862",
                            "identifier": "urn:nisto-link:id:vendor:tel-122",
                            "etag": "E2105564663261746107",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-05T02:38:15+0000",
                            "createdTime": "2021-09-05T02:38:15+0000"
                        },
                        "index": 13,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        },
        "expenseDiversityReportDataTables": [],
        "projectSummaryReportDataTable": {
            "title": "",
            "headers": [],
            "rows": [],
            "totalRow": {
                "children": [],
                "data": []
            },
            "total": {
                "value": 0,
                "valueFormatted": "0",
                "percentage": 0,
                "percentageFormatted": "0%"
            },
            "totalTitle": "",
            "color": "",
            "hideHeaders": false,
            "pageBreakAfter": false,
            "parentsBold": true,
            "hideIfZeroTotal": false,
            "className": "",
            "autoCalculateTotalRow": false
        },
        "payeeCommunityReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Alexander First Nation", "", "$801"],
                        "columnStyle": [{
                                "index": 0,
                                "fontSize": "11pt"
                            }, {
                                "index": 2,
                                "fontSize": "11pt"
                            }
                        ]
                    }, {
                        "data": ["Indigenous Partner", "", "$801"],
                        "children": [{
                                "data": ["Gregg Distributors Co. Ltd.", "", "$801"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 2
                                    }
                                ]
                            }
                        ],
                        "columnStyle": [{
                                "index": 0,
                                "indentations": 1
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "indigenousTypeReportDataTables": [],
        "summaryReportDataTables": [],
        "communityReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 6,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Alexander First Nation", "$801"],
                        "children": [{
                                "data": ["Acceleration Lane", "$801"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }
                        ],
                        "columnStyle": [{
                                "index": 0,
                                "fontSize": "11pt"
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "icprojectsAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999
                },
                "resultMeta": {
                    "matched": 2,
                    "included": 2
                },
                "results": [{
                        "totals": {
                            "revenue": {
                                "projectRevenue": 801,
                                "indigenousProjectRevenue": 0
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 801,
                                    "totalProjectSpend": 801,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 801,
                                    "totalDirectIndigenousSpend": 801,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:project:afs-1535-4819",
                            "displayName": "Acceleration Lane",
                            "recordType": ["Project"],
                            "startDate": "2019-01-02",
                            "ownerVendor": "urn:nisto-link:id:vendor:bluetron",
                            "poId": "358964"
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 2828,
                                    "totalProjectSpend": 2828,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 2828,
                                    "totalIndigenousProjectContributions": 2828,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 2828,
                                    "totalDirectIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:project:vcaf-1516-5714",
                            "displayName": "Dock Reclamation",
                            "recordType": ["Project"],
                            "startDate": "2019-01-31",
                            "ownerVendor": "urn:nisto-link:id:vendor:bluetron",
                            "poId": "358993"
                        },
                        "index": 2
                    }
                ]
            }
        }
    }
    return data;
}
export function getSocialProcurementCommunityTestData(): any {
    const data = {
        "reportTitle": "Community Indigenous Procurement Report",
        "reportType": "social-procurement-community",
        "companyName": "Chandos Construction",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "All - Sep 17, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Sep 17, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": [],
            "subVendors": [],
            "organizations": {
                "identifier": "urn:nisto-link:id:first-nation:fn-0490",
                "displayName": "Aishihik First Nation"
            },
            "clients": [],
            "jobFocuses": [],
            "beneficiaries": "urn:nisto-link:id:first-nation:fn-0490",
            "dateFrom": "",
            "dateTo": "2021-09-17"
        },
        "organizationName": "Chandos Construction",
        "title": "Community Indigenous Procurement Report",
        "orgAggregate": {
            "facetDetail": {
                "identifier": "urn:nisto-link:id:vendor:chandos",
                "displayName": "Chandos Construction",
                "recordType": ["Organization", "Vendor", "Payee", "Tenant"],
                "indigenousOwned": false,
                "vendorCode": "chandos",
                "defaultExpenseClass": "uncategorized"
            },
            "totalProjectExpenses": {
                "value": 65612,
                "valueFormatted": "$65,612",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 0,
                "valueFormatted": "$0",
                "percentage": 0,
                "percentageFormatted": "0%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 65612,
                    "valueFormatted": "$65,612",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "esb": {
                    "value": 65612,
                    "valueFormatted": "$65,612",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 65612,
                    "valueFormatted": "$65,612",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 47516,
                            "valueFormatted": "$47,516",
                            "percentage": 72.42,
                            "percentageFormatted": "72.42%"
                        },
                        "equipment": {
                            "value": 18096,
                            "valueFormatted": "$18,096",
                            "percentage": 27.58,
                            "percentageFormatted": "27.58%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 65612,
                            "valueFormatted": "$65,612",
                            "percentage": 100,
                            "percentageFormatted": "100%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 47516,
                            "valueFormatted": "$47,516",
                            "percentage": 72.42,
                            "percentageFormatted": "72.42%"
                        },
                        "equipment": {
                            "value": 18096,
                            "valueFormatted": "$18,096",
                            "percentage": 27.58,
                            "percentageFormatted": "27.58%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 65612,
                            "valueFormatted": "$65,612",
                            "percentage": 100,
                            "percentageFormatted": "100%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 65612,
                    "valueFormatted": "$65,612",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 65612,
                    "valueFormatted": "$65,612",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "indigenous": {
                "directIndigenousSpend": {
                    "value": 65612,
                    "valueFormatted": "$65,612",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "certifiedBusinessSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "communityOwnedSpend": {
                    "value": 65612,
                    "valueFormatted": "$65,612",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "relationshipSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "selfDeclaredBusinessSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 233,
                        "valueFormatted": "233",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 5194,
                        "valueFormatted": "$5,194",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 233,
                        "valueFormatted": "233",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 5194,
                        "valueFormatted": "$5,194",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 233,
                        "valueFormatted": "233",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 5194,
                        "valueFormatted": "$5,194",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 5194,
                    "valueFormatted": "$5,194",
                    "percentage": 100,
                    "percentageFormatted": "100%"
                },
                "overall": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 5194,
                        "valueFormatted": "$5,194",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 5194,
                        "valueFormatted": "$5,194",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 6,
            "indigenousCommunityContribution": 0.75,
            "indigenousEmployment": 15,
            "diverseProjectBenefit": 5,
            "diverseEmployment": 4,
            "socialProcurement": 7,
            "diverseCommunityContribution": 4
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999
                },
                "resultMeta": {
                    "matched": 1,
                    "included": 1
                },
                "results": [{
                        "totals": {
                            "revenue": {
                                "projectRevenue": 65612,
                                "indigenousProjectRevenue": 65612
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 65612,
                                    "totalProjectSpend": 65612,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 65612,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 5194,
                                    "totalIndigenousEmploymentValue": 5194,
                                    "totalManHours": 233,
                                    "totalIndigenousManHours": 233
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0490",
                            "displayName": "Aishihik First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }
                ]
            }
        },
        "clientAggregate": null,
        "expenseDiversityReportDataTables": [],
        "projectSummaryReportDataTable": {
            "title": "",
            "headers": [],
            "rows": [],
            "totalRow": {
                "children": [],
                "data": []
            },
            "total": {
                "value": 0,
                "valueFormatted": "0",
                "percentage": 0,
                "percentageFormatted": "0%"
            },
            "totalTitle": "",
            "color": "",
            "hideHeaders": false,
            "pageBreakAfter": false,
            "parentsBold": true,
            "hideIfZeroTotal": false,
            "className": "",
            "autoCalculateTotalRow": false
        },
        "payeeCommunityReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Aishihik First Nation", "Total Community Spend", "$61,812"]
                    }, {
                        "data": ["Indigenous Community Owned Businesses", "", "$61,812"],
                        "children": [{
                                "data": ["3E Glass Ltd.", "", "$61,812"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "indigenousTypeReportDataTables": [],
        "summaryReportDataTables": [],
        "communityReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 6,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Aishihik First Nation", "$61,812"],
                        "children": [{
                                "data": ["Mountain Tass Pipeline", "$61,812"]
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ]
    };
    return data;
}
export function getSocialProcurementCommunitySummaryTestData(): any {
    const data = {
        "reportTitle": "Summary Indigenous Procurement Report",
        "reportType": "social-procurement-community-summary",
        "companyName": "Chandos Construction",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "All - Oct 5, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Oct 5, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": [],
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "beneficiaries": [],
            "dateFrom": "",
            "dateTo": "2021-10-05"
        },
        "organizationName": "Chandos Construction",
        "title": "Summary Indigenous Procurement Report",
        "orgAggregate": {
            "facetDetail": {
                "identifier": "urn:nisto-link:id:vendor:chandos",
                "displayName": "Chandos Construction",
                "recordType": ["Organization", "Vendor", "Payee", "Tenant"],
                "indigenousOwned": false,
                "vendorCode": "chandos",
                "defaultExpenseClass": "uncategorized"
            },
            "totalProjectExpenses": {
                "value": 3223650,
                "valueFormatted": "$3,223,650",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 5723602,
                "valueFormatted": "$5,723,602",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 2240663,
                    "valueFormatted": "$2,240,663",
                    "percentage": 69.51,
                    "percentageFormatted": "69.51%"
                },
                "esb": {
                    "value": 810570,
                    "valueFormatted": "$810,570",
                    "percentage": 25.14,
                    "percentageFormatted": "25.14%"
                },
                "esbDiverse": {
                    "value": 193,
                    "valueFormatted": "$193",
                    "percentage": 0.01,
                    "percentageFormatted": "0.01%"
                },
                "esbIndigenous": {
                    "value": 810377,
                    "valueFormatted": "$810,377",
                    "percentage": 25.14,
                    "percentageFormatted": "25.14%"
                },
                "localSpend": {
                    "value": 1409935,
                    "valueFormatted": "$1,409,935",
                    "percentage": 43.74,
                    "percentageFormatted": "43.74%"
                },
                "smeSpend": {
                    "value": 20158,
                    "valueFormatted": "$20,158",
                    "percentage": 0.63,
                    "percentageFormatted": "0.63%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 726,
                            "valueFormatted": "$726",
                            "percentage": 0.02,
                            "percentageFormatted": "0.02%"
                        },
                        "services": {
                            "value": 805580,
                            "valueFormatted": "$805,580",
                            "percentage": 24.99,
                            "percentageFormatted": "24.99%"
                        },
                        "equipment": {
                            "value": 4071,
                            "valueFormatted": "$4,071",
                            "percentage": 0.13,
                            "percentageFormatted": "0.13%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 810377,
                            "valueFormatted": "$810,377",
                            "percentage": 25.14,
                            "percentageFormatted": "25.14%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 137237,
                            "valueFormatted": "$137,237",
                            "percentage": 4.26,
                            "percentageFormatted": "4.26%"
                        },
                        "services": {
                            "value": 1180979,
                            "valueFormatted": "$1,180,979",
                            "percentage": 36.63,
                            "percentageFormatted": "36.63%"
                        },
                        "equipment": {
                            "value": 91719,
                            "valueFormatted": "$91,719",
                            "percentage": 2.85,
                            "percentageFormatted": "2.85%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 1409935,
                            "valueFormatted": "$1,409,935",
                            "percentage": 43.74,
                            "percentageFormatted": "43.74%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 20158,
                            "valueFormatted": "$20,158",
                            "percentage": 0.63,
                            "percentageFormatted": "0.63%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 20158,
                            "valueFormatted": "$20,158",
                            "percentage": 0.63,
                            "percentageFormatted": "0.63%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 144016,
                            "valueFormatted": "$144,016",
                            "percentage": 4.47,
                            "percentageFormatted": "4.47%"
                        },
                        "services": {
                            "value": 2898725,
                            "valueFormatted": "$2,898,725",
                            "percentage": 89.92,
                            "percentageFormatted": "89.92%"
                        },
                        "equipment": {
                            "value": 139551,
                            "valueFormatted": "$139,551",
                            "percentage": 4.33,
                            "percentageFormatted": "4.33%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 3182292,
                            "valueFormatted": "$3,182,292",
                            "percentage": 98.72,
                            "percentageFormatted": "98.72%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 6053,
                            "valueFormatted": "$6,053",
                            "percentage": 0.19,
                            "percentageFormatted": "0.19%"
                        },
                        "services": {
                            "value": 891815,
                            "valueFormatted": "$891,815",
                            "percentage": 27.66,
                            "percentageFormatted": "27.66%"
                        },
                        "equipment": {
                            "value": 43761,
                            "valueFormatted": "$43,761",
                            "percentage": 1.36,
                            "percentageFormatted": "1.36%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 941629,
                            "valueFormatted": "$941,629",
                            "percentage": 29.21,
                            "percentageFormatted": "29.21%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 989286,
                    "valueFormatted": "$989,286",
                    "percentage": 17.28,
                    "percentageFormatted": "17.28%"
                },
                "esbDiverse": {
                    "value": 137551,
                    "valueFormatted": "$137,551",
                    "percentage": 2.4,
                    "percentageFormatted": "2.4%"
                },
                "esbIndigenous": {
                    "value": 851735,
                    "valueFormatted": "$851,735",
                    "percentage": 14.88,
                    "percentageFormatted": "14.88%"
                },
                "localSpend": {
                    "value": 1495706,
                    "valueFormatted": "$1,495,706",
                    "percentage": 26.13,
                    "percentageFormatted": "26.13%"
                },
                "smeSpend": {
                    "value": 48401,
                    "valueFormatted": "$48,401",
                    "percentage": 0.85,
                    "percentageFormatted": "0.85%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "indigenous": {
                "directIndigenousSpend": {
                    "value": 851735,
                    "valueFormatted": "$851,735",
                    "percentage": 26.42,
                    "percentageFormatted": "26.42%"
                },
                "certifiedBusinessSpend": {
                    "value": 126674,
                    "valueFormatted": "$126,674",
                    "percentage": 3.93,
                    "percentageFormatted": "3.93%"
                },
                "communityOwnedSpend": {
                    "value": 543392,
                    "valueFormatted": "$543,392",
                    "percentage": 16.86,
                    "percentageFormatted": "16.86%"
                },
                "relationshipSpend": {
                    "value": 140311,
                    "valueFormatted": "$140,311",
                    "percentage": 4.35,
                    "percentageFormatted": "4.35%"
                },
                "selfDeclaredBusinessSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 6455.25,
                        "valueFormatted": "6455.25",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 140973,
                        "valueFormatted": "$140,973",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 703.25,
                        "valueFormatted": "703.25",
                        "percentage": 10.89,
                        "percentageFormatted": "10.89%"
                    },
                    "wages": {
                        "value": 15064,
                        "valueFormatted": "$15,064",
                        "percentage": 10.69,
                        "percentageFormatted": "10.69%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 543,
                        "valueFormatted": "543",
                        "percentage": 8.41,
                        "percentageFormatted": "8.41%"
                    },
                    "wages": {
                        "value": 11898,
                        "valueFormatted": "$11,898",
                        "percentage": 8.44,
                        "percentageFormatted": "8.44%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 5209,
                        "valueFormatted": "5209",
                        "percentage": 80.69,
                        "percentageFormatted": "80.69%"
                    },
                    "wages": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 80.87,
                        "percentageFormatted": "80.87%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 5752,
                        "valueFormatted": "5752",
                        "percentage": 89.11,
                        "percentageFormatted": "89.11%"
                    },
                    "wages": {
                        "value": 125909,
                        "valueFormatted": "$125,909",
                        "percentage": 89.31,
                        "percentageFormatted": "89.31%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 167267,
                    "valueFormatted": "$167,267",
                    "percentage": 2.92,
                    "percentageFormatted": "2.92%"
                },
                "overall": {
                    "diverse": {
                        "value": 124695,
                        "valueFormatted": "$124,695",
                        "percentage": 2.18,
                        "percentageFormatted": "2.18%"
                    },
                    "indigenous": {
                        "value": 42572,
                        "valueFormatted": "$42,572",
                        "percentage": 0.74,
                        "percentageFormatted": "0.74%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 10684,
                        "valueFormatted": "$10,684",
                        "percentage": 0.19,
                        "percentageFormatted": "0.19%"
                    },
                    "indigenous": {
                        "value": 30674,
                        "valueFormatted": "$30,674",
                        "percentage": 0.54,
                        "percentageFormatted": "0.54%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 1.99,
                        "percentageFormatted": "1.99%"
                    },
                    "indigenous": {
                        "value": 11898,
                        "valueFormatted": "$11,898",
                        "percentage": 0.21,
                        "percentageFormatted": "0.21%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 6,
            "indigenousCommunityContribution": 0.75,
            "indigenousEmployment": 15,
            "diverseProjectBenefit": 5,
            "diverseEmployment": 4,
            "socialProcurement": 7,
            "diverseCommunityContribution": 4
        },
        "beneficiaryAggregate": null,
        "clientAggregate": null,
        "expenseDiversityReportDataTables": [],
        "projectSummaryReportDataTable": {
            "title": "",
            "headers": [],
            "rows": [],
            "totalRow": {
                "children": [],
                "data": []
            },
            "total": {
                "value": 0,
                "valueFormatted": "0",
                "percentage": 0,
                "percentageFormatted": "0%"
            },
            "totalTitle": "",
            "color": "",
            "hideHeaders": false,
            "pageBreakAfter": false,
            "parentsBold": true,
            "hideIfZeroTotal": false,
            "className": "",
            "autoCalculateTotalRow": false
        },
        "payeeCommunityReportDataTables": [],
        "indigenousTypeReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Certified Indigenous Owned Business", "", "$126,674"],
                        "children": [{
                                "data": ["3E Glass Ltd.", "", "$122,603"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }, {
                                "data": ["Enterprise Rent-a-car", "", "$4,071"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }
                        ],
                        "columnStyle": [{
                                "index": 0,
                                "fontSize": "11pt"
                            }
                        ]
                    }, {
                        "data": ["Indigenous Community Owned Business", "", "$543,392"],
                        "children": [{
                                "data": ["AES Engineering Ltd", "", "$225,196"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }, {
                                "data": ["Imaginit Technologies", "", "$1,070"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }, {
                                "data": ["Lloyd Sadd (Internal Only)", "", "$317,126"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }
                        ],
                        "columnStyle": [{
                                "index": 0,
                                "fontSize": "11pt"
                            }
                        ]
                    }, {
                        "data": ["Indigenous Partner", "", "$140,311"],
                        "children": [{
                                "data": ["DRYSTAR Wall & Ceiling Ltd.", "", "$28,243"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }, {
                                "data": ["Houle Electric Ltd", "", "$112,068"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }
                        ],
                        "columnStyle": [{
                                "index": 0,
                                "fontSize": "11pt"
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "summaryReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 6,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Indigenous Community", "$683,703"],
                        "children": [{
                                "data": ["Albany First Nation", "$28,243"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }, {
                                "data": ["Alexander First Nation", "$225,196"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }, {
                                "data": ["Alexis Nakota Sioux Nation", "$113,138"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }, {
                                "data": ["God's Lake First Nation", "$317,126"],
                                "children": [],
                                "columnStyle": [{
                                        "index": 0,
                                        "indentations": 1
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "communityReportDataTables": [],
        "icprojectsAggregate": null
    };
    return data;
}
export function getSocialProcurementCommunityProjectTestData(): any {
    const data = {
        "reportTitle": "Project Indigenous Procurement Report",
        "reportType": "social-procurement-community-project",
        "companyName": "Chandos Construction",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "Feb 1, 2019 - Sep 14, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Sep 14, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": "urn:nisto-link:id:project:-3513--8339",
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "beneficiaries": [],
            "dateFrom": "2019-02-01",
            "dateTo": "2021-09-14"
        },
        "organizationName": "Chandos Construction",
        "title": "Project Indigenous Procurement Report",
        "orgAggregate": {
            "facetDetail": {
                "identifier": "urn:nisto-link:id:project:-3513--8339",
                "displayName": "Three Trees Pad Build",
                "recordType": ["Project"],
                "startDate": "2019-02-01",
                "ownerVendor": "urn:nisto-link:id:vendor:chandos",
                "poId": "2"
            },
            "totalProjectExpenses": {
                "value": 2686781,
                "valueFormatted": "$2,686,781",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 85058030,
                "valueFormatted": "$85,058,030",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 1722107,
                    "valueFormatted": "$1,722,107",
                    "percentage": 64.1,
                    "percentageFormatted": "64.1%"
                },
                "esb": {
                    "value": 312172,
                    "valueFormatted": "$312,172",
                    "percentage": 11.62,
                    "percentageFormatted": "11.62%"
                },
                "esbDiverse": {
                    "value": 193,
                    "valueFormatted": "$193",
                    "percentage": 0.01,
                    "percentageFormatted": "0.01%"
                },
                "esbIndigenous": {
                    "value": 311979,
                    "valueFormatted": "$311,979",
                    "percentage": 11.61,
                    "percentageFormatted": "11.61%"
                },
                "localSpend": {
                    "value": 1409935,
                    "valueFormatted": "$1,409,935",
                    "percentage": 52.48,
                    "percentageFormatted": "52.48%"
                },
                "smeSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 726,
                            "valueFormatted": "$726",
                            "percentage": 0.03,
                            "percentageFormatted": "0.03%"
                        },
                        "services": {
                            "value": 311253,
                            "valueFormatted": "$311,253",
                            "percentage": 11.58,
                            "percentageFormatted": "11.58%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 311979,
                            "valueFormatted": "$311,979",
                            "percentage": 11.61,
                            "percentageFormatted": "11.61%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 137237,
                            "valueFormatted": "$137,237",
                            "percentage": 5.11,
                            "percentageFormatted": "5.11%"
                        },
                        "services": {
                            "value": 1180979,
                            "valueFormatted": "$1,180,979",
                            "percentage": 43.96,
                            "percentageFormatted": "43.96%"
                        },
                        "equipment": {
                            "value": 91719,
                            "valueFormatted": "$91,719",
                            "percentage": 3.41,
                            "percentageFormatted": "3.41%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 1409935,
                            "valueFormatted": "$1,409,935",
                            "percentage": 52.48,
                            "percentageFormatted": "52.48%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 137963,
                            "valueFormatted": "$137,963",
                            "percentage": 5.13,
                            "percentageFormatted": "5.13%"
                        },
                        "services": {
                            "value": 2445484,
                            "valueFormatted": "$2,445,484",
                            "percentage": 91.02,
                            "percentageFormatted": "91.02%"
                        },
                        "equipment": {
                            "value": 91719,
                            "valueFormatted": "$91,719",
                            "percentage": 3.41,
                            "percentageFormatted": "3.41%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 2675166,
                            "valueFormatted": "$2,675,166",
                            "percentage": 99.57,
                            "percentageFormatted": "99.57%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 953059,
                            "valueFormatted": "$953,059",
                            "percentage": 35.47,
                            "percentageFormatted": "35.47%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 953059,
                            "valueFormatted": "$953,059",
                            "percentage": 35.47,
                            "percentageFormatted": "35.47%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 408627,
                    "valueFormatted": "$408,627",
                    "percentage": 0.48,
                    "percentageFormatted": "0.48%"
                },
                "esbDiverse": {
                    "value": 85964,
                    "valueFormatted": "$85,964",
                    "percentage": 0.1,
                    "percentageFormatted": "0.1%"
                },
                "esbIndigenous": {
                    "value": 322663,
                    "valueFormatted": "$322,663",
                    "percentage": 0.38,
                    "percentageFormatted": "0.38%"
                },
                "localSpend": {
                    "value": 1495706,
                    "valueFormatted": "$1,495,706",
                    "percentage": 1.76,
                    "percentageFormatted": "1.76%"
                },
                "smeSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "sociallyFocusedSpend": {
                    "value": 28243,
                    "valueFormatted": "$28,243",
                    "percentage": 0.03,
                    "percentageFormatted": "0.03%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "indigenous": {
                "directIndigenousSpend": {
                    "value": 322663,
                    "valueFormatted": "$322,663",
                    "percentage": 0.38,
                    "percentageFormatted": "0.38%"
                },
                "certifiedBusinessSpend": {
                    "value": 85771,
                    "valueFormatted": "$85,771",
                    "percentage": 0.1,
                    "percentageFormatted": "0.1%"
                },
                "communityOwnedSpend": {
                    "value": 294420,
                    "valueFormatted": "$294,420",
                    "percentage": 0.35,
                    "percentageFormatted": "0.35%"
                },
                "relationshipSpend": {
                    "value": 28243,
                    "valueFormatted": "$28,243",
                    "percentage": 0.03,
                    "percentageFormatted": "0.03%"
                },
                "selfDeclaredBusinessSpend": {
                    "value": 28243,
                    "valueFormatted": "$28,243",
                    "percentage": 0.03,
                    "percentageFormatted": "0.03%"
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 6455.25,
                        "valueFormatted": "6455.25",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 140973,
                        "valueFormatted": "$140,973",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 703.25,
                        "valueFormatted": "703.25",
                        "percentage": 10.89,
                        "percentageFormatted": "10.89%"
                    },
                    "wages": {
                        "value": 15064,
                        "valueFormatted": "$15,064",
                        "percentage": 10.69,
                        "percentageFormatted": "10.69%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 543,
                        "valueFormatted": "543",
                        "percentage": 8.41,
                        "percentageFormatted": "8.41%"
                    },
                    "wages": {
                        "value": 11898,
                        "valueFormatted": "$11,898",
                        "percentage": 8.44,
                        "percentageFormatted": "8.44%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 5209,
                        "valueFormatted": "5209",
                        "percentage": 80.69,
                        "percentageFormatted": "80.69%"
                    },
                    "wages": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 80.87,
                        "percentageFormatted": "80.87%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 5752,
                        "valueFormatted": "5752",
                        "percentage": 89.11,
                        "percentageFormatted": "89.11%"
                    },
                    "wages": {
                        "value": 125909,
                        "valueFormatted": "$125,909",
                        "percentage": 89.31,
                        "percentageFormatted": "89.31%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 137524,
                    "valueFormatted": "$137,524",
                    "percentage": 0.16,
                    "percentageFormatted": "0.16%"
                },
                "overall": {
                    "diverse": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 0.13,
                        "percentageFormatted": "0.13%"
                    },
                    "indigenous": {
                        "value": 22582,
                        "valueFormatted": "$22,582",
                        "percentage": 0.03,
                        "percentageFormatted": "0.03%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 10684,
                        "valueFormatted": "$10,684",
                        "percentage": 0.01,
                        "percentageFormatted": "0.01%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 0.13,
                        "percentageFormatted": "0.13%"
                    },
                    "indigenous": {
                        "value": 11898,
                        "valueFormatted": "$11,898",
                        "percentage": 0.01,
                        "percentageFormatted": "0.01%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 4,
            "indigenousCommunityContribution": 0,
            "indigenousEmployment": 4,
            "socialProcurement": 0,
            "diverseProjectBenefit": 0,
            "diverseCommunityContribution": 0,
            "diverseEmployment": 0
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999
                },
                "resultMeta": {
                    "matched": 4,
                    "included": 4
                },
                "results": [{
                        "totals": {
                            "revenue": {
                                "projectRevenue": 75087,
                                "indigenousProjectRevenue": 75087
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 85771,
                                    "totalProjectSpend": 85771,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 10684,
                                    "totalIndigenousProjectContributions": 10684,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 85771,
                                    "totalDiverseSpend": 85771,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 85771
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 5194,
                                    "totalIndigenousEmploymentValue": 5194,
                                    "totalManHours": 233,
                                    "totalIndigenousManHours": 233
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0490",
                            "displayName": "Aishihik First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 28243,
                                "indigenousProjectRevenue": 0
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 28243,
                                    "totalProjectSpend": 28243,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 28243,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 28243,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0296",
                            "displayName": "God's Lake First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 2
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 3040,
                                    "totalIndigenousEmploymentValue": 3040,
                                    "totalManHours": 141,
                                    "totalIndigenousManHours": 141
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441",
                            "displayName": "Paul First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 3
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 3664,
                                    "totalIndigenousEmploymentValue": 3664,
                                    "totalManHours": 169,
                                    "totalIndigenousManHours": 169
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0432",
                            "displayName": "Tsuut'ina Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 4
                    }
                ]
            }
        },
        "clientAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999,
                    "type": ["Vendor"]
                },
                "resultMeta": {
                    "matched": 1,
                    "included": 1
                },
                "results": [{
                        "organization": {
                            "orgName": "British Columbia Housing Management Commission",
                            "indigenousOwnership": 0,
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["Suite 1701-4555 Kingsway"],
                                    "orgCity": "Burnaby",
                                    "stateOrProvince": "British Columbia",
                                    "postalCode": "V5H 4V8"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "BCHousing",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:chandos",
                                "recordType": ["Organization", "Vendor", "Payee", "Tenant"],
                                "displayName": "Chandos Construction"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "British Columbia Housing Management Commission",
                            "sortKey": "british columbia housing management commission",
                            "transactionId": "urn:nisto-link:id:tx:2021-06-21T22-55-07.362Z-7203",
                            "identifier": "urn:nisto-link:id:vendor:bchousing-855",
                            "etag": "E6288788497659543194",
                            "status": "live",
                            "version": "3",
                            "lastUpdated": "2021-06-21T23:05:16+0000",
                            "createdTime": "2021-06-21T22:55:08+0000",
                            "previousVersion": "urn:nisto-link:id:vendor:bchousing-855:2"
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 1,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 540965,
                                    "totalProjectSpend": 540965,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 10280,
                                    "totalIndigenousProjectContributions": 10280,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 112851,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 123131,
                                    "indigenousDetail": {
                                        "totalIndigenousCommunityOwnedSpend": 98902,
                                        "totalIndigenousRelationshipSpend": 20158,
                                        "totalIndigenousCertifiedBusinessSpend": 69683,
                                        "totalIndigenousSelfDeclaredBusinessSpend": 20158
                                    },
                                    "totalDiverseSpend": 69683,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 20158,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 33290
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        }
                    }
                ]
            }
        },
        "expenseDiversityReportDataTables": [],
        "projectSummaryReportDataTable": {
            "title": "",
            "headers": [],
            "rows": [],
            "totalRow": {
                "children": [],
                "data": []
            },
            "total": {
                "value": 0,
                "valueFormatted": "0",
                "percentage": 0,
                "percentageFormatted": "0%"
            },
            "totalTitle": "",
            "color": "",
            "hideHeaders": false,
            "pageBreakAfter": false,
            "parentsBold": true,
            "hideIfZeroTotal": false,
            "className": "",
            "autoCalculateTotalRow": false
        },
        "payeeCommunityReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Aishihik First Nation", "Total Community Spend", "$57,116"]
                    }, {
                        "data": ["Indigenous Community Owned Businesses", "", "$57,116"],
                        "children": [{
                                "data": ["3E Glass Ltd.", "", "$57,116"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["God's Lake First Nation", "Total Community Spend", "$16,401"]
                    }, {
                        "data": ["Community Relationships", "Total Relationship Spend", "$16,401"],
                        "children": [{
                                "data": ["DRYSTAR Wall & Ceiling Ltd.", "", "$16,401"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "indigenousTypeReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Certified Indigenous Owned Business", "Total Certified Indigenous Owned Business Spend", "$57,116"],
                        "children": [{
                                "data": ["3E Glass Ltd.", "", "$57,116"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "summaryReportDataTables": [],
        "communityReportDataTables": []
    };
    return data;
}
export function getProjectEmploymentTestData(): any {
    const data = {
        "reportTitle": "Project Employment Diversity Report",
        "reportType": "diversity-in-employment-project",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "Mar 20, 2019 - Jul 29, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Jul 29, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": {
                "index": 1,
                "identifier": "urn:nisto-link:id:project:-3338--4336",
                "displayName": "BC Housing IPD - 1st & Clark (1)",
                "recordType": ["Project"],
                "projectId": " 3338-",
                "subProjectId": " 3338-",
                "poId": "1"
            },
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "dateFrom": "2019-03-20",
            "dateTo": "2021-07-29"
        },
        "organizationName": "Chandos Construction",
        "title": "Project Employment Diversity Report",
        "orgAggregate": {
            "totalProjectExpenses": {
                "value": 538965,
                "valueFormatted": "$538,965",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 665572,
                "valueFormatted": "$665,572",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 70116,
                    "valueFormatted": "$70,116",
                    "percentage": 13.01,
                    "percentageFormatted": "13.01%"
                },
                "esb": {
                    "value": 47106,
                    "valueFormatted": "$47,106",
                    "percentage": 8.74,
                    "percentageFormatted": "8.74%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 47106,
                    "valueFormatted": "$47,106",
                    "percentage": 8.74,
                    "percentageFormatted": "8.74%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 23010,
                    "valueFormatted": "$23,010",
                    "percentage": 4.27,
                    "percentageFormatted": "4.27%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 47106,
                    "valueFormatted": "$47,106",
                    "percentage": 7.08,
                    "percentageFormatted": "7.08%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 47106,
                    "valueFormatted": "$47,106",
                    "percentage": 7.08,
                    "percentageFormatted": "7.08%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 33290,
                    "valueFormatted": "$33,290",
                    "percentage": 5,
                    "percentageFormatted": "5%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 10,
                        "valueFormatted": "10",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 10,
                        "valueFormatted": "10",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 10,
                        "valueFormatted": "10",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 10000,
                    "valueFormatted": "$10,000",
                    "percentage": 1.5,
                    "percentageFormatted": "1.5%"
                },
                "overall": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 1.5,
                        "percentageFormatted": "1.5%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 1.5,
                        "percentageFormatted": "1.5%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 0,
            "indigenousCommunityContribution": 0,
            "indigenousEmployment": 0,
            "socialProcurement": 15,
            "diverseProjectBenefit": 0,
            "diverseCommunityContribution": 0,
            "diverseEmployment": 0
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 20
                },
                "resultMeta": {
                    "matched": 2,
                    "included": 2
                },
                "results": [{
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 10000,
                                    "totalIndigenousEmploymentValue": 10000,
                                    "totalManHours": 10,
                                    "totalIndigenousManHours": 10
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0142",
                            "displayName": "Albany First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 47106,
                                "indigenousProjectRevenue": 47106
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 47106,
                                    "totalProjectSpend": 47106,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 47106,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0161",
                            "displayName": "Curve Lake First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 2
                    }
                ]
            }
        },
        "clientAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999,
                    "type": ["Vendor"]
                },
                "resultMeta": {
                    "matched": 1,
                    "included": 1
                },
                "results": [{
                        "organization": {
                            "orgName": "British Columbia Housing Management Commission",
                            "indigenousOwnership": 0,
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["Suite 1701-4555 Kingsway"],
                                    "orgCity": "Burnaby",
                                    "stateOrProvince": "British Columbia",
                                    "postalCode": "V5H 4V8"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "BCHousing",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:chandos",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Chandos Construction"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "British Columbia Housing Management Commission",
                            "sortKey": "british columbia housing management commission",
                            "transactionId": "urn:nisto-link:id:tx:2021-06-21T22-55-07.362Z-7203",
                            "identifier": "urn:nisto-link:id:vendor:bchousing-855",
                            "etag": "E6288788497659543194",
                            "status": "live",
                            "version": "3",
                            "lastUpdated": "2021-06-21T23:05:16+0000",
                            "createdTime": "2021-06-21T22:55:08+0000",
                            "previousVersion": "urn:nisto-link:id:vendor:bchousing-855:2"
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 1,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 665572,
                                    "totalProjectRevenue": 665572,
                                    "totalGeneralRevenue": 0
                                }
                            }
                        }
                    }
                ]
            }
        }
    }
    
    return data;
}

export function getCorporateEmploymentTestData(): any {
    const data = {
        "reportTitle": "Corporate Employment Diversity Report",
        "reportType": "diversity-in-employment-corporate",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "All - Jul 29, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Jul 29, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": [],
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "dateFrom": "",
            "dateTo": "2021-07-29"
        },
        "organizationName": "Chandos Construction",
        "title": "Corporate Employment Diversity Report",
        "orgAggregate": {
            "totalProjectExpenses": {
                "value": 3225746,
                "valueFormatted": "$3,225,746",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 81723602,
                "valueFormatted": "$81,723,602",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 390833,
                    "valueFormatted": "$390,833",
                    "percentage": 12.12,
                    "percentageFormatted": "12.12%"
                },
                "esb": {
                    "value": 159174,
                    "valueFormatted": "$159,174",
                    "percentage": 4.93,
                    "percentageFormatted": "4.93%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 159174,
                    "valueFormatted": "$159,174",
                    "percentage": 4.93,
                    "percentageFormatted": "4.93%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 231659,
                    "valueFormatted": "$231,659",
                    "percentage": 7.18,
                    "percentageFormatted": "7.18%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 159174,
                            "valueFormatted": "$159,174",
                            "percentage": 4.93,
                            "percentageFormatted": "4.93%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 159174,
                            "valueFormatted": "$159,174",
                            "percentage": 4.93,
                            "percentageFormatted": "4.93%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 231659,
                            "valueFormatted": "$231,659",
                            "percentage": 7.18,
                            "percentageFormatted": "7.18%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 231659,
                            "valueFormatted": "$231,659",
                            "percentage": 7.18,
                            "percentageFormatted": "7.18%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 143290,
                            "valueFormatted": "$143,290",
                            "percentage": 4.44,
                            "percentageFormatted": "4.44%"
                        },
                        "services": {
                            "value": 2915598,
                            "valueFormatted": "$2,915,598",
                            "percentage": 90.39,
                            "percentageFormatted": "90.39%"
                        },
                        "equipment": {
                            "value": 155647,
                            "valueFormatted": "$155,647",
                            "percentage": 4.83,
                            "percentageFormatted": "4.83%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 3214535,
                            "valueFormatted": "$3,214,535",
                            "percentage": 99.65,
                            "percentageFormatted": "99.65%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 143290,
                            "valueFormatted": "$143,290",
                            "percentage": 4.44,
                            "percentageFormatted": "4.44%"
                        },
                        "services": {
                            "value": 2524765,
                            "valueFormatted": "$2,524,765",
                            "percentage": 78.27,
                            "percentageFormatted": "78.27%"
                        },
                        "equipment": {
                            "value": 155647,
                            "valueFormatted": "$155,647",
                            "percentage": 4.83,
                            "percentageFormatted": "4.83%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 2823702,
                            "valueFormatted": "$2,823,702",
                            "percentage": 87.54,
                            "percentageFormatted": "87.54%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 159174,
                    "valueFormatted": "$159,174",
                    "percentage": 0.19,
                    "percentageFormatted": "0.19%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 159174,
                    "valueFormatted": "$159,174",
                    "percentage": 0.19,
                    "percentageFormatted": "0.19%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 241939,
                    "valueFormatted": "$241,939",
                    "percentage": 0.3,
                    "percentageFormatted": "0.3%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 6413.5,
                        "valueFormatted": "6413.5",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 151018,
                        "valueFormatted": "$151,018",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 6289.5,
                        "valueFormatted": "6289.5",
                        "percentage": 98.07,
                        "percentageFormatted": "98.07%"
                    },
                    "wages": {
                        "value": 137478,
                        "valueFormatted": "$137,478",
                        "percentage": 91.03,
                        "percentageFormatted": "91.03%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 124,
                        "valueFormatted": "124",
                        "percentage": 1.93,
                        "percentageFormatted": "1.93%"
                    },
                    "wages": {
                        "value": 13540,
                        "valueFormatted": "$13,540",
                        "percentage": 8.97,
                        "percentageFormatted": "8.97%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 124,
                        "valueFormatted": "124",
                        "percentage": 1.93,
                        "percentageFormatted": "1.93%"
                    },
                    "wages": {
                        "value": 13540,
                        "valueFormatted": "$13,540",
                        "percentage": 8.97,
                        "percentageFormatted": "8.97%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 6289.5,
                        "valueFormatted": "6289.5",
                        "percentage": 98.07,
                        "percentageFormatted": "98.07%"
                    },
                    "wages": {
                        "value": 137478,
                        "valueFormatted": "$137,478",
                        "percentage": 91.03,
                        "percentageFormatted": "91.03%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 13540,
                    "valueFormatted": "$13,540",
                    "percentage": 0.02,
                    "percentageFormatted": "0.02%"
                },
                "overall": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 13540,
                        "valueFormatted": "$13,540",
                        "percentage": 0.02,
                        "percentageFormatted": "0.02%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 13540,
                        "valueFormatted": "$13,540",
                        "percentage": 0.02,
                        "percentageFormatted": "0.02%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 6,
            "indigenousCommunityContribution": 0.75,
            "indigenousEmployment": 15,
            "diverseProjectBenefit": 5,
            "diverseEmployment": 4,
            "socialProcurement": 7,
            "diverseCommunityContribution": 4
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 20
                },
                "resultMeta": {
                    "matched": 2,
                    "included": 2
                },
                "results": [{
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 13282,
                                    "totalIndigenousEmploymentValue": 13282,
                                    "totalManHours": 112,
                                    "totalIndigenousManHours": 112
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0142",
                            "displayName": "Albany First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 159174,
                                "indigenousProjectRevenue": 159174
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 159174,
                                    "totalProjectSpend": 159174,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 159174,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0161",
                            "displayName": "Curve Lake First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 2
                    }
                ]
            }
        },
        "clientAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999,
                    "type": ["Vendor"]
                },
                "resultMeta": {
                    "matched": 1,
                    "included": 1
                },
                "results": [{
                        "organization": {
                            "orgName": "British Columbia Housing Management Commission",
                            "indigenousOwnership": 0,
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["Suite 1701-4555 Kingsway"],
                                    "orgCity": "Burnaby",
                                    "stateOrProvince": "British Columbia",
                                    "postalCode": "V5H 4V8"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "BCHousing",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:chandos",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Chandos Construction"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "British Columbia Housing Management Commission",
                            "sortKey": "british columbia housing management commission",
                            "transactionId": "urn:nisto-link:id:tx:2021-06-21T22-55-07.362Z-7203",
                            "identifier": "urn:nisto-link:id:vendor:bchousing-855",
                            "etag": "E6288788497659543194",
                            "status": "live",
                            "version": "3",
                            "lastUpdated": "2021-06-21T23:05:16+0000",
                            "createdTime": "2021-06-21T22:55:08+0000",
                            "previousVersion": "urn:nisto-link:id:vendor:bchousing-855:2"
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 1,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 81723602,
                                    "totalProjectRevenue": 81723602,
                                    "totalGeneralRevenue": 0
                                }
                            }
                        }
                    }
                ]
            }
        }
    }
    
    return data;
}


export function getProjectSocialProcurementTestData(): any {
    const data = {
        "reportTitle": "Project Social Procurement Report",
        "reportType": "social-procurement-project",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "Mar 20, 2019 - Jul 29, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Jul 29, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": {
                "index": 1,
                "identifier": "urn:nisto-link:id:project:-3338--4336",
                "displayName": "BC Housing IPD - 1st & Clark (1)",
                "recordType": ["Project"],
                "projectId": " 3338-",
                "subProjectId": " 3338-",
                "poId": "1"
            },
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "dateFrom": "2019-03-20",
            "dateTo": "2021-07-29"
        },
        "organizationName": "Chandos Construction",
        "title": "Project Social Procurement Report",
        "orgAggregate": {
            "totalProjectExpenses": {
                "value": 538965,
                "valueFormatted": "$538,965",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 665572,
                "valueFormatted": "$665,572",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 70116,
                    "valueFormatted": "$70,116",
                    "percentage": 13.01,
                    "percentageFormatted": "13.01%"
                },
                "esb": {
                    "value": 47106,
                    "valueFormatted": "$47,106",
                    "percentage": 8.74,
                    "percentageFormatted": "8.74%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 47106,
                    "valueFormatted": "$47,106",
                    "percentage": 8.74,
                    "percentageFormatted": "8.74%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 23010,
                    "valueFormatted": "$23,010",
                    "percentage": 4.27,
                    "percentageFormatted": "4.27%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 47106,
                    "valueFormatted": "$47,106",
                    "percentage": 7.08,
                    "percentageFormatted": "7.08%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 47106,
                    "valueFormatted": "$47,106",
                    "percentage": 7.08,
                    "percentageFormatted": "7.08%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 33290,
                    "valueFormatted": "$33,290",
                    "percentage": 5,
                    "percentageFormatted": "5%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 10,
                        "valueFormatted": "10",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 10,
                        "valueFormatted": "10",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 10,
                        "valueFormatted": "10",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 10000,
                    "valueFormatted": "$10,000",
                    "percentage": 1.5,
                    "percentageFormatted": "1.5%"
                },
                "overall": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 1.5,
                        "percentageFormatted": "1.5%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 10000,
                        "valueFormatted": "$10,000",
                        "percentage": 1.5,
                        "percentageFormatted": "1.5%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 0,
            "indigenousCommunityContribution": 0,
            "indigenousEmployment": 0,
            "socialProcurement": 15,
            "diverseProjectBenefit": 0,
            "diverseCommunityContribution": 0,
            "diverseEmployment": 0
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 20
                },
                "resultMeta": {
                    "matched": 2,
                    "included": 2
                },
                "results": [{
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 10000,
                                    "totalIndigenousEmploymentValue": 10000,
                                    "totalManHours": 10,
                                    "totalIndigenousManHours": 10
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0142",
                            "displayName": "Albany First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 47106,
                                "indigenousProjectRevenue": 47106
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 47106,
                                    "totalProjectSpend": 47106,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 47106,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0161",
                            "displayName": "Curve Lake First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 2
                    }
                ]
            }
        },
        "clientAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999,
                    "type": ["Vendor"]
                },
                "resultMeta": {
                    "matched": 1,
                    "included": 1
                },
                "results": [{
                        "organization": {
                            "orgName": "British Columbia Housing Management Commission",
                            "indigenousOwnership": 0,
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["Suite 1701-4555 Kingsway"],
                                    "orgCity": "Burnaby",
                                    "stateOrProvince": "British Columbia",
                                    "postalCode": "V5H 4V8"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "BCHousing",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:chandos",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Chandos Construction"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "British Columbia Housing Management Commission",
                            "sortKey": "british columbia housing management commission",
                            "transactionId": "urn:nisto-link:id:tx:2021-06-21T22-55-07.362Z-7203",
                            "identifier": "urn:nisto-link:id:vendor:bchousing-855",
                            "etag": "E6288788497659543194",
                            "status": "live",
                            "version": "3",
                            "lastUpdated": "2021-06-21T23:05:16+0000",
                            "createdTime": "2021-06-21T22:55:08+0000",
                            "previousVersion": "urn:nisto-link:id:vendor:bchousing-855:2"
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 1,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 665572,
                                    "totalProjectRevenue": 665572,
                                    "totalGeneralRevenue": 0
                                }
                            }
                        }
                    }
                ]
            }
        }
    }
    return data;
}

export function getCorporateSocialProcurementTestData(): any {
    const data = {
        "reportTitle": "Summary Social Procurement Report",
        "reportType": "social-procurement-corporate",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "All - Jul 29, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Jul 29, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": [],
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "dateFrom": "",
            "dateTo": "2021-07-29"
        },
        "organizationName": "Chandos Construction",
        "title": "Summary Social Procurement Report",
        "orgAggregate": {
            "totalProjectExpenses": {
                "value": 3225746,
                "valueFormatted": "$3,225,746",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 81723602,
                "valueFormatted": "$81,723,602",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 390833,
                    "valueFormatted": "$390,833",
                    "percentage": 12.12,
                    "percentageFormatted": "12.12%"
                },
                "esb": {
                    "value": 159174,
                    "valueFormatted": "$159,174",
                    "percentage": 4.93,
                    "percentageFormatted": "4.93%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 159174,
                    "valueFormatted": "$159,174",
                    "percentage": 4.93,
                    "percentageFormatted": "4.93%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 231659,
                    "valueFormatted": "$231,659",
                    "percentage": 7.18,
                    "percentageFormatted": "7.18%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 159174,
                            "valueFormatted": "$159,174",
                            "percentage": 4.93,
                            "percentageFormatted": "4.93%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 159174,
                            "valueFormatted": "$159,174",
                            "percentage": 4.93,
                            "percentageFormatted": "4.93%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 231659,
                            "valueFormatted": "$231,659",
                            "percentage": 7.18,
                            "percentageFormatted": "7.18%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 231659,
                            "valueFormatted": "$231,659",
                            "percentage": 7.18,
                            "percentageFormatted": "7.18%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 143290,
                            "valueFormatted": "$143,290",
                            "percentage": 4.44,
                            "percentageFormatted": "4.44%"
                        },
                        "services": {
                            "value": 2915598,
                            "valueFormatted": "$2,915,598",
                            "percentage": 90.39,
                            "percentageFormatted": "90.39%"
                        },
                        "equipment": {
                            "value": 155647,
                            "valueFormatted": "$155,647",
                            "percentage": 4.83,
                            "percentageFormatted": "4.83%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 3214535,
                            "valueFormatted": "$3,214,535",
                            "percentage": 99.65,
                            "percentageFormatted": "99.65%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 143290,
                            "valueFormatted": "$143,290",
                            "percentage": 4.44,
                            "percentageFormatted": "4.44%"
                        },
                        "services": {
                            "value": 2524765,
                            "valueFormatted": "$2,524,765",
                            "percentage": 78.27,
                            "percentageFormatted": "78.27%"
                        },
                        "equipment": {
                            "value": 155647,
                            "valueFormatted": "$155,647",
                            "percentage": 4.83,
                            "percentageFormatted": "4.83%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 2823702,
                            "valueFormatted": "$2,823,702",
                            "percentage": 87.54,
                            "percentageFormatted": "87.54%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 159174,
                    "valueFormatted": "$159,174",
                    "percentage": 0.19,
                    "percentageFormatted": "0.19%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 159174,
                    "valueFormatted": "$159,174",
                    "percentage": 0.19,
                    "percentageFormatted": "0.19%"
                },
                "localSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "smeSpend": {
                    "value": 241939,
                    "valueFormatted": "$241,939",
                    "percentage": 0.3,
                    "percentageFormatted": "0.3%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 6413.5,
                        "valueFormatted": "6413.5",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 151018,
                        "valueFormatted": "$151,018",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 6289.5,
                        "valueFormatted": "6289.5",
                        "percentage": 98.07,
                        "percentageFormatted": "98.07%"
                    },
                    "wages": {
                        "value": 137478,
                        "valueFormatted": "$137,478",
                        "percentage": 91.03,
                        "percentageFormatted": "91.03%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 124,
                        "valueFormatted": "124",
                        "percentage": 1.93,
                        "percentageFormatted": "1.93%"
                    },
                    "wages": {
                        "value": 13540,
                        "valueFormatted": "$13,540",
                        "percentage": 8.97,
                        "percentageFormatted": "8.97%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 124,
                        "valueFormatted": "124",
                        "percentage": 1.93,
                        "percentageFormatted": "1.93%"
                    },
                    "wages": {
                        "value": 13540,
                        "valueFormatted": "$13,540",
                        "percentage": 8.97,
                        "percentageFormatted": "8.97%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 6289.5,
                        "valueFormatted": "6289.5",
                        "percentage": 98.07,
                        "percentageFormatted": "98.07%"
                    },
                    "wages": {
                        "value": 137478,
                        "valueFormatted": "$137,478",
                        "percentage": 91.03,
                        "percentageFormatted": "91.03%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 13540,
                    "valueFormatted": "$13,540",
                    "percentage": 0.02,
                    "percentageFormatted": "0.02%"
                },
                "overall": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 13540,
                        "valueFormatted": "$13,540",
                        "percentage": 0.02,
                        "percentageFormatted": "0.02%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 13540,
                        "valueFormatted": "$13,540",
                        "percentage": 0.02,
                        "percentageFormatted": "0.02%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 6,
            "indigenousCommunityContribution": 0.75,
            "indigenousEmployment": 15,
            "diverseProjectBenefit": 5,
            "diverseEmployment": 4,
            "socialProcurement": 7,
            "diverseCommunityContribution": 4
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 20
                },
                "resultMeta": {
                    "matched": 2,
                    "included": 2
                },
                "results": [{
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 13282,
                                    "totalIndigenousEmploymentValue": 13282,
                                    "totalManHours": 112,
                                    "totalIndigenousManHours": 112
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0142",
                            "displayName": "Albany First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 159174,
                                "indigenousProjectRevenue": 159174
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 159174,
                                    "totalProjectSpend": 159174,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 159174,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0161",
                            "displayName": "Curve Lake First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 2
                    }
                ]
            }
        },
        "clientAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999,
                    "type": ["Vendor"]
                },
                "resultMeta": {
                    "matched": 1,
                    "included": 1
                },
                "results": [{
                        "organization": {
                            "orgName": "British Columbia Housing Management Commission",
                            "indigenousOwnership": 0,
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["Suite 1701-4555 Kingsway"],
                                    "orgCity": "Burnaby",
                                    "stateOrProvince": "British Columbia",
                                    "postalCode": "V5H 4V8"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "BCHousing",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:chandos",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Chandos Construction"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "British Columbia Housing Management Commission",
                            "sortKey": "british columbia housing management commission",
                            "transactionId": "urn:nisto-link:id:tx:2021-06-21T22-55-07.362Z-7203",
                            "identifier": "urn:nisto-link:id:vendor:bchousing-855",
                            "etag": "E6288788497659543194",
                            "status": "live",
                            "version": "3",
                            "lastUpdated": "2021-06-21T23:05:16+0000",
                            "createdTime": "2021-06-21T22:55:08+0000",
                            "previousVersion": "urn:nisto-link:id:vendor:bchousing-855:2"
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 1,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 81723602,
                                    "totalProjectRevenue": 81723602,
                                    "totalGeneralRevenue": 0
                                }
                            }
                        }
                    }
                ]
            }
        }
    }
    
    return data;
}

export function getCorporateSocialImpactTestData(): any {
    const data = {
        "reportTitle": "Summary Social Impact Report",
        "reportType": "social-impact-corporate",
        "companyName": "Chandos Construction",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "All - Sep 28, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Sep 28, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": [],
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "beneficiaries": [],
            "dateFrom": "",
            "dateTo": "2021-09-28"
        },
        "organizationName": "Chandos Construction",
        "title": "Summary Social Impact Report",
        "orgAggregate": {
            "facetDetail": {
                "identifier": "urn:nisto-link:id:vendor:chandos",
                "displayName": "Chandos Construction",
                "recordType": ["Organization", "Vendor", "Payee", "Tenant"],
                "indigenousOwned": false,
                "vendorCode": "chandos",
                "defaultExpenseClass": "uncategorized"
            },
            "totalProjectExpenses": {
                "value": 3241746,
                "valueFormatted": "$3,241,746",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 85723602,
                "valueFormatted": "$85,723,602",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 2489976,
                    "valueFormatted": "$2,489,976",
                    "percentage": 76.81,
                    "percentageFormatted": "76.81%"
                },
                "esb": {
                    "value": 1059883,
                    "valueFormatted": "$1,059,883",
                    "percentage": 32.69,
                    "percentageFormatted": "32.69%"
                },
                "esbDiverse": {
                    "value": 193,
                    "valueFormatted": "$193",
                    "percentage": 0.01,
                    "percentageFormatted": "0.01%"
                },
                "esbIndigenous": {
                    "value": 1059690,
                    "valueFormatted": "$1,059,690",
                    "percentage": 32.69,
                    "percentageFormatted": "32.69%"
                },
                "localSpend": {
                    "value": 1409935,
                    "valueFormatted": "$1,409,935",
                    "percentage": 43.49,
                    "percentageFormatted": "43.49%"
                },
                "smeSpend": {
                    "value": 20158,
                    "valueFormatted": "$20,158",
                    "percentage": 0.62,
                    "percentageFormatted": "0.62%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 726,
                            "valueFormatted": "$726",
                            "percentage": 0.02,
                            "percentageFormatted": "0.02%"
                        },
                        "services": {
                            "value": 1011132,
                            "valueFormatted": "$1,011,132",
                            "percentage": 31.19,
                            "percentageFormatted": "31.19%"
                        },
                        "equipment": {
                            "value": 47832,
                            "valueFormatted": "$47,832",
                            "percentage": 1.48,
                            "percentageFormatted": "1.48%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 1059690,
                            "valueFormatted": "$1,059,690",
                            "percentage": 32.69,
                            "percentageFormatted": "32.69%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 137237,
                            "valueFormatted": "$137,237",
                            "percentage": 4.23,
                            "percentageFormatted": "4.23%"
                        },
                        "services": {
                            "value": 1180979,
                            "valueFormatted": "$1,180,979",
                            "percentage": 36.43,
                            "percentageFormatted": "36.43%"
                        },
                        "equipment": {
                            "value": 91719,
                            "valueFormatted": "$91,719",
                            "percentage": 2.83,
                            "percentageFormatted": "2.83%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 1409935,
                            "valueFormatted": "$1,409,935",
                            "percentage": 43.49,
                            "percentageFormatted": "43.49%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 20158,
                            "valueFormatted": "$20,158",
                            "percentage": 0.62,
                            "percentageFormatted": "0.62%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 20158,
                            "valueFormatted": "$20,158",
                            "percentage": 0.62,
                            "percentageFormatted": "0.62%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 144016,
                            "valueFormatted": "$144,016",
                            "percentage": 4.44,
                            "percentageFormatted": "4.44%"
                        },
                        "services": {
                            "value": 2898725,
                            "valueFormatted": "$2,898,725",
                            "percentage": 89.42,
                            "percentageFormatted": "89.42%"
                        },
                        "equipment": {
                            "value": 139551,
                            "valueFormatted": "$139,551",
                            "percentage": 4.3,
                            "percentageFormatted": "4.3%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 3182292,
                            "valueFormatted": "$3,182,292",
                            "percentage": 98.17,
                            "percentageFormatted": "98.17%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 6053,
                            "valueFormatted": "$6,053",
                            "percentage": 0.19,
                            "percentageFormatted": "0.19%"
                        },
                        "services": {
                            "value": 686263,
                            "valueFormatted": "$686,263",
                            "percentage": 21.17,
                            "percentageFormatted": "21.17%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 692316,
                            "valueFormatted": "$692,316",
                            "percentage": 21.36,
                            "percentageFormatted": "21.36%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 1433034,
                    "valueFormatted": "$1,433,034",
                    "percentage": 1.67,
                    "percentageFormatted": "1.67%"
                },
                "esbDiverse": {
                    "value": 314821,
                    "valueFormatted": "$314,821",
                    "percentage": 0.37,
                    "percentageFormatted": "0.37%"
                },
                "esbIndigenous": {
                    "value": 1118213,
                    "valueFormatted": "$1,118,213",
                    "percentage": 1.3,
                    "percentageFormatted": "1.3%"
                },
                "localSpend": {
                    "value": 1528996,
                    "valueFormatted": "$1,528,996",
                    "percentage": 1.78,
                    "percentageFormatted": "1.78%"
                },
                "smeSpend": {
                    "value": 48401,
                    "valueFormatted": "$48,401",
                    "percentage": 0.06,
                    "percentageFormatted": "0.06%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "indigenous": {
                "directIndigenousSpend": {
                    "value": 1118213,
                    "valueFormatted": "$1,118,213",
                    "percentage": 34.49,
                    "percentageFormatted": "34.49%"
                },
                "certifiedBusinessSpend": {
                    "value": 314628,
                    "valueFormatted": "$314,628",
                    "percentage": 9.71,
                    "percentageFormatted": "9.71%"
                },
                "communityOwnedSpend": {
                    "value": 542322,
                    "valueFormatted": "$542,322",
                    "percentage": 16.73,
                    "percentageFormatted": "16.73%"
                },
                "relationshipSpend": {
                    "value": 231520,
                    "valueFormatted": "$231,520",
                    "percentage": 7.14,
                    "percentageFormatted": "7.14%"
                },
                "selfDeclaredBusinessSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 6455.25,
                        "valueFormatted": "6455.25",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 140973,
                        "valueFormatted": "$140,973",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 703.25,
                        "valueFormatted": "703.25",
                        "percentage": 10.89,
                        "percentageFormatted": "10.89%"
                    },
                    "wages": {
                        "value": 15064,
                        "valueFormatted": "$15,064",
                        "percentage": 10.69,
                        "percentageFormatted": "10.69%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 543,
                        "valueFormatted": "543",
                        "percentage": 8.41,
                        "percentageFormatted": "8.41%"
                    },
                    "wages": {
                        "value": 11898,
                        "valueFormatted": "$11,898",
                        "percentage": 8.44,
                        "percentageFormatted": "8.44%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 5209,
                        "valueFormatted": "5209",
                        "percentage": 80.69,
                        "percentageFormatted": "80.69%"
                    },
                    "wages": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 80.87,
                        "percentageFormatted": "80.87%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 5752,
                        "valueFormatted": "5752",
                        "percentage": 89.11,
                        "percentageFormatted": "89.11%"
                    },
                    "wages": {
                        "value": 125909,
                        "valueFormatted": "$125,909",
                        "percentage": 89.31,
                        "percentageFormatted": "89.31%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "occupations": [{
					"occupation": null,
					"occupationDescription": null,
					"overall": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 16671,
							"valueFormatted": "16671",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 5110809,
							"valueFormatted": "$5,110,809",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseUnknown": {
						"averageRate": {
							"value": 29.31,
							"valueFormatted": "$29.31",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 7441,
							"valueFormatted": "7441",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 2185308,
							"valueFormatted": "$2,185,308",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"diverseNo": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenous": {
						"averageRate": {
							"value": 24.4,
							"valueFormatted": "$24.40",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 9230,
							"valueFormatted": "9230",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 2925501,
							"valueFormatted": "$2,925,501",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenousAndDiverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"unknownAndNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					}
				}, {
					"occupation": "administrative-supporters",
					"occupationDescription": "administrative-supporters",
					"overall": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 16,
							"valueFormatted": "16",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 160,
							"valueFormatted": "$160",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseUnknown": {
						"averageRate": {
							"value": 10,
							"valueFormatted": "$10.00",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 8,
							"valueFormatted": "8",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 80,
							"valueFormatted": "$80",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"diverseNo": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenous": {
						"averageRate": {
							"value": 10,
							"valueFormatted": "$10.00",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 8,
							"valueFormatted": "8",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 80,
							"valueFormatted": "$80",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenousAndDiverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"unknownAndNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					}
				}, {
					"occupation": "general-labourers",
					"occupationDescription": "general-labourers",
					"overall": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 1024,
							"valueFormatted": "1024",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 19456,
							"valueFormatted": "$19,456",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseUnknown": {
						"averageRate": {
							"value": 19,
							"valueFormatted": "$19.00",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 520,
							"valueFormatted": "520",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 9880,
							"valueFormatted": "$9,880",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"diverseNo": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenous": {
						"averageRate": {
							"value": 19,
							"valueFormatted": "$19.00",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 504,
							"valueFormatted": "504",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 9576,
							"valueFormatted": "$9,576",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenousAndDiverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"unknownAndNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					}
				}, {
					"occupation": "crane-operators",
					"occupationDescription": "crane-operators",
					"overall": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 1520,
							"valueFormatted": "1520",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 36480,
							"valueFormatted": "$36,480",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseUnknown": {
						"averageRate": {
							"value": 24,
							"valueFormatted": "$24.00",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 1184,
							"valueFormatted": "1184",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 28416,
							"valueFormatted": "$28,416",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"diverseNo": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenous": {
						"averageRate": {
							"value": 24,
							"valueFormatted": "$24.00",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 336,
							"valueFormatted": "336",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 8064,
							"valueFormatted": "$8,064",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenousAndDiverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"unknownAndNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					}
				}, {
					"occupation": "supervisor",
					"occupationDescription": "supervisor",
					"overall": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 32,
							"valueFormatted": "32",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 608,
							"valueFormatted": "$608",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseUnknown": {
						"averageRate": {
							"value": 50,
							"valueFormatted": "$50.00",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalManHours": {
							"value": 32,
							"valueFormatted": "32",
							"percentage": 100,
							"percentageFormatted": "100%"
						},
						"totalWages": {
							"value": 608,
							"valueFormatted": "$608",
							"percentage": 100,
							"percentageFormatted": "100%"
						}
					},
					"diverseNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"diverseNo": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenous": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"diverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"indigenousAndDiverse": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					},
					"unknownAndNoAnswer": {
						"averageRate": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalManHours": {
							"value": 0,
							"valueFormatted": "0",
							"percentage": 0,
							"percentageFormatted": "0%"
						},
						"totalWages": {
							"value": 0,
							"valueFormatted": "$0",
							"percentage": 0,
							"percentageFormatted": "0%"
						}
					}
				}
			]
            },
            "tnb": {
                "total": {
                    "value": 167267,
                    "valueFormatted": "$167,267",
                    "percentage": 0.2,
                    "percentageFormatted": "0.2%"
                },
                "overall": {
                    "diverse": {
                        "value": 124695,
                        "valueFormatted": "$124,695",
                        "percentage": 0.15,
                        "percentageFormatted": "0.15%"
                    },
                    "indigenous": {
                        "value": 41641,
                        "valueFormatted": "$41,641",
                        "percentage": 0.05,
                        "percentageFormatted": "0.05%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 10684,
                        "valueFormatted": "$10,684",
                        "percentage": 0.01,
                        "percentageFormatted": "0.01%"
                    },
                    "indigenous": {
                        "value": 29743,
                        "valueFormatted": "$29,743",
                        "percentage": 0.03,
                        "percentageFormatted": "0.03%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 0.13,
                        "percentageFormatted": "0.13%"
                    },
                    "indigenous": {
                        "value": 11898,
                        "valueFormatted": "$11,898",
                        "percentage": 0.01,
                        "percentageFormatted": "0.01%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 6,
            "indigenousCommunityContribution": 0.75,
            "indigenousEmployment": 15,
            "diverseProjectBenefit": 5,
            "diverseEmployment": 4,
            "socialProcurement": 7,
            "diverseCommunityContribution": 4
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999
                },
                "resultMeta": {
                    "matched": 6,
                    "included": 6
                },
                "results": [{
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDirectIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 5194,
                                    "totalIndigenousEmploymentValue": 5194,
                                    "totalManHours": 233,
                                    "totalIndigenousManHours": 233
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0490",
                            "displayName": "Aishihik First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 28243,
                                "indigenousProjectRevenue": 0
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 28243,
                                    "totalProjectSpend": 28243,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 28243,
                                    "totalDirectIndigenousSpend": 28243,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 28243,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0142",
                            "displayName": "Albany First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 2
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 405463,
                                "indigenousProjectRevenue": 202186
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 424926,
                                    "totalProjectSpend": 424926,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 19463,
                                    "totalIndigenousProjectContributions": 19463,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 424926,
                                    "totalDirectIndigenousSpend": 405463,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437",
                            "displayName": "Alexis Nakota Sioux Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 3
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 317126,
                                "indigenousProjectRevenue": 317126
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 317126,
                                    "totalProjectSpend": 317126,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 317126,
                                    "totalDirectIndigenousSpend": 317126,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0296",
                            "displayName": "God's Lake First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 4
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDirectIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 3040,
                                    "totalIndigenousEmploymentValue": 3040,
                                    "totalManHours": 141,
                                    "totalIndigenousManHours": 141
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441",
                            "displayName": "Paul First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 5
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDirectIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 3664,
                                    "totalIndigenousEmploymentValue": 3664,
                                    "totalManHours": 169,
                                    "totalIndigenousManHours": 169
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0432",
                            "displayName": "Tsuut'ina Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 6
                    }
                ]
            }
        },
        "clientAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999,
                    "type": ["Vendor"]
                },
                "resultMeta": {
                    "matched": 2,
                    "included": 2
                },
                "results": [{
                        "organization": {
                            "orgName": "British Columbia Housing Management Commission",
                            "indigenousOwnership": 0,
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["Suite 1701-4555 Kingsway"],
                                    "orgCity": "Burnaby",
                                    "stateOrProvince": "British Columbia",
                                    "postalCode": "V5H 4V8"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "BCHousing",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:chandos",
                                "recordType": ["Organization", "Vendor", "Payee", "Tenant"],
                                "displayName": "Chandos Construction"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "British Columbia Housing Management Commission",
                            "sortKey": "british columbia housing management commission",
                            "transactionId": "urn:nisto-link:id:tx:2021-06-21T22-55-07.362Z-7203",
                            "identifier": "urn:nisto-link:id:vendor:bchousing-855",
                            "etag": "E6288788497659543194",
                            "status": "live",
                            "version": "3",
                            "lastUpdated": "2021-06-21T23:05:16+0000",
                            "createdTime": "2021-06-21T22:55:08+0000",
                            "previousVersion": "urn:nisto-link:id:vendor:bchousing-855:2"
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 1,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 665572,
                                    "totalProjectRevenue": 665572,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 522869,
                                    "totalProjectSpend": 522869,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 10280,
                                    "totalIndigenousProjectContributions": 10280,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 324980,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 20158,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 335260,
                                    "indigenousDetail": {
                                        "totalIndigenousCommunityOwnedSpend": 23010,
                                        "totalIndigenousRelationshipSpend": 203277,
                                        "totalIndigenousCertifiedBusinessSpend": 98693,
                                        "totalIndigenousSelfDeclaredBusinessSpend": 0
                                    },
                                    "totalDiverseSpend": 98693,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 20158,
                                        "totalLocalSpend": 33290
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": [""],
                                    "countryName": "",
                                    "orgCity": "",
                                    "postalCode": "",
                                    "stateOrProvince": ""
                                }
                            ],
                            "indigenousOwnership": 0,
                            "orgName": "Pembina Pipelines"
                        },
                        "vendor": {
                            "properties": {},
                            "impactTargets": {
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0,
                                "diverseProjectBenefit": 0,
                                "socialProcurement": 0,
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0
                            },
                            "vendorState": "provisional",
                            "vendorCode": "ppl",
                            "defaultExpenseClass": "services",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:chandos",
                                "recordType": ["Organization", "Vendor", "Payee", "Tenant"],
                                "displayName": "Chandos Construction"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Pembina Pipelines",
                            "sortKey": "pembina pipelines",
                            "identifier": "urn:nisto-link:id:vendor:ppl-1646",
                            "etag": "E4173782566485388819",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-09-08T19:57:38+0000",
                            "createdTime": "2021-09-08T19:57:38+0000"
                        },
                        "index": 2,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 85058030,
                                    "totalProjectRevenue": 85058030,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 2700781,
                                    "totalProjectSpend": 2700781,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 31078,
                                    "totalIndigenousProjectContributions": 19463,
                                    "totalDiverseProjectContributions": 10684,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 734710,
                                    "totalDiverseSpend": 193,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 1409935
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 764857,
                                    "indigenousDetail": {
                                        "totalIndigenousCommunityOwnedSpend": 519312,
                                        "totalIndigenousRelationshipSpend": 28243,
                                        "totalIndigenousCertifiedBusinessSpend": 197839,
                                        "totalIndigenousSelfDeclaredBusinessSpend": 0
                                    },
                                    "totalDiverseSpend": 198032,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 28243,
                                        "totalLocalSpend": 1495706
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 140973,
                                    "totalIndigenousEmploymentValue": 11898,
                                    "totalManHours": 6455.25,
                                    "totalIndigenousManHours": 543
                                }
                            }
                        }
                    }
                ]
            }
        },
        "expenseDiversityReportDataTables": [],
        "projectSummaryReportDataTable": {
            "title": "",
            "headers": [],
            "rows": [],
            "totalRow": {
                "children": [],
                "data": []
            },
            "total": {
                "value": 0,
                "valueFormatted": "0",
                "percentage": 0,
                "percentageFormatted": "0%"
            },
            "totalTitle": "",
            "color": "",
            "hideHeaders": false,
            "pageBreakAfter": false,
            "parentsBold": true,
            "hideIfZeroTotal": false,
            "className": "",
            "autoCalculateTotalRow": false
        },
        "payeeCommunityReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["God's Lake First Nation", "Total Community Spend", "$317,126"]
                    }, {
                        "data": ["Indigenous Community Owned Businesses", "", "$317,126"],
                        "children": [{
                                "data": ["Lloyd Sadd (Internal Only)", "", "$317,126"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Albany First Nation", "Total Community Spend", "$28,243"]
                    }, {
                        "data": ["Community Relationships", "Total Relationship Spend", "$28,243"],
                        "children": [{
                                "data": ["DRYSTAR Wall & Ceiling Ltd.", "", "$28,243"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Alexis Nakota Sioux Nation", "Total Community Spend", "$405,463"]
                    }, {
                        "data": ["Indigenous Community Owned Businesses", "", "$202,186"],
                        "children": [{
                                "data": ["AES Engineering Ltd", "", "$202,186"],
                                "children": []
                            }
                        ]
                    }, {
                        "data": ["Community Relationships", "Total Relationship Spend", "$203,277"],
                        "children": [{
                                "data": ["HDR Architecture Associates Inc", "", "$203,277"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "indigenousTypeReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Certified Indigenous Owned Business", "Total Certified Indigenous Owned Business Spend", "$285,848"],
                        "children": [{
                                "data": ["Enterprise Rent-a-car", "", "$4,071"],
                                "children": []
                            }, {
                                "data": ["3E Glass Ltd.", "", "$122,603"],
                                "children": []
                            }, {
                                "data": ["Houle Electric Ltd", "", "$159,174"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "summaryReportDataTables": [],
        "communityReportDataTables": [],
        "enabledFeatures": [features.clientMenu,
            features.communityEmploymentTables,
            features.contributionsByClientReport,
            features.employmentDiversityReports,
            features.employmentDiversityWidgetReports,
            features.employmentWidgets,
            features.indigenousEmploymentReports,
        ]
    }
    return data;
}

export function getTangibleNetBenefitsSummaryTestData(): any {
    const data = {
        "reportTitle": "Summary Indigenous Benefit Report",
        "reportType": "tangible-net-benefits-summary",
        "reportLogoUrl": "https://media-exp1.licdn.com/dms/image/C4D0BAQFQUAhJvzfTFA/company-logo_200_200/0/1576773257889?e=2159024400&v=beta&t=BpzHc40a9UKVtvv8JwzsH8RqzpkySIggk-WllBGTyWE",
        "dateRange": "All - Jul 23, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Jul 23, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": [],
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "dateFrom": "",
            "dateTo": "2021-07-23"
        },
        "organizationName": "Valard Construction Ltd",
        "title": "Summary Indigenous Benefit Report",
        "orgAggregate": {
            "totalProjectExpenses": {
                "value": 31924482,
                "valueFormatted": "$31,924,482",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 335943586,
                "valueFormatted": "$335,943,586",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 5143079,
                    "valueFormatted": "$5,143,079",
                    "percentage": 16.11,
                    "percentageFormatted": "16.11%"
                },
                "esb": {
                    "value": 3630555,
                    "valueFormatted": "$3,630,555",
                    "percentage": 11.37,
                    "percentageFormatted": "11.37%"
                },
                "esbDiverse": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esbIndigenous": {
                    "value": 3630555,
                    "valueFormatted": "$3,630,555",
                    "percentage": 11.37,
                    "percentageFormatted": "11.37%"
                },
                "localSpend": {
                    "value": 725817,
                    "valueFormatted": "$725,817",
                    "percentage": 2.27,
                    "percentageFormatted": "2.27%"
                },
                "smeSpend": {
                    "value": 786707,
                    "valueFormatted": "$786,707",
                    "percentage": 2.46,
                    "percentageFormatted": "2.46%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 3630555,
                            "valueFormatted": "$3,630,555",
                            "percentage": 11.37,
                            "percentageFormatted": "11.37%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 3630555,
                            "valueFormatted": "$3,630,555",
                            "percentage": 11.37,
                            "percentageFormatted": "11.37%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 725817,
                            "valueFormatted": "$725,817",
                            "percentage": 2.27,
                            "percentageFormatted": "2.27%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 725817,
                            "valueFormatted": "$725,817",
                            "percentage": 2.27,
                            "percentageFormatted": "2.27%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 786707,
                            "valueFormatted": "$786,707",
                            "percentage": 2.46,
                            "percentageFormatted": "2.46%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 786707,
                            "valueFormatted": "$786,707",
                            "percentage": 2.46,
                            "percentageFormatted": "2.46%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 11687,
                            "valueFormatted": "$11,687",
                            "percentage": 0.04,
                            "percentageFormatted": "0.04%"
                        },
                        "services": {
                            "value": 28264007,
                            "valueFormatted": "$28,264,007",
                            "percentage": 88.53,
                            "percentageFormatted": "88.53%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 28275694,
                            "valueFormatted": "$28,275,694",
                            "percentage": 88.57,
                            "percentageFormatted": "88.57%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 11687,
                            "valueFormatted": "$11,687",
                            "percentage": 0.04,
                            "percentageFormatted": "0.04%"
                        },
                        "services": {
                            "value": 23120928,
                            "valueFormatted": "$23,120,928",
                            "percentage": 72.42,
                            "percentageFormatted": "72.42%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 23132615,
                            "valueFormatted": "$23,132,615",
                            "percentage": 72.46,
                            "percentageFormatted": "72.46%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 10388132,
                    "valueFormatted": "$10,388,132",
                    "percentage": 3.09,
                    "percentageFormatted": "3.09%"
                },
                "esbDiverse": {
                    "value": 3383789,
                    "valueFormatted": "$3,383,789",
                    "percentage": 1.01,
                    "percentageFormatted": "1.01%"
                },
                "esbIndigenous": {
                    "value": 7004343,
                    "valueFormatted": "$7,004,343",
                    "percentage": 2.08,
                    "percentageFormatted": "2.08%"
                },
                "localSpend": {
                    "value": 726367,
                    "valueFormatted": "$726,367",
                    "percentage": 0.22,
                    "percentageFormatted": "0.22%"
                },
                "smeSpend": {
                    "value": 1086706,
                    "valueFormatted": "$1,086,706",
                    "percentage": 0.32,
                    "percentageFormatted": "0.32%"
                },
                "sociallyFocusedSpend": {
                    "value": 3373788,
                    "valueFormatted": "$3,373,788",
                    "percentage": 1,
                    "percentageFormatted": "1%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 19263,
                        "valueFormatted": "19263",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 5167513,
                        "valueFormatted": "$5,167,513",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 9185,
                        "valueFormatted": "9185",
                        "percentage": 47.68,
                        "percentageFormatted": "47.68%"
                    },
                    "wages": {
                        "value": 2224292,
                        "valueFormatted": "$2,224,292",
                        "percentage": 43.04,
                        "percentageFormatted": "43.04%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 10078,
                        "valueFormatted": "10078",
                        "percentage": 52.32,
                        "percentageFormatted": "52.32%"
                    },
                    "wages": {
                        "value": 2943221,
                        "valueFormatted": "$2,943,221",
                        "percentage": 56.96,
                        "percentageFormatted": "56.96%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 10078,
                        "valueFormatted": "10078",
                        "percentage": 52.32,
                        "percentageFormatted": "52.32%"
                    },
                    "wages": {
                        "value": 2943221,
                        "valueFormatted": "$2,943,221",
                        "percentage": 56.96,
                        "percentageFormatted": "56.96%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 9185,
                        "valueFormatted": "9185",
                        "percentage": 47.68,
                        "percentageFormatted": "47.68%"
                    },
                    "wages": {
                        "value": 2224292,
                        "valueFormatted": "$2,224,292",
                        "percentage": 43.04,
                        "percentageFormatted": "43.04%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 6317009,
                    "valueFormatted": "$6,317,009",
                    "percentage": 1.88,
                    "percentageFormatted": "1.88%"
                },
                "overall": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 6317009,
                        "valueFormatted": "$6,317,009",
                        "percentage": 1.88,
                        "percentageFormatted": "1.88%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 3111833,
                        "valueFormatted": "$3,111,833",
                        "percentage": 0.93,
                        "percentageFormatted": "0.93%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 261955,
                        "valueFormatted": "$261,955",
                        "percentage": 0.08,
                        "percentageFormatted": "0.08%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 2943221,
                        "valueFormatted": "$2,943,221",
                        "percentage": 0.88,
                        "percentageFormatted": "0.88%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 4,
            "indigenousCommunityContribution": 0.75,
            "indigenousEmployment": 2.5,
            "diverseProjectBenefit": 3,
            "diverseEmployment": 0,
            "socialProcurement": 5,
            "diverseCommunityContribution": 0
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 20
                },
                "resultMeta": {
                    "matched": 35,
                    "included": 35
                },
                "results": [{
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 51750,
                                    "totalProjectSpend": 26750,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 26750,
                                    "totalIndigenousProjectContributions": 26750,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 25000,
                                    "totalIndigenousCommunityContributions": 25000,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 51750,
                                    "totalDiverseSpend": 51750,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 51750,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 1900,
                                    "totalIndigenousEmploymentValue": 1900,
                                    "totalManHours": 2,
                                    "totalIndigenousManHours": 2
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0018",
                            "displayName": "Acadia First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 87655,
                                    "totalProjectSpend": 71000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 6000,
                                    "totalIndigenousProjectContributions": 6000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 16655,
                                    "totalIndigenousCommunityContributions": 16655,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 22655,
                                    "totalDiverseSpend": 22655,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 22655,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0758",
                            "displayName": "Acho Dene Koe First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 2
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 825544,
                                "indigenousProjectRevenue": 825544
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0684",
                            "displayName": "Adams Lake First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 3
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 222000,
                                "indigenousProjectRevenue": 222000
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0659",
                            "displayName": "Ahousaht First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 4
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 100456,
                                    "totalProjectSpend": 100456,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 75000,
                                    "totalIndigenousProjectContributions": 75000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 75000,
                                    "totalDiverseSpend": 75000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 75000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0406",
                            "displayName": "Ahtahkakoop First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 5
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 162500,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0755",
                            "displayName": "Aklavik First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 6
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 1200,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:inuit:in-991000",
                            "displayName": "Aklavik Inuit",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 7
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 35000,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0142",
                            "displayName": "Albany First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 8
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 449999,
                                "indigenousProjectRevenue": 150000
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0160",
                            "displayName": "Alderville First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 9
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 290000,
                                "indigenousProjectRevenue": 0
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 436581,
                                    "totalProjectSpend": 436581,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 146581,
                                    "totalIndigenousProjectContributions": 146581,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 146581,
                                    "totalDiverseSpend": 146581,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 146581,
                                        "totalSmeSpend": 125000,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 3360,
                                    "totalIndigenousEmploymentValue": 3360,
                                    "totalManHours": 160,
                                    "totalIndigenousManHours": 160
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0438",
                            "displayName": "Alexander First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 10
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 471898,
                                "indigenousProjectRevenue": 281000
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437",
                            "displayName": "Alexis Nakota Sioux Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 11
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 125000,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0463",
                            "displayName": "Athabasca Chipewyan First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 12
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 125000,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0435",
                            "displayName": "Blood Tribe",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 13
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 88454,
                                "indigenousProjectRevenue": 23000
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:metis:me-98267",
                            "displayName": "Cochin Métis",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 14
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 75000,
                                    "totalProjectSpend": 75000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 75000,
                                    "totalIndigenousProjectContributions": 75000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 75000,
                                    "totalDiverseSpend": 75000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 75000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0548",
                            "displayName": "Doig River First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 15
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 75000,
                                    "totalProjectSpend": 75000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 75000,
                                    "totalIndigenousProjectContributions": 75000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 75000,
                                    "totalDiverseSpend": 75000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 75000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:metis:me-98225",
                            "displayName": "Ducharme Lake Métis",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 16
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 338806,
                                    "totalProjectSpend": 338806,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 167306,
                                    "totalIndigenousProjectContributions": 167306,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 167306,
                                    "totalDiverseSpend": 167306,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 167306,
                                        "totalSmeSpend": 125000,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0440",
                            "displayName": "Enoch Cree Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 17
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 233820,
                                "indigenousProjectRevenue": 0
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 676652,
                                    "totalProjectSpend": 676652,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 442832,
                                    "totalIndigenousProjectContributions": 442832,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 442832,
                                    "totalDiverseSpend": 442832,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 442832,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 97180,
                                    "totalIndigenousEmploymentValue": 97180,
                                    "totalManHours": 369,
                                    "totalIndigenousManHours": 369
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0467",
                            "displayName": "Fort McKay First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 18
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 66400,
                                    "totalProjectSpend": 66400,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 66400,
                                    "totalIndigenousProjectContributions": 66400,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 66400,
                                    "totalDiverseSpend": 66400,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 66400,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0468",
                            "displayName": "Fort McMurray #468 First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 19
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 56000,
                                    "totalProjectSpend": 56000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 56000,
                                    "totalIndigenousProjectContributions": 56000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 56000,
                                    "totalDiverseSpend": 56000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 56000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:metis:me-98334",
                            "displayName": "Gift Lake Métis Settlement",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 20
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 274500,
                                "indigenousProjectRevenue": 24500
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 519505,
                                    "totalProjectSpend": 517005,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 242505,
                                    "totalIndigenousProjectContributions": 242505,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 2500,
                                    "totalIndigenousCommunityContributions": 2500,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 269505,
                                    "totalDiverseSpend": 245005,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 245005,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 343829,
                                    "totalIndigenousEmploymentValue": 343829,
                                    "totalManHours": 1505,
                                    "totalIndigenousManHours": 1505
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0676",
                            "displayName": "Haisla Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 21
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 25000,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0546",
                            "displayName": "Halfway River First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 22
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 155000,
                                "indigenousProjectRevenue": 0
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 157000,
                                    "totalProjectSpend": 156250,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 1250,
                                    "totalIndigenousProjectContributions": 1250,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 750,
                                    "totalIndigenousCommunityContributions": 750,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 2000,
                                    "totalDiverseSpend": 2000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 2000,
                                        "totalSmeSpend": 110000,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0461",
                            "displayName": "Mikisew Cree First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 23
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 350000,
                                "indigenousProjectRevenue": 350000
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0200",
                            "displayName": "Mississauga First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 24
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 56987,
                                    "totalProjectSpend": 56987,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 56987,
                                    "totalIndigenousProjectContributions": 56987,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 56987,
                                    "totalDiverseSpend": 56987,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 56987,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:metis:me-98005",
                            "displayName": "Métis Local # 1935 Fort McMurray",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 25
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 563,
                                    "totalProjectSpend": 563,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 563,
                                    "totalIndigenousProjectContributions": 563,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 563,
                                    "totalDiverseSpend": 563,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 563,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 59100,
                                    "totalIndigenousEmploymentValue": 59100,
                                    "totalManHours": 126,
                                    "totalIndigenousManHours": 126
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:metis:me-98359",
                            "displayName": "Métis Nation of Alberta",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 26
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 102525,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:metis:me-98191",
                            "displayName": "Métis of Dumont",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 27
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 391453,
                                "indigenousProjectRevenue": 6801
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 734596,
                                    "totalProjectSpend": 734596,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 343143,
                                    "totalIndigenousProjectContributions": 343143,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 349944,
                                    "totalDiverseSpend": 343143,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 343143,
                                        "totalSmeSpend": 258987,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 421943,
                                    "totalIndigenousEmploymentValue": 421943,
                                    "totalManHours": 1671,
                                    "totalIndigenousManHours": 1671
                                }
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441",
                            "displayName": "Paul First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 28
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 65000,
                                    "totalProjectSpend": 65000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 65000,
                                    "totalIndigenousProjectContributions": 65000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 65000,
                                    "totalDiverseSpend": 65000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 65000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 2192,
                                    "totalIndigenousEmploymentValue": 2192,
                                    "totalManHours": 8,
                                    "totalIndigenousManHours": 8
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0462",
                            "displayName": "Saddle Lake Cree Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 29
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 25011,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0555",
                            "displayName": "Squamish First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 30
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 65878,
                                    "totalProjectSpend": 65878,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 65878,
                                    "totalIndigenousProjectContributions": 65878,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 65878,
                                    "totalDiverseSpend": 65878,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 65878,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 65700,
                                    "totalIndigenousEmploymentValue": 65700,
                                    "totalManHours": 146,
                                    "totalIndigenousManHours": 146
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0456",
                            "displayName": "Sucker Creek First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 31
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 65000,
                                    "totalProjectSpend": 65000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 65000,
                                    "totalIndigenousProjectContributions": 65000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 65000,
                                    "totalDiverseSpend": 65000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 65000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0434",
                            "displayName": "Sunchild First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 32
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 83000,
                                    "totalProjectSpend": 83000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 83000,
                                    "totalIndigenousProjectContributions": 83000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 83000,
                                    "totalDiverseSpend": 83000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 83000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 58895,
                                    "totalIndigenousEmploymentValue": 58895,
                                    "totalManHours": 250,
                                    "totalIndigenousManHours": 250
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0657",
                            "displayName": "T'Sou-ke First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 33
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 132500,
                                    "totalProjectSpend": 132500,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 132500,
                                    "totalIndigenousProjectContributions": 132500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 132500,
                                    "totalDiverseSpend": 132500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 132500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 5040,
                                    "totalIndigenousEmploymentValue": 5040,
                                    "totalManHours": 240,
                                    "totalIndigenousManHours": 240
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0432",
                            "displayName": "Tsuut'ina Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 34
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 175000,
                                "indigenousProjectRevenue": 50000
                            },
                            "expense": {
                                "indigenousSpend": 0,
                                "projectSpend": 0
                            },
                            "employment": {
                                "employmentValue": 0,
                                "indigenousEmploymentValue": 0,
                                "manHours": 0,
                                "indigenousManHours": 0
                            },
                            "contributions": {
                                "totalContributions": 0,
                                "totalCommunityContributions": 0,
                                "totalProjectContributions": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0725",
                            "displayName": "Wet'suwet'en First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 35
                    }
                ]
            }
        },
        "clientAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999,
                    "type": ["Vendor"]
                },
                "resultMeta": {
                    "matched": 37,
                    "included": 37
                },
                "results": [{
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:arc",
                            "createdTime": "2021-05-08T00:30:29+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-06-21T17:05:18+0000",
                            "status": "live",
                            "displayName": "ARC Financial",
                            "sortKey": "arc financial",
                            "etag": "E1784503666795894802",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "4",
                            "previousVersion": "urn:nisto-link:id:vendor:arc:3"
                        },
                        "organization": {
                            "orgName": "ARC Financial",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": [""],
                                    "countryName": "",
                                    "orgCity": "",
                                    "postalCode": "",
                                    "stateOrProvince": ""
                                }
                            ],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "arc",
                            "properties": {},
                            "defaultExpenseClass": "equipment",
                            "impactTargets": {},
                            "vendorState": "active"
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 1,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 250000,
                                    "totalProjectRevenue": 250000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 25897,
                                    "totalProjectSpend": 25897,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 25897,
                                    "totalIndigenousProjectContributions": 25897,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 25897,
                                    "totalDiverseSpend": 25897,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 25897,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 24415,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 107
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:at",
                            "createdTime": "2021-05-08T00:30:29+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "previousVersion": "urn:nisto-link:id:vendor:at:22",
                            "lastUpdated": "2021-07-11T22:03:27+0000",
                            "status": "live",
                            "displayName": "Alberta Transportation",
                            "sortKey": "alberta transportation",
                            "etag": "E3959325330370348568",
                            "updater": "urn:nisto-link:id:person:jonathan-steele",
                            "version": "23"
                        },
                        "organization": {
                            "orgName": "Alberta Transportation",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["111 The Road"],
                                    "countryName": "Canada",
                                    "orgCity": "City",
                                    "postalCode": "AB1 2CF",
                                    "stateOrProvince": "Prov"
                                }
                            ],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "at",
                            "properties": {},
                            "defaultExpenseClass": "contribution",
                            "impactTargets": {},
                            "vendorState": "active",
                            "hasProvisionalCreator": null
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 2,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 4300,
                                    "totalProjectRevenue": 4300,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 10000,
                                    "totalProjectSpend": 10000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 10000,
                                    "totalIndigenousProjectContributions": 10000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 10000,
                                    "totalDiverseSpend": 10000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 10000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:amc",
                            "createdTime": "2021-05-08T00:30:29+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "previousVersion": "urn:nisto-link:id:vendor:amc:9",
                            "lastUpdated": "2021-07-05T13:38:12+0000",
                            "status": "live",
                            "displayName": "Australian Mining Co.",
                            "sortKey": "australian mining co.",
                            "etag": "E2640805794683169767",
                            "updater": "urn:nisto-link:id:person:jonathan-steele",
                            "version": "10"
                        },
                        "organization": {
                            "orgName": "Australian Mining Co.",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": [""],
                                    "countryName": "",
                                    "orgCity": "",
                                    "postalCode": "",
                                    "stateOrProvince": ""
                                }
                            ],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "amc",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "properties": {},
                            "impactTargets": {},
                            "proxyPayeeFor": {
                                "identifier": "urn:nisto-link:id:first-nation:fn-0684",
                                "recordType": ["Organization", "IndigenousCommunity"],
                                "displayName": "Adams Lake First Nation"
                            },
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional"
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": ["indigenousOwned"]
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 3,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 7372398,
                                    "totalProjectRevenue": 7372398,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 240375,
                                    "totalProjectSpend": 240375,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 240375,
                                    "totalIndigenousProjectContributions": 240375,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 240375,
                                    "totalDiverseSpend": 240375,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 240375,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 185262,
                                    "totalIndigenousEmploymentValue": 118397,
                                    "totalManHours": 473,
                                    "totalIndigenousManHours": 319
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:bchous",
                            "createdTime": "2021-05-08T00:30:29+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:29+0000",
                            "status": "live",
                            "displayName": "BC Housing",
                            "sortKey": "bc housing",
                            "etag": "E8587962314994592672",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "BC Housing",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "bchous",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 4,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 68490549,
                                    "totalProjectRevenue": 68490549,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 20920481,
                                    "totalProjectSpend": 20920481,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 432804,
                                    "totalIndigenousProjectContributions": 157804,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 321000,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 725817
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 478804,
                                    "totalDiverseSpend": 167805,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 157804,
                                        "totalSmeSpend": 299999,
                                        "totalLocalSpend": 726367
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 389734,
                                    "totalIndigenousEmploymentValue": 254342,
                                    "totalManHours": 3352,
                                    "totalIndigenousManHours": 1432
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:bei",
                            "createdTime": "2021-05-08T00:30:29+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "previousVersion": "/records/urn:nisto-link:id:vendor:bei:3.xml",
                            "lastUpdated": "2021-05-08T00:30:29+0000",
                            "status": "live",
                            "displayName": "Backwood Energy Inc.",
                            "sortKey": "backwood energy inc.",
                            "etag": "E4069105762878646211",
                            "updater": "urn:nisto-link:id:person:jonathan-steele",
                            "version": "5"
                        },
                        "organization": {
                            "orgName": "Backwood Energy Inc.",
                            "indigenousOwnership": 51,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "bei",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": ["indigenousOwned"]
                            },
                            "impactEsg": {
                                "values": ["indigenousOwned"]
                            }
                        },
                        "index": 5,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 4259453,
                                    "totalProjectRevenue": 4259453,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 12457,
                                    "totalProjectSpend": 12457,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 12457,
                                    "totalIndigenousProjectContributions": 12457,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 12457,
                                    "totalDiverseSpend": 12457,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 12457,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 138444,
                                    "totalIndigenousEmploymentValue": 70052,
                                    "totalManHours": 432,
                                    "totalIndigenousManHours": 237
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:cnooc",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:28+0000",
                            "status": "live",
                            "displayName": "CNOOC",
                            "sortKey": "cnooc",
                            "etag": "E3225679867912013392",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "CNOOC",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "cnooc",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 6,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 250000,
                                    "totalProjectRevenue": 250000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 15500,
                                    "totalProjectSpend": 15500,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 15500,
                                    "totalIndigenousProjectContributions": 15500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 15500,
                                    "totalDiverseSpend": 15500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 15500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 150700,
                                    "totalIndigenousEmploymentValue": 123550,
                                    "totalManHours": 325,
                                    "totalIndigenousManHours": 267
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:ccab",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-18T12:20:42+0000",
                            "status": "live",
                            "displayName": "Canadian Council for Aboriginal Business",
                            "sortKey": "canadian council for aboriginal business",
                            "etag": "E2062473651261044552",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "3",
                            "previousVersion": "urn:nisto-link:id:vendor:ccab:2"
                        },
                        "organization": {
                            "orgName": "Canadian Council for Aboriginal Business",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": [""],
                                    "countryName": "",
                                    "orgCity": "",
                                    "postalCode": "",
                                    "stateOrProvince": ""
                                }
                            ],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "ccab",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": ["indigenousOwned"]
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 7,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 6500000,
                                    "totalProjectRevenue": 6500000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 27965,
                                    "totalProjectSpend": 27965,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 27965,
                                    "totalIndigenousProjectContributions": 27965,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 27965,
                                    "totalDiverseSpend": 27965,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 27965,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 53562,
                                    "totalIndigenousEmploymentValue": 43797,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 163
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:cnrl",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "transactionId": "urn:nisto-link:id:tx:2020-06-22T18-33-46.392Z-4633",
                            "lastUpdated": "2021-05-08T00:30:28+0000",
                            "status": "live",
                            "displayName": "Canadian Natural Resources Ltd.",
                            "sortKey": "canadian natural resources ltd.",
                            "etag": "E8305835249979061531",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Canadian Natural Resources Ltd.",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "cnrl",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 8,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 21637893,
                                    "totalProjectRevenue": 21637893,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 121809,
                                    "totalProjectSpend": 121809,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 121809,
                                    "totalIndigenousProjectContributions": 121809,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 121809,
                                    "totalDiverseSpend": 121809,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 121809,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 100374,
                                    "totalIndigenousEmploymentValue": 62282,
                                    "totalManHours": 415,
                                    "totalIndigenousManHours": 261
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:cah",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:28+0000",
                            "status": "live",
                            "displayName": "Carol Anne Hilton",
                            "sortKey": "carol anne hilton",
                            "etag": "E285903791718649015",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Carol Anne Hilton",
                            "indigenousOwnership": 51,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "cah",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": ["indigenousOwned"]
                            },
                            "impactEsg": {
                                "values": ["indigenousOwned"]
                            }
                        },
                        "index": 9,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 2500000,
                                    "totalProjectRevenue": 2500000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 25706,
                                    "totalProjectSpend": 25706,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 25706,
                                    "totalIndigenousProjectContributions": 25706,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 25706,
                                    "totalDiverseSpend": 25706,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 25706,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 31251,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 137
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:chevron1",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "transactionId": "urn:nisto-link:id:tx:2020-11-13T17-10-25.541Z-2604",
                            "lastUpdated": "2021-05-20T17:24:08+0000",
                            "status": "live",
                            "displayName": "Chevron",
                            "sortKey": "chevron",
                            "etag": "E6277340812070822974",
                            "version": "3",
                            "previousVersion": "urn:nisto-link:id:vendor:chevron1:2"
                        },
                        "organization": {
                            "description": "N/A",
                            "orgName": "Chevron",
                            "contactPerson": "Alec Carton",
                            "contactEmail": "aleccarton@chevron.com",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": [""],
                                    "countryName": "",
                                    "orgCity": "",
                                    "postalCode": "",
                                    "stateOrProvince": ""
                                }
                            ],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "Chevron1",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "impactTargets": {},
                            "vendorState": "provisional"
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 10,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 28698412,
                                    "totalProjectRevenue": 28698412,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 339821,
                                    "totalProjectSpend": 339821,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 314365,
                                    "totalIndigenousProjectContributions": 314365,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 314365,
                                    "totalDiverseSpend": 314365,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 314365,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 376460,
                                    "totalIndigenousEmploymentValue": 257795,
                                    "totalManHours": 1307,
                                    "totalIndigenousManHours": 866
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:citymh",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:28+0000",
                            "status": "live",
                            "displayName": "City of Medicine Hat",
                            "sortKey": "city of medicine hat",
                            "etag": "E4297356590872905374",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "City of Medicine Hat",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "citymh",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 11,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 5000000,
                                    "totalProjectRevenue": 5000000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 65250,
                                    "totalProjectSpend": 65250,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 65250,
                                    "totalIndigenousProjectContributions": 65250,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 65250,
                                    "totalDiverseSpend": 65250,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 65250,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 26081,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 113
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:com",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-11T12:10:23+0000",
                            "status": "live",
                            "displayName": "City of Montreal",
                            "sortKey": "city of montreal",
                            "etag": "E3039208236076070278",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "4",
                            "previousVersion": "urn:nisto-link:id:vendor:com:3"
                        },
                        "organization": {
                            "orgName": "City of Montreal",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": [""],
                                    "countryName": "",
                                    "orgCity": "",
                                    "postalCode": "",
                                    "stateOrProvince": ""
                                }
                            ],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "com",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": ["sme"]
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 12,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 195667,
                                    "totalProjectRevenue": 195667,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 3000,
                                    "totalProjectSpend": 3000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 3000,
                                    "totalIndigenousProjectContributions": 3000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 3000,
                                    "totalDiverseSpend": 3000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 3000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 192812,
                                    "totalIndigenousEmploymentValue": 148301,
                                    "totalManHours": 515,
                                    "totalIndigenousManHours": 390
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:clp",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-21T16:50:00+0000",
                            "status": "live",
                            "displayName": "Craigs Line Painting",
                            "sortKey": "craigs line painting",
                            "etag": "E2564710898378700211",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "31",
                            "previousVersion": "urn:nisto-link:id:vendor:clp:30"
                        },
                        "organization": {
                            "orgName": "Craigs Line Painting",
                            "indigenousOwnership": 51,
                            "imageUrls": {},
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": [""],
                                    "countryName": "",
                                    "orgCity": "",
                                    "postalCode": "",
                                    "stateOrProvince": ""
                                }
                            ],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "clp",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "impactTargets": {},
                            "vendorState": "provisional"
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": ["sme"]
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 13,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 90000,
                                    "totalProjectRevenue": 90000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 55000,
                                    "totalProjectSpend": 55000,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 55000,
                                    "totalIndigenousProjectContributions": 55000,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 55000,
                                    "totalDiverseSpend": 55000,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 55000,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 5600,
                                    "totalIndigenousEmploymentValue": 5600,
                                    "totalManHours": 4,
                                    "totalIndigenousManHours": 4
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:deloitte",
                            "createdTime": "2021-05-08T00:30:28+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:28+0000",
                            "status": "live",
                            "displayName": "Deloitte",
                            "sortKey": "deloitte",
                            "etag": "E1604055887650403094",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Deloitte",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "Deloitte",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 14,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 2565458,
                                    "totalProjectRevenue": 2565458,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 65250,
                                    "totalProjectSpend": 65250,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 65250,
                                    "totalIndigenousProjectContributions": 65250,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 65250,
                                    "totalDiverseSpend": 65250,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 65250,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 29276,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 128
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:dpc",
                            "createdTime": "2021-05-08T00:30:26+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:26+0000",
                            "status": "live",
                            "displayName": "Denver Paving Co",
                            "sortKey": "denver paving co",
                            "etag": "E4997026148350967362",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Denver Paving Co",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "dpc",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 15,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 300000,
                                    "totalProjectRevenue": 300000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 8500,
                                    "totalProjectSpend": 8500,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 8500,
                                    "totalIndigenousProjectContributions": 8500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 8500,
                                    "totalDiverseSpend": 8500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 8500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 204262,
                                    "totalIndigenousEmploymentValue": 166438,
                                    "totalManHours": 523,
                                    "totalIndigenousManHours": 427
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:exp",
                            "createdTime": "2021-05-08T00:30:26+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:26+0000",
                            "status": "live",
                            "displayName": "Explorer Software",
                            "sortKey": "explorer software",
                            "etag": "E2247523903631837847",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Explorer Software",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "exp",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 16,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 150000,
                                    "totalProjectRevenue": 150000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 12750,
                                    "totalProjectSpend": 12750,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 12750,
                                    "totalIndigenousProjectContributions": 12750,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 12750,
                                    "totalDiverseSpend": 12750,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 12750,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 53562,
                                    "totalIndigenousEmploymentValue": 28465,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 107
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:gc",
                            "createdTime": "2021-05-08T00:30:26+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:26+0000",
                            "status": "live",
                            "displayName": "Graham Construction",
                            "sortKey": "graham construction",
                            "etag": "E854131429629713791",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Graham Construction",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "gc",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 17,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 1500000,
                                    "totalProjectRevenue": 1500000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 75500,
                                    "totalProjectSpend": 75500,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 75500,
                                    "totalIndigenousProjectContributions": 75500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 75500,
                                    "totalDiverseSpend": 75500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 75500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 73812,
                                    "totalIndigenousEmploymentValue": 40265,
                                    "totalManHours": 239,
                                    "totalIndigenousManHours": 131
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:hn",
                            "createdTime": "2021-05-08T00:30:26+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:26+0000",
                            "status": "live",
                            "displayName": "Haisla Nation",
                            "sortKey": "haisla nation",
                            "etag": "E6157658985800425375",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Haisla Nation",
                            "indigenousOwnership": 51,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "hn",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": ["indigenousOwned"]
                            },
                            "impactEsg": {
                                "values": ["indigenousOwned"]
                            }
                        },
                        "index": 18,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 450000,
                                    "totalProjectRevenue": 450000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 112300,
                                    "totalProjectSpend": 112300,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 112300,
                                    "totalIndigenousProjectContributions": 112300,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 112300,
                                    "totalDiverseSpend": 112300,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 112300,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 30716,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 135
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:he",
                            "createdTime": "2021-05-08T00:30:26+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "transactionId": "urn:nisto-link:id:tx:2020-06-22T18-33-46.392Z-4633",
                            "lastUpdated": "2021-05-08T00:30:26+0000",
                            "status": "live",
                            "displayName": "Husky Energy",
                            "sortKey": "husky energy",
                            "etag": "E6788785461662294839",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Husky Energy",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "he",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 19,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 53865278,
                                    "totalProjectRevenue": 53865278,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 244989,
                                    "totalProjectSpend": 244989,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 244989,
                                    "totalIndigenousProjectContributions": 244989,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 244989,
                                    "totalDiverseSpend": 244989,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 244989,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 251724,
                                    "totalIndigenousEmploymentValue": 175562,
                                    "totalManHours": 741,
                                    "totalIndigenousManHours": 493
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:hydroone",
                            "createdTime": "2021-05-08T00:30:26+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:26+0000",
                            "status": "live",
                            "displayName": "Hydroone",
                            "sortKey": "hydroone",
                            "etag": "E141253514458831246",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Hydroone",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "hydroone",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 20,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 2500000,
                                    "totalProjectRevenue": 2500000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 75500,
                                    "totalProjectSpend": 75500,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 75500,
                                    "totalIndigenousProjectContributions": 75500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 75500,
                                    "totalDiverseSpend": 75500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 75500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 26081,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 113
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:iol",
                            "createdTime": "2021-05-08T00:30:26+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:26+0000",
                            "status": "live",
                            "displayName": "Imperial Oil",
                            "sortKey": "imperial oil",
                            "etag": "E516562822663703256",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Imperial Oil",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "iol",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 21,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 2750000,
                                    "totalProjectRevenue": 2750000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 130500,
                                    "totalProjectSpend": 130500,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 130500,
                                    "totalIndigenousProjectContributions": 130500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 130500,
                                    "totalDiverseSpend": 130500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 130500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 91824,
                                    "totalIndigenousEmploymentValue": 52162,
                                    "totalManHours": 396,
                                    "totalIndigenousManHours": 226
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Jennifer Painting Co.",
                            "indigenousOwnership": 0,
                            "contactPerson": "Jennifer Digby",
                            "contactEmail": "j.digby@paintingco.com",
                            "contactPhone": "403-645-7980",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["23 Mountain Road"],
                                    "orgCity": "Calgary",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T2N 0P3"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "jpc34",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Jennifer Painting Co.",
                            "sortKey": "jennifer painting co.",
                            "transactionId": "urn:nisto-link:id:tx:2021-05-21T16-18-47.994Z-5480",
                            "identifier": "urn:nisto-link:id:vendor:jpc34-4796",
                            "etag": "E7036402318713025344",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-05-21T16:18:49+0000",
                            "createdTime": "2021-05-21T16:18:49+0000"
                        },
                        "index": 22,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 45000,
                                    "totalProjectRevenue": 45000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 2584,
                                    "totalIndigenousEmploymentValue": 760,
                                    "totalManHours": 136,
                                    "totalIndigenousManHours": 40
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:kccl",
                            "createdTime": "2021-05-08T00:30:23+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "transactionId": "urn:nisto-link:id:tx:2020-06-22T18-33-46.392Z-4633",
                            "lastUpdated": "2021-05-08T00:30:23+0000",
                            "status": "live",
                            "displayName": "Kiewit Construction Canada Ltd.",
                            "sortKey": "kiewit construction canada ltd.",
                            "etag": "E9205888556581974093",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Kiewit Construction Canada Ltd.",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "kccl",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 23,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 5499866,
                                    "totalProjectRevenue": 5499866,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 7566,
                                    "totalProjectSpend": 7566,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 7566,
                                    "totalIndigenousProjectContributions": 7566,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 7566,
                                    "totalDiverseSpend": 7566,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 7566,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 24550,
                                    "totalIndigenousEmploymentValue": 13950,
                                    "totalManHours": 51,
                                    "totalIndigenousManHours": 29
                                }
                            }
                        }
                    }, {
                        "organization": {
                            "orgName": "Kodiak Group of Companies",
                            "indigenousOwnership": 0,
                            "contactPerson": "Sky Bearchief",
                            "contactEmail": "sky.bearchief@kodiak.com",
                            "contactPhone": "403-567-2341",
                            "addresses": [{
                                    "addressCode": "primary",
                                    "addressName": "Primary",
                                    "addrLines": ["3546 Valleyview Road"],
                                    "orgCity": "Valleyview",
                                    "stateOrProvince": "Alberta",
                                    "postalCode": "T0H 3N0"
                                }
                            ]
                        },
                        "vendor": {
                            "vendorCode": "kgc99",
                            "impactTargets": {
                                "indigenousProjectBenefit": 0,
                                "indigenousCommunityContribution": 0,
                                "indigenousEmployment": 0,
                                "socialProcurement": 0,
                                "diverseProjectBenefit": 0,
                                "diverseCommunityContribution": 0,
                                "diverseEmployment": 0
                            },
                            "properties": {},
                            "defaultExpenseClass": "uncategorized",
                            "vendorState": "provisional",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            }
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": []
                            },
                            "impactESG": {
                                "values": []
                            },
                            "impactStatus": {
                                "values": []
                            },
                            "impactSocial": {
                                "values": []
                            },
                            "socialFocusedOrgs": {
                                "values": []
                            }
                        },
                        "common": {
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "displayName": "Kodiak Group of Companies",
                            "sortKey": "kodiak group of companies",
                            "transactionId": "urn:nisto-link:id:tx:2021-05-21T16-18-47.994Z-5480",
                            "identifier": "urn:nisto-link:id:vendor:kgc99-2219",
                            "etag": "E9034391074813827673",
                            "status": "live",
                            "version": "1",
                            "lastUpdated": "2021-05-21T16:18:51+0000",
                            "createdTime": "2021-05-21T16:18:51+0000"
                        },
                        "index": 24,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 1825000,
                                    "totalProjectRevenue": 1825000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 4104,
                                    "totalIndigenousEmploymentValue": 2432,
                                    "totalManHours": 216,
                                    "totalIndigenousManHours": 128
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:lngcan",
                            "createdTime": "2021-05-08T00:30:22+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:22+0000",
                            "status": "live",
                            "displayName": "LNG Canada",
                            "sortKey": "lng canada",
                            "etag": "E8233917303338523536",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "LNG Canada",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "lngcan",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 25,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 650000,
                                    "totalProjectRevenue": 650000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 37045,
                                    "totalProjectSpend": 37045,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 37045,
                                    "totalIndigenousProjectContributions": 37045,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 37045,
                                    "totalDiverseSpend": 37045,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 37045,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 29393,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 129
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:pc2020",
                            "createdTime": "2021-05-08T00:30:18+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:18+0000",
                            "status": "live",
                            "displayName": "Parkland County",
                            "sortKey": "parkland county",
                            "etag": "E383161807143466414",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Parkland County",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "pc2020",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 26,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 567876,
                                    "totalProjectRevenue": 567876,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 27500,
                                    "totalProjectSpend": 27500,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 27500,
                                    "totalIndigenousProjectContributions": 27500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 27500,
                                    "totalDiverseSpend": 27500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 27500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 53562,
                                    "totalIndigenousEmploymentValue": 42888,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 160
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:ppl",
                            "createdTime": "2021-05-08T00:30:18+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "transactionId": "urn:nisto-link:id:tx:2020-06-22T18-33-46.392Z-4633",
                            "lastUpdated": "2021-05-08T00:30:18+0000",
                            "status": "live",
                            "displayName": "Pembina Pipeline Ltd.",
                            "sortKey": "pembina pipeline ltd.",
                            "etag": "E3471839992390391440",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Pembina Pipeline Ltd.",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "ppl",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 27,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 6254885,
                                    "totalProjectRevenue": 6254885,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 70575,
                                    "totalProjectSpend": 70575,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 70575,
                                    "totalIndigenousProjectContributions": 70575,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 70575,
                                    "totalDiverseSpend": 70575,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 70575,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 41550,
                                    "totalIndigenousEmploymentValue": 24050,
                                    "totalManHours": 88,
                                    "totalIndigenousManHours": 51
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:rt",
                            "createdTime": "2021-05-08T00:30:17+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:17+0000",
                            "status": "live",
                            "displayName": "Rio Tinto",
                            "sortKey": "rio tinto",
                            "etag": "E804480507353065241",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Rio Tinto",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "rt",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 28,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 25000000,
                                    "totalProjectRevenue": 25000000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 29460,
                                    "totalProjectSpend": 29460,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 29460,
                                    "totalIndigenousProjectContributions": 29460,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 29460,
                                    "totalDiverseSpend": 29460,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 29460,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 31251,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 137
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:share",
                            "createdTime": "2021-05-08T00:30:16+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:16+0000",
                            "status": "live",
                            "displayName": "SHARE",
                            "sortKey": "share",
                            "etag": "E5577742660197290056",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "SHARE",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "share",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 29,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 2587984,
                                    "totalProjectRevenue": 2587984,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 45250,
                                    "totalProjectSpend": 45250,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 45250,
                                    "totalIndigenousProjectContributions": 45250,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 45250,
                                    "totalDiverseSpend": 45250,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 45250,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 31251,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 137
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:sge",
                            "createdTime": "2021-05-08T00:30:16+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:16+0000",
                            "status": "live",
                            "displayName": "Seven Generation Energy",
                            "sortKey": "seven generation energy",
                            "etag": "E2929987977600595404",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Seven Generation Energy",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "sge",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 30,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 2589788,
                                    "totalProjectRevenue": 2589788,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 26148,
                                    "totalProjectSpend": 26148,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 26148,
                                    "totalIndigenousProjectContributions": 26148,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 26148,
                                    "totalDiverseSpend": 26148,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 26148,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 45912,
                                    "totalIndigenousEmploymentValue": 31251,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 137
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:sei",
                            "createdTime": "2021-05-08T00:30:15+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "transactionId": "urn:nisto-link:id:tx:2020-06-22T18-33-46.392Z-4633",
                            "lastUpdated": "2021-05-08T00:30:15+0000",
                            "status": "live",
                            "displayName": "Suncor Energy Inc.",
                            "sortKey": "suncor energy inc.",
                            "etag": "E8374670143666597626",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Suncor Energy Inc.",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "sei",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 31,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 25129670,
                                    "totalProjectRevenue": 25129670,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 1261470,
                                    "totalProjectSpend": 1261470,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 531926,
                                    "totalIndigenousProjectContributions": 531926,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 724544,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 5000,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 1256470,
                                    "totalDiverseSpend": 531926,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 531926,
                                        "totalSmeSpend": 5000,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 1011973,
                                    "totalIndigenousEmploymentValue": 542715,
                                    "totalManHours": 3173,
                                    "totalIndigenousManHours": 1572
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:tfn",
                            "createdTime": "2021-05-08T00:30:15+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:15+0000",
                            "status": "live",
                            "displayName": "T'suu Tina First Nation",
                            "sortKey": "t'suu tina first nation",
                            "etag": "E7124650528894698250",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "T'suu Tina First Nation",
                            "indigenousOwnership": 51,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "tfn",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {
                            "indigenousImpact": {
                                "values": ["indigenousOwned"]
                            },
                            "impactEsg": {
                                "values": ["indigenousOwned"]
                            }
                        },
                        "index": 32,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 5000000,
                                    "totalProjectRevenue": 5000000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 65500,
                                    "totalProjectSpend": 65500,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 65500,
                                    "totalIndigenousProjectContributions": 65500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 65500,
                                    "totalDiverseSpend": 65500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 65500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 53562,
                                    "totalIndigenousEmploymentValue": 28465,
                                    "totalManHours": 198,
                                    "totalIndigenousManHours": 107
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:tcener",
                            "createdTime": "2021-05-08T00:30:15+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:15+0000",
                            "status": "live",
                            "displayName": "TC Energy",
                            "sortKey": "tc energy",
                            "etag": "E4823024011757292466",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "TC Energy",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "tcener",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 33,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 7755000,
                                    "totalProjectRevenue": 7755000,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 207648,
                                    "totalProjectSpend": 207648,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 207648,
                                    "totalIndigenousProjectContributions": 207648,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 207648,
                                    "totalDiverseSpend": 207648,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 207648,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 249780,
                                    "totalIndigenousEmploymentValue": 148905,
                                    "totalManHours": 973,
                                    "totalIndigenousManHours": 618
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:tmx",
                            "createdTime": "2021-05-08T00:30:14+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:14+0000",
                            "status": "live",
                            "displayName": "Transmountain",
                            "sortKey": "transmountain",
                            "etag": "E2941973035033364590",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Transmountain",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "tmx",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 34,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 28087657,
                                    "totalProjectRevenue": 28087657,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 110298,
                                    "totalProjectSpend": 110298,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 110298,
                                    "totalIndigenousProjectContributions": 110298,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 110298,
                                    "totalDiverseSpend": 110298,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 110298,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 238724,
                                    "totalIndigenousEmploymentValue": 174382,
                                    "totalManHours": 713,
                                    "totalIndigenousManHours": 503
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:VALCON",
                            "createdTime": "2021-05-08T00:30:13+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "previousVersion": "urn:nisto-link:id:vendor:VALCON:32",
                            "lastUpdated": "2021-07-14T16:58:23+0000",
                            "status": "live",
                            "displayName": "Valard Construction Ltd",
                            "sortKey": "valard construction ltd",
                            "etag": "E1912570624620638936",
                            "updater": "urn:nisto-link:id:person:jonathan-steele",
                            "version": "33"
                        },
                        "organization": {
                            "description": "Description of Vendor Valard Construction Ltd",
                            "orgName": "Valard Construction Ltd",
                            "indigenousOwnership": 0,
                            "imageUrls": {
                                "logo": "https://media-exp1.licdn.com/dms/image/C4D0BAQFQUAhJvzfTFA/company-logo_200_200/0/1576773257889?e=2159024400&v=beta&t=BpzHc40a9UKVtvv8JwzsH8RqzpkySIggk-WllBGTyWE"
                            },
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "VALCON",
                            "properties": {},
                            "impactTargets": {
                                "indigenousProjectBenefit": 4,
                                "indigenousCommunityContribution": 0.75,
                                "indigenousEmployment": 2.5,
                                "diverseProjectBenefit": 3,
                                "diverseEmployment": 0,
                                "socialProcurement": 5,
                                "diverseCommunityContribution": 0
                            },
                            "vendorState": "active",
                            "defaultExpenseClass": "uncategorized",
                            "hasProvisionalCreator": null
                        },
                        "diversityDeclarations": {},
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "index": 35,
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 0,
                                    "totalProjectRevenue": 0,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 7020330,
                                    "totalProjectSpend": 7020330,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 2585011,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 781707,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 2585011,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 781707,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:van",
                            "createdTime": "2021-05-08T00:30:12+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:12+0000",
                            "status": "live",
                            "displayName": "Vancouver City",
                            "sortKey": "vancouver city",
                            "etag": "E8324634716713587004",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Vancouver City",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "van",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 36,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 10250211,
                                    "totalProjectRevenue": 10250211,
                                    "totalGeneralRevenue": 0
                                }
                            }
                        }
                    }, {
                        "common": {
                            "identifier": "urn:nisto-link:id:vendor:vict",
                            "createdTime": "2021-05-08T00:30:12+0000",
                            "recordType": ["Organization", "Vendor", "Payee"],
                            "lastUpdated": "2021-05-08T00:30:12+0000",
                            "status": "live",
                            "displayName": "Victorian Government",
                            "sortKey": "victorian government",
                            "etag": "E1657642854140456627",
                            "updater": "urn:nisto-link:id:person:aaron-lambie",
                            "version": "2"
                        },
                        "organization": {
                            "orgName": "Victorian Government",
                            "indigenousOwnership": 0,
                            "imageUrls": {},
                            "addresses": [],
                            "licenses": ["pro.1"]
                        },
                        "vendor": {
                            "vendorCode": "vict",
                            "hasProvisionalCreator": {
                                "identifier": "urn:nisto-link:id:vendor:VALCON",
                                "recordType": ["Organization", "Vendor", "Payee"],
                                "displayName": "Valard Construction Ltd"
                            },
                            "vendorState": "provisional",
                            "properties": {},
                            "defaultExpenseClass": "services",
                            "isSelfEmployedContractor": false,
                            "impactTargets": {}
                        },
                        "diversityDeclarations": {},
                        "index": 37,
                        "resourceInfo": {
                            "safeToDelete": false
                        },
                        "totals": {
                            "revenue": {
                                "revenueTotals": {
                                    "displayName": "Revenue Totals",
                                    "totalRevenue": 5345785,
                                    "totalProjectRevenue": 5345785,
                                    "totalGeneralRevenue": 0
                                }
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 164087,
                                    "totalProjectSpend": 164087,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 152500,
                                    "totalIndigenousProjectContributions": 152500,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "socialProcurement": {
                                    "displayName": "Social Procurement",
                                    "totalIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 152500,
                                    "totalDiverseSpend": 152500,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 152500,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "displayName": "Employment",
                                    "totalEmploymentValue": 759872,
                                    "totalIndigenousEmploymentValue": 126700,
                                    "totalManHours": 2419,
                                    "totalIndigenousManHours": 274
                                }
                            }
                        }
                    }
                ]
            }
        }
    }
    return data;
}

export function getProjectSocialImpactTestData(): any {
    const data = {
        "reportTitle": "Project Social Impact Report",
        "reportType": "social-impact-project",
        "companyName": "Chandos Construction",
        "reportLogoUrl": "https://dev.nistolink.io/assets/img/Chandos_Logo.png",
        "dateRange": "Feb 1, 2019 - Sep 28, 2021",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Sep 28, 2021",
        "searchCriteria": {
            "expenseTypes": [],
            "facets": [],
            "projects": {
                "index": 2,
                "identifier": "urn:nisto-link:id:project:-3513--8339",
                "displayName": "Three Trees Pad Build (2)",
                "recordType": ["Project"],
                "projectId": " 3513-",
                "subProjectId": " 3513-",
                "poId": "2"
            },
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "beneficiaries": [],
            "dateFrom": "2019-02-01",
            "dateTo": "2021-09-28"
        },
        "organizationName": "Chandos Construction",
        "title": "Project Social Impact Report",
        "orgAggregate": {
            "facetDetail": {
                "identifier": "urn:nisto-link:id:project:-3513--8339",
                "displayName": "Three Trees Pad Build",
                "recordType": ["Project"],
                "startDate": "2019-02-01",
                "ownerVendor": "urn:nisto-link:id:vendor:chandos",
                "poId": "2"
            },
            "totalProjectExpenses": {
                "value": 2700781,
                "valueFormatted": "$2,700,781",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "totalProjectRevenue": {
                "value": 85058030,
                "valueFormatted": "$85,058,030",
                "percentage": 100,
                "percentageFormatted": "100%"
            },
            "socialProcurement": {
                "overall": {
                    "value": 2144838,
                    "valueFormatted": "$2,144,838",
                    "percentage": 79.42,
                    "percentageFormatted": "79.42%"
                },
                "esb": {
                    "value": 734903,
                    "valueFormatted": "$734,903",
                    "percentage": 27.21,
                    "percentageFormatted": "27.21%"
                },
                "esbDiverse": {
                    "value": 193,
                    "valueFormatted": "$193",
                    "percentage": 0.01,
                    "percentageFormatted": "0.01%"
                },
                "esbIndigenous": {
                    "value": 734710,
                    "valueFormatted": "$734,710",
                    "percentage": 27.2,
                    "percentageFormatted": "27.2%"
                },
                "localSpend": {
                    "value": 1409935,
                    "valueFormatted": "$1,409,935",
                    "percentage": 52.2,
                    "percentageFormatted": "52.2%"
                },
                "smeSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 193,
                            "valueFormatted": "$193",
                            "percentage": 0.01,
                            "percentageFormatted": "0.01%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 726,
                            "valueFormatted": "$726",
                            "percentage": 0.03,
                            "percentageFormatted": "0.03%"
                        },
                        "services": {
                            "value": 733984,
                            "valueFormatted": "$733,984",
                            "percentage": 27.18,
                            "percentageFormatted": "27.18%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 734710,
                            "valueFormatted": "$734,710",
                            "percentage": 27.2,
                            "percentageFormatted": "27.2%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 137237,
                            "valueFormatted": "$137,237",
                            "percentage": 5.08,
                            "percentageFormatted": "5.08%"
                        },
                        "services": {
                            "value": 1180979,
                            "valueFormatted": "$1,180,979",
                            "percentage": 43.73,
                            "percentageFormatted": "43.73%"
                        },
                        "equipment": {
                            "value": 91719,
                            "valueFormatted": "$91,719",
                            "percentage": 3.4,
                            "percentageFormatted": "3.4%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 1409935,
                            "valueFormatted": "$1,409,935",
                            "percentage": 52.2,
                            "percentageFormatted": "52.2%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 137963,
                            "valueFormatted": "$137,963",
                            "percentage": 5.11,
                            "percentageFormatted": "5.11%"
                        },
                        "services": {
                            "value": 2440021,
                            "valueFormatted": "$2,440,021",
                            "percentage": 90.35,
                            "percentageFormatted": "90.35%"
                        },
                        "equipment": {
                            "value": 91719,
                            "valueFormatted": "$91,719",
                            "percentage": 3.4,
                            "percentageFormatted": "3.4%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 2669703,
                            "valueFormatted": "$2,669,703",
                            "percentage": 98.85,
                            "percentageFormatted": "98.85%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 524865,
                            "valueFormatted": "$524,865",
                            "percentage": 19.43,
                            "percentageFormatted": "19.43%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 524865,
                            "valueFormatted": "$524,865",
                            "percentage": 19.43,
                            "percentageFormatted": "19.43%"
                        }
                    }
                }
            },
            "diverse": {
                "overall": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "esb": {
                    "value": 962889,
                    "valueFormatted": "$962,889",
                    "percentage": 1.13,
                    "percentageFormatted": "1.13%"
                },
                "esbDiverse": {
                    "value": 198032,
                    "valueFormatted": "$198,032",
                    "percentage": 0.23,
                    "percentageFormatted": "0.23%"
                },
                "esbIndigenous": {
                    "value": 764857,
                    "valueFormatted": "$764,857",
                    "percentage": 0.9,
                    "percentageFormatted": "0.9%"
                },
                "localSpend": {
                    "value": 1495706,
                    "valueFormatted": "$1,495,706",
                    "percentage": 1.76,
                    "percentageFormatted": "1.76%"
                },
                "smeSpend": {
                    "value": 28243,
                    "valueFormatted": "$28,243",
                    "percentage": 0.03,
                    "percentageFormatted": "0.03%"
                },
                "sociallyFocusedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "thirdPartyCertifiedSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "expenseDiversityCategories": {
                    "esb": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbDiverse": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "esbIndigenous": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "localSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "smeSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "sociallyFocusedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "thirdPartyCertifiedSpend": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "overall": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    },
                    "unknown": {
                        "goods": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "services": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "equipment": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "unknown": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        },
                        "total": {
                            "value": 0,
                            "valueFormatted": "0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    }
                }
            },
            "indigenous": {
                "directIndigenousSpend": {
                    "value": 764857,
                    "valueFormatted": "$764,857",
                    "percentage": 28.32,
                    "percentageFormatted": "28.32%"
                },
                "certifiedBusinessSpend": {
                    "value": 197839,
                    "valueFormatted": "$197,839",
                    "percentage": 7.33,
                    "percentageFormatted": "7.33%"
                },
                "communityOwnedSpend": {
                    "value": 519312,
                    "valueFormatted": "$519,312",
                    "percentage": 19.23,
                    "percentageFormatted": "19.23%"
                },
                "relationshipSpend": {
                    "value": 28243,
                    "valueFormatted": "$28,243",
                    "percentage": 1.05,
                    "percentageFormatted": "1.05%"
                },
                "selfDeclaredBusinessSpend": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                }
            },
            "employment": {
                "overall": {
                    "hours": {
                        "value": 6455.25,
                        "valueFormatted": "6455.25",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "wages": {
                        "value": 140973,
                        "valueFormatted": "$140,973",
                        "percentage": 100,
                        "percentageFormatted": "100%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseUnknown": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNoAnswer": {
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverseNo": {
                    "hours": {
                        "value": 703.25,
                        "valueFormatted": "703.25",
                        "percentage": 10.89,
                        "percentageFormatted": "10.89%"
                    },
                    "wages": {
                        "value": 15064,
                        "valueFormatted": "$15,064",
                        "percentage": 10.69,
                        "percentageFormatted": "10.69%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenous": {
                    "hours": {
                        "value": 543,
                        "valueFormatted": "543",
                        "percentage": 8.41,
                        "percentageFormatted": "8.41%"
                    },
                    "wages": {
                        "value": 11898,
                        "valueFormatted": "$11,898",
                        "percentage": 8.44,
                        "percentageFormatted": "8.44%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "diverse": {
                    "hours": {
                        "value": 5209,
                        "valueFormatted": "5209",
                        "percentage": 80.69,
                        "percentageFormatted": "80.69%"
                    },
                    "wages": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 80.87,
                        "percentageFormatted": "80.87%"
                    },
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "indigenousAndDiverse": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 5752,
                        "valueFormatted": "5752",
                        "percentage": 89.11,
                        "percentageFormatted": "89.11%"
                    },
                    "wages": {
                        "value": 125909,
                        "valueFormatted": "$125,909",
                        "percentage": 89.31,
                        "percentageFormatted": "89.31%"
                    }
                },
                "unknownAndNoAnswer": {
                    "headCount": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "hours": {
                        "value": 0,
                        "valueFormatted": "0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "wages": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                }
            },
            "tnb": {
                "total": {
                    "value": 156987,
                    "valueFormatted": "$156,987",
                    "percentage": 0.18,
                    "percentageFormatted": "0.18%"
                },
                "overall": {
                    "diverse": {
                        "value": 124695,
                        "valueFormatted": "$124,695",
                        "percentage": 0.15,
                        "percentageFormatted": "0.15%"
                    },
                    "indigenous": {
                        "value": 31361,
                        "valueFormatted": "$31,361",
                        "percentage": 0.04,
                        "percentageFormatted": "0.04%"
                    }
                },
                "projectBenefits": {
                    "diverse": {
                        "value": 10684,
                        "valueFormatted": "$10,684",
                        "percentage": 0.01,
                        "percentageFormatted": "0.01%"
                    },
                    "indigenous": {
                        "value": 19463,
                        "valueFormatted": "$19,463",
                        "percentage": 0.02,
                        "percentageFormatted": "0.02%"
                    }
                },
                "communityContributions": {
                    "diverse": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    },
                    "indigenous": {
                        "value": 0,
                        "valueFormatted": "$0",
                        "percentage": 0,
                        "percentageFormatted": "0%"
                    }
                },
                "employment": {
                    "diverse": {
                        "value": 114011,
                        "valueFormatted": "$114,011",
                        "percentage": 0.13,
                        "percentageFormatted": "0.13%"
                    },
                    "indigenous": {
                        "value": 11898,
                        "valueFormatted": "$11,898",
                        "percentage": 0.01,
                        "percentageFormatted": "0.01%"
                    }
                }
            }
        },
        "impactTargets": {
            "indigenousProjectBenefit": 4,
            "indigenousCommunityContribution": 0,
            "indigenousEmployment": 10,
            "socialProcurement": 20,
            "diverseProjectBenefit": 0,
            "diverseCommunityContribution": 0,
            "diverseEmployment": 15
        },
        "beneficiaryAggregate": {
            "searchResults": {
                "searchMeta": {
                    "page": 1,
                    "ipp": 999999
                },
                "resultMeta": {
                    "matched": 6,
                    "included": 6
                },
                "results": [{
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDirectIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 5194,
                                    "totalIndigenousEmploymentValue": 5194,
                                    "totalManHours": 233,
                                    "totalIndigenousManHours": 233
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0490",
                            "displayName": "Aishihik First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 1
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 28243,
                                "indigenousProjectRevenue": 0
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 28243,
                                    "totalProjectSpend": 28243,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 28243,
                                    "totalDirectIndigenousSpend": 28243,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 28243,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0142",
                            "displayName": "Albany First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 2
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 202186,
                                "indigenousProjectRevenue": 202186
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 221649,
                                    "totalProjectSpend": 221649,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 19463,
                                    "totalIndigenousProjectContributions": 19463,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 221649,
                                    "totalDirectIndigenousSpend": 202186,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437",
                            "displayName": "Alexis Nakota Sioux Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 3
                    }, {
                        "totals": {
                            "revenue": {
                                "projectRevenue": 317126,
                                "indigenousProjectRevenue": 317126
                            },
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 317126,
                                    "totalProjectSpend": 317126,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 317126,
                                    "totalDirectIndigenousSpend": 317126,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 0,
                                    "totalIndigenousEmploymentValue": 0,
                                    "totalManHours": 0,
                                    "totalIndigenousManHours": 0
                                }
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0296",
                            "displayName": "God's Lake First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 4
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDirectIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 3040,
                                    "totalIndigenousEmploymentValue": 3040,
                                    "totalManHours": 141,
                                    "totalIndigenousManHours": 141
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441",
                            "displayName": "Paul First Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 5
                    }, {
                        "totals": {
                            "expenses": {
                                "expenseTotals": {
                                    "displayName": "Expense Totals",
                                    "totalSpend": 0,
                                    "totalProjectSpend": 0,
                                    "totalGeneralSpend": 0,
                                    "totalProjectContributions": 0,
                                    "totalIndigenousProjectContributions": 0,
                                    "totalDiverseProjectContributions": 0,
                                    "totalCommunityContributions": 0,
                                    "totalIndigenousCommunityContributions": 0,
                                    "totalDiverseCommunityContributions": 0,
                                    "totalDonationContributions": 0
                                },
                                "diverseSpend": {
                                    "displayName": "Diverse Spend",
                                    "totalIndigenousSpend": 0,
                                    "totalDirectIndigenousSpend": 0,
                                    "totalDiverseSpend": 0,
                                    "totalDirectDiverseSpend": 0,
                                    "diversityDetail": {
                                        "totalThirdPartyCertifiedSpend": 0,
                                        "totalSociallyFocusedSpend": 0,
                                        "totalSmeSpend": 0,
                                        "totalLocalSpend": 0
                                    }
                                },
                                "employment": {
                                    "totalEmploymentValue": 3664,
                                    "totalIndigenousEmploymentValue": 3664,
                                    "totalManHours": 169,
                                    "totalIndigenousManHours": 169
                                }
                            },
                            "revenue": {
                                "projectRevenue": 0,
                                "indigenousProjectRevenue": 0
                            }
                        },
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:first-nation:fn-0432",
                            "displayName": "Tsuut'ina Nation",
                            "recordType": ["Organization", "IndigenousCommunity"],
                            "indigenousOwned": true,
                            "diversityDeclarations": {
                                "indigenousImpact": {
                                    "values": ["indigenousCommunity", "indigenousOwned", "indigenousFocused"]
                                },
                                "impactESG": {
                                    "values": ["indigenousOwned"]
                                },
                                "socialFocusedOrgs": {
                                    "values": ["indigenousFocused"]
                                }
                            }
                        },
                        "index": 6
                    }
                ]
            }
        },
        "clientAggregate": null,
        "expenseDiversityReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "Company",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "Goods ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Services ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Equipment ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "children": [{
                                "children": [],
                                "data": ["3E Glass Ltd.", {
                                        "value": 0,
                                        "valueFormatted": "$0",
                                        "percentage": 0,
                                        "percentageFormatted": "0%"
                                    }, {
                                        "value": 75087,
                                        "valueFormatted": "$75,087",
                                        "percentage": 2.78,
                                        "percentageFormatted": "2.78%"
                                    }, {
                                        "value": 0,
                                        "valueFormatted": "$0",
                                        "percentage": 0,
                                        "percentageFormatted": "0%"
                                    }
                                ]
                            }, {
                                "children": [],
                                "data": ["Houle Electric Ltd", {
                                        "value": 0,
                                        "valueFormatted": "$0",
                                        "percentage": 0,
                                        "percentageFormatted": "0%"
                                    }, {
                                        "value": 112068,
                                        "valueFormatted": "$112,068",
                                        "percentage": 4.15,
                                        "percentageFormatted": "4.15%"
                                    }, {
                                        "value": 0,
                                        "valueFormatted": "$0",
                                        "percentage": 0,
                                        "percentageFormatted": "0%"
                                    }
                                ]
                            }
                        ],
                        "data": ["Indigenous Owned Business", {
                                "value": 0,
                                "valueFormatted": "$0",
                                "percentage": 0,
                                "percentageFormatted": "0%"
                            }, {
                                "value": 187155,
                                "valueFormatted": "$187,155",
                                "percentage": 6.93,
                                "percentageFormatted": "6.93%"
                            }, {
                                "value": 0,
                                "valueFormatted": "$0",
                                "percentage": 0,
                                "percentageFormatted": "0%"
                            }
                        ]
                    }, {
                        "children": [{
                                "children": [],
                                "data": ["Dominion Blue Reprographics", {
                                        "value": 0,
                                        "valueFormatted": "$0",
                                        "percentage": 0,
                                        "percentageFormatted": "0%"
                                    }, {
                                        "value": 193,
                                        "valueFormatted": "$193",
                                        "percentage": 0.01,
                                        "percentageFormatted": "0.01%"
                                    }, {
                                        "value": 0,
                                        "valueFormatted": "$0",
                                        "percentage": 0,
                                        "percentageFormatted": "0%"
                                    }
                                ]
                            }
                        ],
                        "data": ["Certified Woman Owned Business", {
                                "value": 0,
                                "valueFormatted": "$0",
                                "percentage": 0,
                                "percentageFormatted": "0%"
                            }, {
                                "value": 193,
                                "valueFormatted": "$193",
                                "percentage": 0.01,
                                "percentageFormatted": "0.01%"
                            }, {
                                "value": 0,
                                "valueFormatted": "$0",
                                "percentage": 0,
                                "percentageFormatted": "0%"
                            }
                        ]
                    }
                ],
                "totalRow": {
                    "children": [],
                    "data": [{
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }, {
                            "value": 187348,
                            "valueFormatted": "$187,348",
                            "percentage": 6.94,
                            "percentageFormatted": "6.94%"
                        }, {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    ]
                },
                "total": {
                    "value": 187348,
                    "valueFormatted": "$187,348",
                    "percentage": 6.94,
                    "percentageFormatted": "6.94%"
                },
                "totalTitle": "Third Party Certified Equity Seeking Businesses",
                "color": "#4D79B8",
                "hideHeaders": false,
                "pageBreakAfter": false,
                "parentsBold": true,
                "hideIfZeroTotal": true,
                "className": "margin-bottom",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "Company",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "Goods ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Services ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Equipment ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [],
                "totalRow": {
                    "children": [],
                    "data": [{
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }, {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }, {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    ]
                },
                "total": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "Self Declared Equity Seeking Businesses",
                "color": "#4D79B8",
                "hideHeaders": false,
                "pageBreakAfter": false,
                "parentsBold": true,
                "hideIfZeroTotal": true,
                "className": "margin-bottom",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "Company",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "Goods ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Services ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Equipment ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [],
                "totalRow": {
                    "children": [],
                    "data": [{
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }, {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }, {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    ]
                },
                "total": {
                    "value": 0,
                    "valueFormatted": "$0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "Social Purpose Organizations",
                "color": "#4D79B8",
                "hideHeaders": false,
                "pageBreakAfter": false,
                "parentsBold": true,
                "hideIfZeroTotal": true,
                "className": "margin-bottom",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "Goods ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Services ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Equipment ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "children": [{
                                "children": [],
                                "data": ["HDR Architecture Associates Inc", {
                                        "value": 137237,
                                        "valueFormatted": "$137,237",
                                        "percentage": 5.08,
                                        "percentageFormatted": "5.08%"
                                    }, {
                                        "value": 1180979,
                                        "valueFormatted": "$1,180,979",
                                        "percentage": 43.73,
                                        "percentageFormatted": "43.73%"
                                    }, {
                                        "value": 91719,
                                        "valueFormatted": "$91,719",
                                        "percentage": 3.4,
                                        "percentageFormatted": "3.4%"
                                    }
                                ]
                            }
                        ],
                        "data": [null, {
                                "value": 137237,
                                "valueFormatted": "$137,237",
                                "percentage": 5.08,
                                "percentageFormatted": "5.08%"
                            }, {
                                "value": 1180979,
                                "valueFormatted": "$1,180,979",
                                "percentage": 43.73,
                                "percentageFormatted": "43.73%"
                            }, {
                                "value": 91719,
                                "valueFormatted": "$91,719",
                                "percentage": 3.4,
                                "percentageFormatted": "3.4%"
                            }
                        ]
                    }
                ],
                "totalRow": {
                    "children": [],
                    "data": [{
                            "value": 137237,
                            "valueFormatted": "$137,237",
                            "percentage": 5.08,
                            "percentageFormatted": "5.08%"
                        }, {
                            "value": 1180979,
                            "valueFormatted": "$1,180,979",
                            "percentage": 43.73,
                            "percentageFormatted": "43.73%"
                        }, {
                            "value": 91719,
                            "valueFormatted": "$91,719",
                            "percentage": 3.4,
                            "percentageFormatted": "3.4%"
                        }
                    ]
                },
                "total": {
                    "value": 1409935,
                    "valueFormatted": "$1,409,935",
                    "percentage": 52.2,
                    "percentageFormatted": "52.2%"
                },
                "totalTitle": "Local Spend",
                "color": "#54B0A4",
                "hideHeaders": false,
                "pageBreakAfter": false,
                "parentsBold": true,
                "hideIfZeroTotal": true,
                "className": "margin-bottom",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "Goods ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Services ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }, {
                        "text": "Equipment ($/%)",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "children": [{
                                "children": [],
                                "data": ["DRYSTAR Wall & Ceiling Ltd.", {
                                        "value": 0,
                                        "valueFormatted": "$0",
                                        "percentage": 0,
                                        "percentageFormatted": "0%"
                                    }, {
                                        "value": 28243,
                                        "valueFormatted": "$28,243",
                                        "percentage": 1.05,
                                        "percentageFormatted": "1.05%"
                                    }, {
                                        "value": 0,
                                        "valueFormatted": "$0",
                                        "percentage": 0,
                                        "percentageFormatted": "0%"
                                    }
                                ]
                            }
                        ],
                        "data": [null, {
                                "value": 0,
                                "valueFormatted": "$0",
                                "percentage": 0,
                                "percentageFormatted": "0%"
                            }, {
                                "value": 28243,
                                "valueFormatted": "$28,243",
                                "percentage": 1.05,
                                "percentageFormatted": "1.05%"
                            }, {
                                "value": 0,
                                "valueFormatted": "$0",
                                "percentage": 0,
                                "percentageFormatted": "0%"
                            }
                        ]
                    }
                ],
                "totalRow": {
                    "children": [],
                    "data": [{
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }, {
                            "value": 28243,
                            "valueFormatted": "$28,243",
                            "percentage": 1.05,
                            "percentageFormatted": "1.05%"
                        }, {
                            "value": 0,
                            "valueFormatted": "$0",
                            "percentage": 0,
                            "percentageFormatted": "0%"
                        }
                    ]
                },
                "total": {
                    "value": 28243,
                    "valueFormatted": "$28,243",
                    "percentage": 1.05,
                    "percentageFormatted": "1.05%"
                },
                "totalTitle": "Small Enterprise Spend",
                "color": "#71AF54",
                "hideHeaders": false,
                "pageBreakAfter": false,
                "parentsBold": true,
                "hideIfZeroTotal": true,
                "className": "margin-bottom",
                "autoCalculateTotalRow": false
            }
        ],
        "projectSummaryReportDataTable": {
            "title": "",
            "headers": [],
            "rows": [],
            "totalRow": {
                "children": [],
                "data": []
            },
            "total": {
                "value": 0,
                "valueFormatted": "0",
                "percentage": 0,
                "percentageFormatted": "0%"
            },
            "totalTitle": "",
            "color": "",
            "hideHeaders": false,
            "pageBreakAfter": false,
            "parentsBold": true,
            "hideIfZeroTotal": false,
            "className": "",
            "autoCalculateTotalRow": false
        },
        "payeeCommunityReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["God's Lake First Nation", "Total Community Spend", "$317,126"]
                    }, {
                        "data": ["Indigenous Community Owned Businesses", "", "$317,126"],
                        "children": [{
                                "data": ["Lloyd Sadd (Internal Only)", "", "$317,126"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Albany First Nation", "Total Community Spend", "$28,243"]
                    }, {
                        "data": ["Community Relationships", "Total Relationship Spend", "$28,243"],
                        "children": [{
                                "data": ["DRYSTAR Wall & Ceiling Ltd.", "", "$28,243"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }, {
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Alexis Nakota Sioux Nation", "Total Community Spend", "$202,186"]
                    }, {
                        "data": ["Indigenous Community Owned Businesses", "", "$202,186"],
                        "children": [{
                                "data": ["AES Engineering Ltd", "", "$202,186"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "indigenousTypeReportDataTables": [{
                "title": "",
                "headers": [{
                        "text": "",
                        "rowLayout": 6,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "left"
                    }, {
                        "text": "",
                        "rowLayout": 3,
                        "align": "right"
                    }
                ],
                "rows": [{
                        "data": ["Certified Indigenous Owned Business", "Total Certified Indigenous Owned Business Spend", "$187,155"],
                        "children": [{
                                "data": ["3E Glass Ltd.", "", "$75,087"],
                                "children": []
                            }, {
                                "data": ["Houle Electric Ltd", "", "$112,068"],
                                "children": []
                            }
                        ]
                    }
                ],
                "totalRow": null,
                "total": {
                    "value": 0,
                    "valueFormatted": "0",
                    "percentage": 0,
                    "percentageFormatted": "0%"
                },
                "totalTitle": "",
                "color": "",
                "hideHeaders": false,
                "pageBreakAfter": true,
                "parentsBold": true,
                "hideIfZeroTotal": false,
                "className": "",
                "autoCalculateTotalRow": false
            }
        ],
        "summaryReportDataTables": [],
        "communityReportDataTables": []
    }
    return data;    
}

export function getProjectsVendorsTestData(): any {
    const data =
    {
        "searchResults": {
            "searchMeta": {
                "ipp": 25,
                "page": 1,
                "type": "Project"
            },
            "resultMeta": {
                "matched": 2,
                "included": 25
            },
            "results": [
                {
                    "index": 1,
                    "common": {
                        "identifier": "urn:nisto-link:id:vendor:one",
                        "createdTime": "2020-10-09T20:44:31.871892Z",
                        "recordType": ["Vendor"],
                        "lastUpdated": "2020-10-09T20:44:31.871892Z",
                        "status": "live",
                        "displayName": "Vendor 1",
                        "sortKey": "Vendor 1",
                        "etag": "E20201009204431268cb",
                        "updater": "urn:nisto-link:id:person:aaron-lambie",
                        "version": 1
                    },
                    "vendor": {
                        "vendorType": "Goods",
                        "spend": 123456,
                        "invitedState": "Invite",
                        "isLocal": true,
                        "isIndigenousPartner": true,
                        "isDiverse": true
                    }
                },
                {
                    "index": 2,
                    "common": {
                        "identifier": "urn:nisto-link:id:vendor:two",
                        "createdTime": "2020-10-09T20:44:31.871892Z",
                        "recordType": ["Vendor"],
                        "lastUpdated": "2020-10-09T20:44:31.871892Z",
                        "status": "live",
                        "displayName": "Vendor 2",
                        "sortKey": "Vendor 2",
                        "etag": "E20201009204431268cb",
                        "updater": "urn:nisto-link:id:person:aaron-lambie",
                        "version": 1
                    },
                    "vendor": {
                        "vendorType": "Services",
                        "spend": 7891011,
                        "invitedState": "Pending",
                        "isLocal": false,
                        "isIndigenousPartner": false,
                        "isDiverse": false
                    }
                }
            ]
        }
    }
    return data;
}

export function getProjectsEmailReportTestData(): any {
    const reportData =
    {
        "reportType": "project-email",
        "preparedOn": "December 14, 2020",
        "searchCriteria": {
          "dateFrom": "",
          "dateTo": ""
        },
        "organizationName": "Pine Valley First Nation",
        "reportTitle": "Project Based Indigenous Benefit Report",
        "title": "Project Based Indigenous Benefit Report",
        "orgAggregate": {
          "totals": {
            "spend": {"isRevenueIndigenous": false, "totalIndigenousProjectRevenue": 0, "totalIndigenousSpend": 456789, "totalProjectRevenue": 123456, "totalProjectSpend": 0},
            "employment": {"occupations": {"driver": {"displayName": "Driver", "occupationCode": "driver", "totalEmploymentValue": 2880, "totalIndigenousEmploymentValue": 2880, "totalIndigenousManHours": 128, "totalManHours": 128}, "pipeFitter": {"displayName": "Pipe Fitter", "occupationCode": "pipe-fitter", "totalEmploymentValue": 1440, "totalIndigenousEmploymentValue": 0, "totalIndigenousManHours": 0, "totalManHours": 64}}, "totalEmploymentValue": 4320, "totalIndigenousEmploymentValue": 2880, "totalIndigenousManHours": 128, "totalManHours": 192},
            "benefit": {"communityInvestment": {"rejected": 0, "submitted": 7500, "verified": 0, "voided": 0}, "projectBenefit": {"rejected": 0, "submitted": 234567, "verified": 0, "voided": 0}}
          }
        },
        "projects": [
          {
            "common": {"createdTime": "2020-07-29T23:20:29.581711Z", "displayName": "Australian Pipeline", "etag": "E2020102902565169b8a", "identifier": "urn:nisto-link:id:project:auspipeline", "lastUpdated": "2020-10-29T02:56:51.177888Z", "previousVersion": "/records/urn:nisto-link:id:project:auspipeline:3.xml", "recordType": ["Project", "SubProject"], "sortKey": "australian pipeline", "status": "live", "updater": "urn:nisto-link:id:person:craig-schlegelmilch", "version": 4},
            "index": 1,
            "project": {"discipline": "construction--pipeline-", "hasContact": "urn:nisto-link:id:person:omesh-kumar", "hasSubmitter": "urn:nisto-link:id:person:aaron-lambie", "ownerVendor": "urn:nisto-link:id:vendor:VALCON", "projectId": "auspipeline", "projectName": "Australian Pipeline", "province": "NT", "startDate": "2020-09-03", "targetCommunityInvestment": 0, "targetEmployment": 0, "targetProjectBenefit": 0},
            "resourceInfo": {
                "safeToDelete": false
            },
            "subproject": {
                "contractorType": "vendor",
                "creatorVendor": {"displayName": "Australian Mining Co.", "identifier": "urn:nisto-link:id:vendor:amc", "indigenousOwnership": 0, "vendorCode": "amc", "vendorState": "provisional"},
                "hasProvisionalCreator": "urn:nisto-link:id:vendor:VALCON",
                "poId": 2544215,
                "subProjectId": "auspipeline",
                "subProjectName": "Australian Pipeline"
            },
            "totals": {"benefit": {"communityInvestment": {"rejected": 0, "submitted": 0, "verified": 0, "voided": 0}, "projectBenefit": {"rejected": 0, "submitted": 9876, "verified": 0, "voided": 0}}, "employment": {"occupations": "", "totalEmploymentValue": 0, "totalIndigenousEmploymentValue": 76543, "totalIndigenousManHours": 0, "totalManHours": 0}, "spend": {"isRevenueIndigenous": true, "totalIndigenousProjectRevenue": 4800000, "totalIndigenousSpend": 1234567, "totalProjectRevenue": 4800000, "totalProjectSpend": 0}}
          }
        ]
      }
    return reportData;
}

export function getProjectsReportTestData(): any {
    const reportData =
    {
        "reportTitle:": "Project Benefit Report",
        "reportType": "project",
        "dateRange": "All",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Aug 13, 2020",
        "searchCriteria": {
            "projects": {
                "index": 2,
                "identifier": "urn:nisto-link:id:project:aushwy",
                "displayName": "Australian Highway",
                "recordType": ["Project", "SubProject"]
            },
            "subVendors": [],
            "organizations": [],
            "clients": [],
            "jobFocuses": [],
            "dateFrom": "",
            "dateTo": ""
        },
        "organizationName": "Valard Construction Ltd",
        "title": "Project Based Indigenous Benefit Report",
        "orgAggregate": {
            "searchResults": {
                "searchMeta": {
                    "format": "aggregate",
                    "ipp": 999,
                    "page": 1
                },
                "resultMeta": {
                    "matched": 1,
                    "included": 1
                },
                "groupedBy": {
                    "code": "payer",
                    "name": "Payer"
                },
                "results": [{
                        "index": 1,
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:vendor:VALCON",
                            "recordType": ["Organization", "Vendor"],
                            "displayName": "Valard Construction Ltd",
                            "indigenousOwned": false
                        },
                        "totals": {
                            "spend": {
                                "totalProjectRevenue": 12545785,
                                "totalIndigenousProjectRevenue": 0,
                                "totalIndigenousSpend": 0,
                                "isRevenueIndigenous": false,
                                "totalProjectSpend": 0
                            },
                            "employment": {
                                "totalEmploymentValue": 150700,
                                "totalIndigenousEmploymentValue": 123550,
                                "totalManHours": 3927,
                                "totalIndigenousManHours": 3228,
                                "occupations": {
                                    "fuelTruckAndLubeTruckOperators": {
                                        "totalEmploymentValue": 25650,
                                        "totalIndigenousEmploymentValue": 21600,
                                        "occupationCode": "fuel-truck-and-lube-truck-operators",
                                        "totalManHours": 684,
                                        "totalIndigenousManHours": 576,
                                        "occupationDisplayName": "Fuel Truck and Lube Truck Operators"
                                    },
                                    "packerOperator": {
                                        "totalEmploymentValue": 4950,
                                        "totalIndigenousEmploymentValue": 4050,
                                        "occupationCode": "packer-operator",
                                        "totalManHours": 132,
                                        "totalIndigenousManHours": 108,
                                        "occupationDisplayName": "Packer Operator"
                                    },
                                    "generalLabourers": {
                                        "totalEmploymentValue": 120100,
                                        "totalIndigenousEmploymentValue": 97900,
                                        "occupationCode": "general-labourers",
                                        "totalManHours": 3111,
                                        "totalIndigenousManHours": 2544,
                                        "occupationDisplayName": "General Labourers"
                                    }
                                }
                            },
                            "benefit": {
                                "communityInvestment": {
                                    "submitted": 0,
                                    "rejected": 0,
                                    "verified": 0,
                                    "voided": 0
                                },
                                "projectBenefit": {
                                    "submitted": 77500,
                                    "rejected": 0,
                                    "verified": 0,
                                    "voided": 75000
                                }
                            }
                        },
                        "cachedAt": "2020-08-13T13:37:08.911557Z"
                    }
                ]
            }
        },
        "totalContributionsByCommunity": {
            "chart": {
                "type": "bar",
                "height": 452,
                "style": {
                    "fontFamily": "Roboto-Regular"
                },
                "events": {
                    "drilldown": [],
                    "drillup": []
                }
            },
            "title": {
                "text": "Total Tangible Net Benefits by Indigenous Community",
                "style": {
                    "fontSize": "22px"
                },
                "align": "left"
            },
            "legend": {
                "itemStyle": {
                    "fontSize": "16px"
                },
                "align": "center"
            },
            "credits": {
                "enabled": false
            },
            "xAxis": {
                "categories": ["Alexis Nakota Sioux Nation", "Aamjiwnaang First Nation", "Paul First Nation", "Adams Lake First Nation", "Acadia First Nation", "Acho Dene Koe First Nation", "Ducharme Lake Métis", "Driftpile Cree Nation", "Cold Lake First Nations", "Bigstone Cree Nation", "Sturgeon Lake Cree Nation", "Assiniboia Métis", "Sucker Creek First Nation", "Dene Tha' First Nation"],
                "title": {
                    "text": "",
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "labels": {
                    "style": {
                        "fontSize": "16px"
                    }
                }
            },
            "yAxis": {
                "allowDecimals": false,
                "min": 0,
                "title": {
                    "text": "",
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "labels": {
                    "style": {
                        "fontSize": "16px"
                    }
                }
            },
            "colors": ["#794D81", "#367A98", "#54B0A4"],
            "tooltip": {
                "pointFormat": "{series.name}: <b>{point.y}</b><br/>",
                "valuePrefix": "$",
                "shared": true
            },
            "plotOptions": {
                "series": {
                    "stacking": "normal",
                    "pointWidth": 12
                },
                "bar": {
                    "groupPadding": 0
                }
            },
            "series": [{
                    "name": "Tangible Net Benefits: Project Benefits",
                    "stack": "stack3",
                    "data": [{
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0172"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0684"
                        }, {
                            "y": 2500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0018"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0758"
                        }, {
                            "y": 75000,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98225"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0450"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0464"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0458"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0455"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98316"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0456"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0448"
                        }
                    ]
                }, {
                    "name": "Tangible Net Benefits: Community Investments",
                    "stack": "stack3",
                    "data": [{
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437"
                        }, {
                            "y": 21171,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0172"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441"
                        }, {
                            "y": 46545,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0684"
                        }, {
                            "y": 25000,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0018"
                        }, {
                            "y": 16655,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0758"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98225"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0450"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0464"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0458"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0455"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98316"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0456"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0448"
                        }
                    ]
                }, {
                    "name": "Tangible Net Benefits: Indigenous Employment",
                    "stack": "stack3",
                    "data": [{
                            "y": 2700,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0172"
                        }, {
                            "y": 3600,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0684"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0018"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0758"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98225"
                        }, {
                            "y": 12800,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0450"
                        }, {
                            "y": 28300,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0464"
                        }, {
                            "y": 28600,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0458"
                        }, {
                            "y": 29600,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0455"
                        }, {
                            "y": 9850,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98316"
                        }, {
                            "y": 6300,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0456"
                        }, {
                            "y": 1800,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0448"
                        }
                    ]
                }
            ],
            "exporting": {
                "enabled": true,
                "sourceWidth": 1400,
                "sourceHeight": 452,
                "useMultiLevelHeaders": false,
                "showTable": true
            },
            "formatNumbersToCurrency": true
        },
        "totalContributionsByClient": {},
        "subContractingAnalysis": {
            "stacks": [{
                    "percentage": "0",
                    "amount": 0,
                    "label": "INDIGENOUS SPEND"
                }, {
                    "percentage": "0",
                    "amount": 0,
                    "label": "NON-INDIGENOUS SPEND"
                }
            ],
            "totalAmount": 0
        },
        "employmentBalance": {
            "stacks": [{
                    "percentage": "81.98",
                    "amount": 123550,
                    "label": "INDIGENOUS EMPLOYMENT"
                }, {
                    "percentage": "18.02",
                    "amount": 27150,
                    "label": "NON-INDIGENOUS EMPLOYMENT"
                }
            ],
            "totalAmount": 150700
        },
        "employmentByCommunity": [{
                "index": 1,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0437",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Alexis Nakota Sioux Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 15000,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 15000
                    },
                    "employment": {
                        "totalEmploymentValue": 2700,
                        "totalIndigenousEmploymentValue": 2700,
                        "totalManHours": 72,
                        "totalIndigenousManHours": 72
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 2,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0172",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Aamjiwnaang First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 21171,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 69009
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 3,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0467",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Fort McKay First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 4,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0441",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Paul First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 3600,
                        "totalIndigenousEmploymentValue": 3600,
                        "totalManHours": 96,
                        "totalIndigenousManHours": 96
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 5,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0001",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Abegweit First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 6,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0165",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Caldwell First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 7,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0684",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Adams Lake First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 46545,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 8,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0490",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Aishihik First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 9,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0018",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Acadia First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 25000,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 2500,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 10,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0139",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Chippewas of Rama First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 11,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0388",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Wood Mountain First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 12,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0200",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Mississauga First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 13,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0457",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Swan River First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 14,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0440",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Enoch Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 15,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98359",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Métis Nation of Alberta",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 16,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0758",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Acho Dene Koe First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 16655,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 17,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0438",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Alexander First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 18,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0406",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Ahtahkakoop First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 75000
                        }
                    }
                }
            }, {
                "index": 19,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98225",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Ducharme Lake Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 12545785,
                        "totalIndigenousProjectRevenue": 12545785,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 75000,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 20,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0659",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Ahousaht First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 21,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98282",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Battleford Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 22,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0463",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Athabasca Chipewyan First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 23,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:inuit:in-991000",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Aklavik Inuit",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 24,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0450",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Driftpile Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 12800,
                        "totalIndigenousEmploymentValue": 12800,
                        "totalManHours": 340,
                        "totalIndigenousManHours": 340
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 25,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0464",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Cold Lake First Nations",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 28300,
                        "totalIndigenousEmploymentValue": 28300,
                        "totalManHours": 747,
                        "totalIndigenousManHours": 747
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 26,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0458",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Bigstone Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 28600,
                        "totalIndigenousEmploymentValue": 28600,
                        "totalManHours": 728,
                        "totalIndigenousManHours": 728
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 27,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:indigenous:in-0",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Indigenous (General)",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 28,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98294",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "MacDowall Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 29,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0455",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Sturgeon Lake Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 29600,
                        "totalIndigenousEmploymentValue": 29600,
                        "totalManHours": 774,
                        "totalIndigenousManHours": 774
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 30,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98316",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Assiniboia Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 9850,
                        "totalIndigenousEmploymentValue": 9850,
                        "totalManHours": 255,
                        "totalIndigenousManHours": 255
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 31,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0456",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Sucker Creek First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 6300,
                        "totalIndigenousEmploymentValue": 6300,
                        "totalManHours": 168,
                        "totalIndigenousManHours": 168
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 32,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0448",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Dene Tha' First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 1800,
                        "totalIndigenousEmploymentValue": 1800,
                        "totalManHours": 48,
                        "totalIndigenousManHours": 48
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 33,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-2",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Métis (General)",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 34,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0447",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Little Red River Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 35,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0446",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Tallcree Tribal Government",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 36,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0462",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Saddle Lake Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 37,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98267",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Cochin Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }
        ],
        "employmentByOccupation": [{
                "occupationName": "Fuel Truck and Lube Truck Operators",
                "occupationCode": "fuel-truck-and-lube-truck-operators",
                "totalEmploymentValue": 25650,
                "totalIndigenousEmploymentValue": 21600,
                "totalNonIndigenousEmploymentValue": 4050,
                "totalManHours": 684,
                "totalIndigenousManHours": 576
            }, {
                "occupationName": "Packer Operator",
                "occupationCode": "packer-operator",
                "totalEmploymentValue": 4950,
                "totalIndigenousEmploymentValue": 4050,
                "totalNonIndigenousEmploymentValue": 900,
                "totalManHours": 132,
                "totalIndigenousManHours": 108
            }, {
                "occupationName": "General Labourers",
                "occupationCode": "general-labourers",
                "totalEmploymentValue": 120100,
                "totalIndigenousEmploymentValue": 97900,
                "totalNonIndigenousEmploymentValue": 22200,
                "totalManHours": 3111,
                "totalIndigenousManHours": 2544
            }
        ],
        "totalIndigenousCommunityInvestment": {},
        "communityInvestmentReportItems": []
    }
    return reportData;
}

export function getReportTestData(): any {
    const reportData =
    {
        "dateRange": "All",
        "preparedBy": "Jonathan Steele",
        "preparedOn": "Aug 12, 2020",
        "searchCriteria": {
            "projects": [],
            "subVendors": [],
            "organizations": [],
            "jobFocuses": [],
            "dateFrom": "",
            "dateTo": ""
        },
        "organizationName": "Valard Construction Ltd",
        "title": "Indigenous Benefit Report",
        "orgAggregate": {
            "searchResults": {
                "searchMeta": {
                    "format": "aggregate",
                    "ipp": 999,
                    "page": 1
                },
                "resultMeta": {
                    "matched": 1,
                    "included": 1
                },
                "groupedBy": {
                    "code": "payer",
                    "name": "Payer"
                },
                "results": [{
                        "index": 1,
                        "facetDetail": {
                            "identifier": "urn:nisto-link:id:vendor:VALCON",
                            "recordType": ["Organization", "Vendor"],
                            "displayName": "Valard Construction Ltd",
                            "indigenousOwned": false
                        },
                        "totals": {
                            "spend": {
                                "totalProjectRevenue": 122408970,
                                "totalIndigenousProjectRevenue": 0,
                                "totalIndigenousSpend": 28319517,
                                "isRevenueIndigenous": false,
                                "totalProjectSpend": 28445439
                            },
                            "employment": {
                                "totalEmploymentValue": 891392,
                                "totalIndigenousEmploymentValue": 625122,
                                "totalManHours": 26023.49,
                                "totalIndigenousManHours": 18592.99,
                                "occupations": {
                                    "materialsCoOrdinators": {
                                        "totalEmploymentValue": 374,
                                        "totalIndigenousEmploymentValue": 374,
                                        "occupationCode": "materials-co-ordinators",
                                        "totalManHours": 11,
                                        "totalIndigenousManHours": 11,
                                        "occupationDisplayName": "Materials Co-ordinators"
                                    },
                                    "campAttendants": {
                                        "totalEmploymentValue": 1664,
                                        "totalIndigenousEmploymentValue": 1664,
                                        "occupationCode": "camp-attendants",
                                        "totalManHours": 8,
                                        "totalIndigenousManHours": 8,
                                        "occupationDisplayName": "Camp Attendants"
                                    },
                                    "cook__KitchenManagers": {
                                        "totalEmploymentValue": 35665,
                                        "totalIndigenousEmploymentValue": 30685,
                                        "occupationCode": "cook---kitchen-managers",
                                        "totalManHours": 1422,
                                        "totalIndigenousManHours": 1219,
                                        "occupationDisplayName": "Cook / Kitchen Managers"
                                    },
                                    "packerOperator": {
                                        "totalEmploymentValue": 17988,
                                        "totalIndigenousEmploymentValue": 16188,
                                        "occupationCode": "packer-operator",
                                        "totalManHours": 491,
                                        "totalIndigenousManHours": 443,
                                        "occupationDisplayName": "Packer Operator"
                                    },
                                    "surveyHelpers": {
                                        "totalEmploymentValue": 15184,
                                        "totalIndigenousEmploymentValue": 14884,
                                        "occupationCode": "survey-helpers",
                                        "totalManHours": 597,
                                        "totalIndigenousManHours": 591,
                                        "occupationDisplayName": "Survey Helpers"
                                    },
                                    "bulldozerOperators": {
                                        "totalEmploymentValue": 200,
                                        "totalIndigenousEmploymentValue": 200,
                                        "occupationCode": "bulldozer-operators",
                                        "totalManHours": 10.99,
                                        "totalIndigenousManHours": 10.99,
                                        "occupationDisplayName": "Bulldozer Operators"
                                    },
                                    "loaderOperator": {
                                        "totalEmploymentValue": 140197,
                                        "totalIndigenousEmploymentValue": 86168,
                                        "occupationCode": "loader-operator",
                                        "totalManHours": 3796,
                                        "totalIndigenousManHours": 2393.5,
                                        "occupationDisplayName": "Loader Operator"
                                    },
                                    "fieldOfficeAdministrators": {
                                        "totalEmploymentValue": 133684,
                                        "totalIndigenousEmploymentValue": 102710,
                                        "occupationCode": "field-office-administrators",
                                        "totalManHours": 5201,
                                        "totalIndigenousManHours": 4012,
                                        "occupationDisplayName": "Field Office Administrators"
                                    },
                                    "generalLabourers": {
                                        "totalEmploymentValue": 387435,
                                        "totalIndigenousEmploymentValue": 289111,
                                        "occupationCode": "general-labourers",
                                        "totalManHours": 10360.5,
                                        "totalIndigenousManHours": 7793.5,
                                        "occupationDisplayName": "General Labourers"
                                    },
                                    "assistantSuperintendents": {
                                        "totalEmploymentValue": 1172,
                                        "totalIndigenousEmploymentValue": 1172,
                                        "occupationCode": "assistant-superintendents",
                                        "totalManHours": 5,
                                        "totalIndigenousManHours": 5,
                                        "occupationDisplayName": "Assistant Superintendents"
                                    },
                                    "oilers": {
                                        "totalEmploymentValue": 35238,
                                        "totalIndigenousEmploymentValue": 10517,
                                        "occupationCode": "oilers",
                                        "totalManHours": 858.5,
                                        "totalIndigenousManHours": 249,
                                        "occupationDisplayName": "Oilers"
                                    },
                                    "structuralSteelErectors": {
                                        "totalEmploymentValue": 9056,
                                        "totalIndigenousEmploymentValue": 0,
                                        "occupationCode": "structural-steel-erectors",
                                        "totalManHours": 264,
                                        "totalIndigenousManHours": 0,
                                        "occupationDisplayName": "Structural Steel Erectors"
                                    },
                                    "fuelTruckAndLubeTruckOperators": {
                                        "totalEmploymentValue": 51300,
                                        "totalIndigenousEmploymentValue": 43200,
                                        "occupationCode": "fuel-truck-and-lube-truck-operators",
                                        "totalManHours": 1368,
                                        "totalIndigenousManHours": 1152,
                                        "occupationDisplayName": "Fuel Truck and Lube Truck Operators"
                                    },
                                    "safetyCoordinators": {
                                        "totalEmploymentValue": 5007,
                                        "totalIndigenousEmploymentValue": 0,
                                        "occupationCode": "safety-coordinators",
                                        "totalManHours": 134.5,
                                        "totalIndigenousManHours": 0,
                                        "occupationDisplayName": "Safety Coordinators"
                                    },
                                    "bendingMachineOperators": {
                                        "totalEmploymentValue": 32400,
                                        "totalIndigenousEmploymentValue": 16200,
                                        "occupationCode": "bending-machine-operators",
                                        "totalManHours": 864,
                                        "totalIndigenousManHours": 432,
                                        "occupationDisplayName": "Bending Machine Operators"
                                    },
                                    "draftingTechnologistsAndTechnicians": {
                                        "totalEmploymentValue": 2394,
                                        "totalIndigenousEmploymentValue": 2394,
                                        "occupationCode": "drafting-technologists-and-technicians",
                                        "totalManHours": 66,
                                        "totalIndigenousManHours": 66,
                                        "occupationDisplayName": "Drafting Technologists and Technicians"
                                    },
                                    "administrativeSupporters": {
                                        "totalEmploymentValue": 1444,
                                        "totalIndigenousEmploymentValue": 718,
                                        "occupationCode": "administrative-supporters",
                                        "totalManHours": 51,
                                        "totalIndigenousManHours": 27,
                                        "occupationDisplayName": "Administrative Supporters"
                                    },
                                    "plumbersGasfitters": {
                                        "totalEmploymentValue": 4950,
                                        "totalIndigenousEmploymentValue": 0,
                                        "occupationCode": "plumbers-gasfitters",
                                        "totalManHours": 156,
                                        "totalIndigenousManHours": 0,
                                        "occupationDisplayName": "Plumbers/Gasfitters"
                                    },
                                    "faller": {
                                        "totalEmploymentValue": 16040,
                                        "totalIndigenousEmploymentValue": 8937,
                                        "occupationCode": "faller",
                                        "totalManHours": 359,
                                        "totalIndigenousManHours": 180,
                                        "occupationDisplayName": "Faller"
                                    }
                                }
                            },
                            "benefit": {
                                "communityInvestment": {
                                    "submitted": 109371,
                                    "rejected": 0,
                                    "verified": 0,
                                    "voided": 69009
                                },
                                "projectBenefit": {
                                    "submitted": 736610,
                                    "rejected": 0,
                                    "verified": 0,
                                    "voided": 75100
                                }
                            }
                        }
                    }
                ]
            }
        },
        "totalContributionsByCommunity": {
            "chart": {
                "type": "bar",
                "height": 758,
                "style": {
                    "fontFamily": "Roboto-Regular"
                },
                "events": {
                    "drilldown": [],
                    "drillup": []
                }
            },
            "title": {
                "text": "Total Tangible Net Benefits by Indigenous Community",
                "style": {
                    "fontSize": "22px"
                },
                "align": "left"
            },
            "legend": {
                "itemStyle": {
                    "fontSize": "16px"
                },
                "align": "center"
            },
            "credits": {
                "enabled": false
            },
            "xAxis": {
                "categories": ["Mississauga First Nation", "Paul First Nation", "Alexis Nakota Sioux Nation", "Fort McKay First Nation", "Acho Dene Koe First Nation", "Enoch Cree Nation", "Aamjiwnaang First Nation", "Abegweit First Nation", "Caldwell First Nation", "Adams Lake First Nation", "Aishihik First Nation", "Acadia First Nation", "Chippewas of Rama First Nation", "Wood Mountain First Nation", "Swan River First Nation", "Métis Nation of Alberta", "Alexander First Nation", "Ducharme Lake Métis", "Driftpile Cree Nation", "Cold Lake First Nations", "Bigstone Cree Nation", "Indigenous (General)", "MacDowall Métis", "Sturgeon Lake Cree Nation", "Assiniboia Métis", "Sucker Creek First Nation", "Dene Tha' First Nation", "Métis (General)", "Little Red River Cree Nation", "Tallcree Tribal Government", "Saddle Lake Cree Nation"],
                "title": {
                    "text": "",
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "labels": {
                    "style": {
                        "fontSize": "16px"
                    }
                }
            },
            "yAxis": {
                "allowDecimals": false,
                "min": 0,
                "title": {
                    "text": "",
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "labels": {
                    "style": {
                        "fontSize": "16px"
                    }
                }
            },
            "colors": ["#794D81", "#367A98", "#54B0A4"],
            "tooltip": {
                "pointFormat": "{series.name}: <b>{point.y}</b><br/>",
                "valuePrefix": "$",
                "shared": true
            },
            "plotOptions": {
                "series": {
                    "stacking": "normal",
                    "pointWidth": 12
                },
                "bar": {
                    "groupPadding": 0
                }
            },
            "series": [{
                    "name": "Tangible Net Benefits: Project Benefits",
                    "stack": "stack3",
                    "data": [{
                            "y": 25465,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0200"
                        }, {
                            "y": 235695,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437"
                        }, {
                            "y": 109254,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0467"
                        }, {
                            "y": 6000,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0758"
                        }, {
                            "y": 456,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0440"
                        }, {
                            "y": 20100,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0172"
                        }, {
                            "y": 152625,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0001"
                        }, {
                            "y": 250,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0165"
                        }, {
                            "y": 2500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0684"
                        }, {
                            "y": 67500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0490"
                        }, {
                            "y": 2500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0018"
                        }, {
                            "y": 2500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0139"
                        }, {
                            "y": 25000,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0388"
                        }, {
                            "y": 4556,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0457"
                        }, {
                            "y": 563,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98359"
                        }, {
                            "y": 6646,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0438"
                        }, {
                            "y": 75000,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98225"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0450"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0464"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0458"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:indigenous:in-0"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98294"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0455"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98316"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0456"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0448"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-2"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0447"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0446"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0462"
                        }
                    ]
                }, {
                    "name": "Tangible Net Benefits: Community Investments",
                    "stack": "stack3",
                    "data": [{
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0200"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0467"
                        }, {
                            "y": 16655,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0758"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0440"
                        }, {
                            "y": 21171,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0172"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0001"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0165"
                        }, {
                            "y": 46545,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0684"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0490"
                        }, {
                            "y": 25000,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0018"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0139"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0388"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0457"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98359"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0438"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98225"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0450"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0464"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0458"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:indigenous:in-0"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98294"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0455"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98316"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0456"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0448"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-2"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0447"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0446"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0462"
                        }
                    ]
                }, {
                    "name": "Tangible Net Benefits: Indigenous Employment",
                    "stack": "stack3",
                    "data": [{
                            "y": 21404,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0200"
                        }, {
                            "y": 119218,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0441"
                        }, {
                            "y": 50400,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0437"
                        }, {
                            "y": 7221,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0467"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0758"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0440"
                        }, {
                            "y": 200,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0172"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0001"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0165"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0684"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0490"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0018"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0139"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0388"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0457"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98359"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0438"
                        }, {
                            "y": 0,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98225"
                        }, {
                            "y": 25600,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0450"
                        }, {
                            "y": 56600,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0464"
                        }, {
                            "y": 57200,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0458"
                        }, {
                            "y": 126200,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:indigenous:in-0"
                        }, {
                            "y": 9850,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98294"
                        }, {
                            "y": 59200,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0455"
                        }, {
                            "y": 9850,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-98316"
                        }, {
                            "y": 12600,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0456"
                        }, {
                            "y": 52639,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0448"
                        }, {
                            "y": 1024,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:metis:me-2"
                        }, {
                            "y": 8645,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0447"
                        }, {
                            "y": 5079,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0446"
                        }, {
                            "y": 2192,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:first-nation:fn-0462"
                        }
                    ]
                }
            ],
            "exporting": {
                "enabled": true,
                "sourceWidth": 1400,
                "sourceHeight": 758,
                "useMultiLevelHeaders": false,
                "showTable": true
            },
            "formatNumbersToCurrency": true
        },
        "totalContributionsByClient": {
            "chart": {
                "type": "bar",
                "height": 398,
                "style": {
                    "fontFamily": "Roboto-Regular"
                },
                "events": {
                    "drilldown": [],
                    "drillup": []
                }
            },
            "title": {
                "text": "Total Tangible Net Benefits by Client",
                "style": {
                    "fontSize": "22px"
                },
                "align": "left"
            },
            "legend": {
                "itemStyle": {
                    "fontSize": "16px"
                },
                "align": "center"
            },
            "credits": {
                "enabled": false
            },
            "xAxis": {
                "categories": ["Australian Mining Co.", "Canadian Natural Resources Ltd.", "Canadian Council for Aboriginal Business", "Kiewit Construction Canada Ltd.", "Victorian Government", "Parkland County", "Husky Energy", "Pembina Pipeline Ltd.", "Suncor Energy Inc.", "Denver Paving Co", "Backwood Energy Inc."],
                "title": {
                    "text": "",
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "labels": {
                    "style": {
                        "fontSize": "16px"
                    }
                }
            },
            "yAxis": {
                "allowDecimals": false,
                "min": 0,
                "title": {
                    "text": "",
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "labels": {
                    "style": {
                        "fontSize": "16px"
                    }
                }
            },
            "colors": ["#794D81", "#367A98", "#54B0A4"],
            "tooltip": {
                "pointFormat": "{series.name}: <b>{point.y}</b><br/>",
                "valuePrefix": "$",
                "shared": true
            },
            "plotOptions": {
                "series": {
                    "stacking": "normal",
                    "pointWidth": 12
                },
                "bar": {
                    "groupPadding": 0
                }
            },
            "series": [{
                    "name": "Tangible Net Benefits: Project Benefits",
                    "stack": "stack3",
                    "data": [{
                            "y": 240375,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:amc"
                        }, {
                            "y": 52770,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:cnrl"
                        }, {
                            "y": 27965,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:ccab"
                        }, {
                            "y": 7566,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:kccl"
                        }, {
                            "y": 77500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:vict"
                        }, {
                            "y": 27500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:pc2020"
                        }, {
                            "y": 68989,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:he"
                        }, {
                            "y": 70575,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:ppl"
                        }, {
                            "y": 142413,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:sei"
                        }, {
                            "y": 8500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:dpc"
                        }, {
                            "y": 12457,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:bei"
                        }
                    ]
                }, {
                    "name": "Tangible Net Benefits: Indigenous Employment",
                    "stack": "stack3",
                    "data": [{
                            "y": 118397,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:amc"
                        }, {
                            "y": 4950,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:cnrl"
                        }, {
                            "y": 43797,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:ccab"
                        }, {
                            "y": 13950,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:kccl"
                        }, {
                            "y": 123550,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:vict"
                        }, {
                            "y": 42888,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:pc2020"
                        }, {
                            "y": 4550,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:he"
                        }, {
                            "y": 24050,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:ppl"
                        }, {
                            "y": 12500,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:sei"
                        }, {
                            "y": 166438,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:dpc"
                        }, {
                            "y": 70052,
                            "drilldown": false,
                            "identifier": "urn:nisto-link:id:vendor:bei"
                        }
                    ]
                }
            ],
            "exporting": {
                "enabled": true,
                "sourceWidth": 1400,
                "sourceHeight": 398,
                "useMultiLevelHeaders": false,
                "showTable": true
            },
            "formatNumbersToCurrency": true
        },
        "subContractingAnalysis": {
            "stacks": [{
                    "percentage": "99.56",
                    "amount": 28319517,
                    "label": "INDIGENOUS SPEND"
                }, {
                    "percentage": "0.44",
                    "amount": 125922,
                    "label": "NON-INDIGENOUS SPEND"
                }
            ],
            "totalAmount": 28445439
        },
        "employmentBalance": {
            "stacks": [{
                    "percentage": "70.13",
                    "amount": 625122,
                    "label": "INDIGENOUS EMPLOYMENT"
                }, {
                    "percentage": "29.87",
                    "amount": 266270,
                    "label": "NON-INDIGENOUS EMPLOYMENT"
                }
            ],
            "totalAmount": 891392
        },
        "employmentByCommunity": [{
                "index": 1,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0659",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Ahousaht First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 2569854,
                        "totalIndigenousProjectRevenue": 2569854,
                        "totalIndigenousSpend": 20220000,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 20220000
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 2,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:inuit:in-991000",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Aklavik Inuit",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 7246643,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 7246643
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 3,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0200",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Mississauga First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 350000,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 350000
                    },
                    "employment": {
                        "totalEmploymentValue": 21404,
                        "totalIndigenousEmploymentValue": 21404,
                        "totalManHours": 848,
                        "totalIndigenousManHours": 848
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 25465,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 4,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0441",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Paul First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 17103672,
                        "totalIndigenousProjectRevenue": 17103672,
                        "totalIndigenousSpend": 132465,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 132465
                    },
                    "employment": {
                        "totalEmploymentValue": 119218,
                        "totalIndigenousEmploymentValue": 119218,
                        "totalManHours": 4187.5,
                        "totalIndigenousManHours": 4187.5
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 235695,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 5,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0437",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Alexis Nakota Sioux Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 115000,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 115000
                    },
                    "employment": {
                        "totalEmploymentValue": 50400,
                        "totalIndigenousEmploymentValue": 50400,
                        "totalManHours": 1296,
                        "totalIndigenousManHours": 1296
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 6,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0467",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Fort McKay First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 3566524,
                        "totalIndigenousProjectRevenue": 3566524,
                        "totalIndigenousSpend": 83820,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 83820
                    },
                    "employment": {
                        "totalEmploymentValue": 7221,
                        "totalIndigenousEmploymentValue": 7221,
                        "totalManHours": 163,
                        "totalIndigenousManHours": 163
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 109254,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 7,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0758",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Acho Dene Koe First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 65000,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 65000
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 16655,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 6000,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 8,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0440",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Enoch Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 16500,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 16500
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 456,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 9,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0172",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Aamjiwnaang First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 10000,
                        "totalIndigenousProjectRevenue": 10000,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 200,
                        "totalIndigenousEmploymentValue": 200,
                        "totalManHours": 10.99,
                        "totalIndigenousManHours": 10.99
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 21171,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 69009
                        },
                        "projectBenefit": {
                            "submitted": 20100,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 100
                        }
                    }
                }
            }, {
                "index": 10,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0001",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Abegweit First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 152625,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 11,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0165",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Caldwell First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 250,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 12,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0684",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Adams Lake First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 46545,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 2500,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 13,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0490",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Aishihik First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 67500,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 14,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0018",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Acadia First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 25000,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 2500,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 15,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0139",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Chippewas of Rama First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 6500000,
                        "totalIndigenousProjectRevenue": 6500000,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 2500,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 16,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0388",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Wood Mountain First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 567876,
                        "totalIndigenousProjectRevenue": 567876,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 25000,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 17,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0457",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Swan River First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 4556,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 18,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98359",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Métis Nation of Alberta",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 563,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 19,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0438",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Alexander First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 6254885,
                        "totalIndigenousProjectRevenue": 6254885,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 6646,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 20,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0406",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Ahtahkakoop First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 75000
                        }
                    }
                }
            }, {
                "index": 21,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98225",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Ducharme Lake Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 12545785,
                        "totalIndigenousProjectRevenue": 12545785,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 75000,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 22,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98282",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Battleford Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 65000000,
                        "totalIndigenousProjectRevenue": 65000000,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 23,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0463",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Athabasca Chipewyan First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 236444,
                        "totalIndigenousProjectRevenue": 236444,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 24,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0450",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Driftpile Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 25600,
                        "totalIndigenousEmploymentValue": 25600,
                        "totalManHours": 680,
                        "totalIndigenousManHours": 680
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 25,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0464",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Cold Lake First Nations",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 56600,
                        "totalIndigenousEmploymentValue": 56600,
                        "totalManHours": 1494,
                        "totalIndigenousManHours": 1494
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 26,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0458",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Bigstone Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 57200,
                        "totalIndigenousEmploymentValue": 57200,
                        "totalManHours": 1456,
                        "totalIndigenousManHours": 1456
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 27,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:indigenous:in-0",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Indigenous (General)",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 126200,
                        "totalIndigenousEmploymentValue": 126200,
                        "totalManHours": 3705.5,
                        "totalIndigenousManHours": 3705.5
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 28,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98294",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "MacDowall Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 9850,
                        "totalIndigenousEmploymentValue": 9850,
                        "totalManHours": 255,
                        "totalIndigenousManHours": 255
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 29,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0455",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Sturgeon Lake Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 59200,
                        "totalIndigenousEmploymentValue": 59200,
                        "totalManHours": 1548,
                        "totalIndigenousManHours": 1548
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 30,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98316",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Assiniboia Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 9850,
                        "totalIndigenousEmploymentValue": 9850,
                        "totalManHours": 255,
                        "totalIndigenousManHours": 255
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 31,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0456",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Sucker Creek First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 12600,
                        "totalIndigenousEmploymentValue": 12600,
                        "totalManHours": 336,
                        "totalIndigenousManHours": 336
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 32,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0448",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Dene Tha' First Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 52639,
                        "totalIndigenousEmploymentValue": 52639,
                        "totalManHours": 1812,
                        "totalIndigenousManHours": 1812
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 33,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-2",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Métis (General)",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 1024,
                        "totalIndigenousEmploymentValue": 1024,
                        "totalManHours": 41,
                        "totalIndigenousManHours": 41
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 34,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0447",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Little Red River Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 8645,
                        "totalIndigenousEmploymentValue": 8645,
                        "totalManHours": 302.5,
                        "totalIndigenousManHours": 302.5
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 35,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0446",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Tallcree Tribal Government",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 5079,
                        "totalIndigenousEmploymentValue": 5079,
                        "totalManHours": 114.5,
                        "totalIndigenousManHours": 114.5
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 36,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:first-nation:fn-0462",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Saddle Lake Cree Nation",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 0,
                        "totalIndigenousProjectRevenue": 0,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": false,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 2192,
                        "totalIndigenousEmploymentValue": 2192,
                        "totalManHours": 88,
                        "totalIndigenousManHours": 88
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }, {
                "index": 37,
                "facetDetail": {
                    "identifier": "urn:nisto-link:id:metis:me-98267",
                    "recordType": ["IndigenousCommunity", "Organization"],
                    "displayName": "Cochin Métis",
                    "indigenousOwned": true
                },
                "totals": {
                    "spend": {
                        "totalProjectRevenue": 2300,
                        "totalIndigenousProjectRevenue": 2300,
                        "totalIndigenousSpend": 0,
                        "isRevenueIndigenous": true,
                        "totalProjectSpend": 0
                    },
                    "employment": {
                        "totalEmploymentValue": 0,
                        "totalIndigenousEmploymentValue": 0,
                        "totalManHours": 0,
                        "totalIndigenousManHours": 0
                    },
                    "benefit": {
                        "communityInvestment": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        },
                        "projectBenefit": {
                            "submitted": 0,
                            "rejected": 0,
                            "verified": 0,
                            "voided": 0
                        }
                    }
                }
            }
        ],
        "employmentByOccupation": [{
                "occupationName": "Materials Co-ordinators",
                "occupationCode": "materials-co-ordinators",
                "totalEmploymentValue": 374,
                "totalIndigenousEmploymentValue": 374,
                "totalNonIndigenousEmploymentValue": 0,
                "totalManHours": 11,
                "totalIndigenousManHours": 11
            }, {
                "occupationName": "Camp Attendants",
                "occupationCode": "camp-attendants",
                "totalEmploymentValue": 1664,
                "totalIndigenousEmploymentValue": 1664,
                "totalNonIndigenousEmploymentValue": 0,
                "totalManHours": 8,
                "totalIndigenousManHours": 8
            }, {
                "occupationName": "Cook / Kitchen Managers",
                "occupationCode": "cook---kitchen-managers",
                "totalEmploymentValue": 35665,
                "totalIndigenousEmploymentValue": 30685,
                "totalNonIndigenousEmploymentValue": 4980,
                "totalManHours": 1422,
                "totalIndigenousManHours": 1219
            }, {
                "occupationName": "Packer Operator",
                "occupationCode": "packer-operator",
                "totalEmploymentValue": 17988,
                "totalIndigenousEmploymentValue": 16188,
                "totalNonIndigenousEmploymentValue": 1800,
                "totalManHours": 491,
                "totalIndigenousManHours": 443
            }, {
                "occupationName": "Survey Helpers",
                "occupationCode": "survey-helpers",
                "totalEmploymentValue": 15184,
                "totalIndigenousEmploymentValue": 14884,
                "totalNonIndigenousEmploymentValue": 300,
                "totalManHours": 597,
                "totalIndigenousManHours": 591
            }, {
                "occupationName": "Bulldozer Operators",
                "occupationCode": "bulldozer-operators",
                "totalEmploymentValue": 200,
                "totalIndigenousEmploymentValue": 200,
                "totalNonIndigenousEmploymentValue": 0,
                "totalManHours": 10.99,
                "totalIndigenousManHours": 10.99
            }, {
                "occupationName": "Loader Operator",
                "occupationCode": "loader-operator",
                "totalEmploymentValue": 140197,
                "totalIndigenousEmploymentValue": 86168,
                "totalNonIndigenousEmploymentValue": 54029,
                "totalManHours": 3796,
                "totalIndigenousManHours": 2393.5
            }, {
                "occupationName": "Field Office Administrators",
                "occupationCode": "field-office-administrators",
                "totalEmploymentValue": 133684,
                "totalIndigenousEmploymentValue": 102710,
                "totalNonIndigenousEmploymentValue": 30974,
                "totalManHours": 5201,
                "totalIndigenousManHours": 4012
            }, {
                "occupationName": "General Labourers",
                "occupationCode": "general-labourers",
                "totalEmploymentValue": 387435,
                "totalIndigenousEmploymentValue": 289111,
                "totalNonIndigenousEmploymentValue": 98324,
                "totalManHours": 10360.5,
                "totalIndigenousManHours": 7793.5
            }, {
                "occupationName": "Assistant Superintendents",
                "occupationCode": "assistant-superintendents",
                "totalEmploymentValue": 1172,
                "totalIndigenousEmploymentValue": 1172,
                "totalNonIndigenousEmploymentValue": 0,
                "totalManHours": 5,
                "totalIndigenousManHours": 5
            }, {
                "occupationName": "Oilers",
                "occupationCode": "oilers",
                "totalEmploymentValue": 35238,
                "totalIndigenousEmploymentValue": 10517,
                "totalNonIndigenousEmploymentValue": 24721,
                "totalManHours": 858.5,
                "totalIndigenousManHours": 249
            }, {
                "occupationName": "Structural Steel Erectors",
                "occupationCode": "structural-steel-erectors",
                "totalEmploymentValue": 9056,
                "totalIndigenousEmploymentValue": 0,
                "totalNonIndigenousEmploymentValue": 9056,
                "totalManHours": 264,
                "totalIndigenousManHours": 0
            }, {
                "occupationName": "Fuel Truck and Lube Truck Operators",
                "occupationCode": "fuel-truck-and-lube-truck-operators",
                "totalEmploymentValue": 51300,
                "totalIndigenousEmploymentValue": 43200,
                "totalNonIndigenousEmploymentValue": 8100,
                "totalManHours": 1368,
                "totalIndigenousManHours": 1152
            }, {
                "occupationName": "Safety Coordinators",
                "occupationCode": "safety-coordinators",
                "totalEmploymentValue": 5007,
                "totalIndigenousEmploymentValue": 0,
                "totalNonIndigenousEmploymentValue": 5007,
                "totalManHours": 134.5,
                "totalIndigenousManHours": 0
            }, {
                "occupationName": "Bending Machine Operators",
                "occupationCode": "bending-machine-operators",
                "totalEmploymentValue": 32400,
                "totalIndigenousEmploymentValue": 16200,
                "totalNonIndigenousEmploymentValue": 16200,
                "totalManHours": 864,
                "totalIndigenousManHours": 432
            }, {
                "occupationName": "Drafting Technologists and Technicians",
                "occupationCode": "drafting-technologists-and-technicians",
                "totalEmploymentValue": 2394,
                "totalIndigenousEmploymentValue": 2394,
                "totalNonIndigenousEmploymentValue": 0,
                "totalManHours": 66,
                "totalIndigenousManHours": 66
            }, {
                "occupationName": "Administrative Supporters",
                "occupationCode": "administrative-supporters",
                "totalEmploymentValue": 1444,
                "totalIndigenousEmploymentValue": 718,
                "totalNonIndigenousEmploymentValue": 726,
                "totalManHours": 51,
                "totalIndigenousManHours": 27
            }, {
                "occupationName": "Plumbers/Gasfitters",
                "occupationCode": "plumbers-gasfitters",
                "totalEmploymentValue": 4950,
                "totalIndigenousEmploymentValue": 0,
                "totalNonIndigenousEmploymentValue": 4950,
                "totalManHours": 156,
                "totalIndigenousManHours": 0
            }, {
                "occupationName": "Faller",
                "occupationCode": "faller",
                "totalEmploymentValue": 16040,
                "totalIndigenousEmploymentValue": 8937,
                "totalNonIndigenousEmploymentValue": 7103,
                "totalManHours": 359,
                "totalIndigenousManHours": 180
            }
        ],
        "totalIndigenousCommunityInvestment": {
            "chart": {
                "style": {
                    "fontFamily": "Roboto-Regular"
                },
                "animation": false
            },
            "plotOptions": {
                "series": {
                    "animation": false
                }
            },
            "exporting": {
                "enabled": true,
                "sourceWidth": 1400,
                "useMultiLevelHeaders": false
            },
            "credits": {
                "enabled": false
            },
            "series": [{
                    "type": "treemap",
                    "layoutAlgorithm": "stripes",
                    "alternateStartingDirection": true,
                    "levels": [{
                            "level": 1,
                            "layoutAlgorithm": "sliceAndDice",
                            "dataLabels": {
                                "enabled": true,
                                "align": "center",
                                "verticalAlign": "middle",
                                "style": {
                                    "fontSize": "15px",
                                    "font": "Roboto-Regular",
                                    "textOutline": false,
                                    "fontWeight": "bold"
                                }
                            }
                        }
                    ],
                    "dataLabels": {
                        "enabled": false
                    },
                    "data": [{
                            "name": "Community",
                            "id": "community",
                            "color": "rgba(116, 62, 158, 1)"
                        }, {
                            "name": "Education",
                            "id": "education",
                            "color": "rgba(27, 107, 178, 1)"
                        }, {
                            "name": "Emergency Management",
                            "id": "emergency-management",
                            "color": "rgba(141, 181, 216, 1)"
                        }, {
                            "name": "Governance",
                            "id": "governance",
                            "color": "rgba(171, 178, 29, 1)"
                        }, {
                            "name": "Consultation, Engagement and the Duty to Consult",
                            "id": "consultation--engagement-and-the-duty-to-consult",
                            "color": "rgba(47, 208, 124, 1)"
                        }, {
                            "name": "Cultural",
                            "id": "cultural",
                            "color": "rgba(47, 208, 124, 0.8)"
                        }, {
                            "name": "Agriculture",
                            "parent": "community",
                            "value": 22172
                        }, {
                            "name": "Cultural Centers",
                            "parent": "education",
                            "value": 22000
                        }, {
                            "name": "Fire Protection",
                            "parent": "emergency-management",
                            "value": 15654
                        }, {
                            "name": "Financial Capacity",
                            "parent": "governance",
                            "value": 4545
                        }, {
                            "name": "Conferences",
                            "parent": "consultation--engagement-and-the-duty-to-consult",
                            "value": 5000
                        }, {
                            "name": "Other",
                            "parent": "cultural",
                            "value": 10000
                        }, {
                            "name": "Other Cultural Event",
                            "parent": "cultural",
                            "value": 20000
                        }, {
                            "name": "Registration",
                            "parent": "cultural",
                            "value": 10000
                        }
                    ]
                }
            ],
            "title": {
                "text": "Total Indigenous Community Investment",
                "style": {
                    "fontSize": "22px"
                },
                "align": "left"
            }
        },
        "communityInvestmentReportItems": [{
                "name": "Community",
                "value": {
                    "value": 22172,
                    "valueFormatted": "$22,172",
                    "percentage": 20.27228424353805,
                    "percentageFormatted": "20.27%"
                }
            }, {
                "name": "Education",
                "value": {
                    "value": 22000,
                    "valueFormatted": "$22,000",
                    "percentage": 20.115021349352205,
                    "percentageFormatted": "20.12%"
                }
            }, {
                "name": "Emergency Management",
                "value": {
                    "value": 15654,
                    "valueFormatted": "$15,654",
                    "percentage": 14.312752009216338,
                    "percentageFormatted": "14.31%"
                }
            }, {
                "name": "Governance",
                "value": {
                    "value": 4545,
                    "valueFormatted": "$4,545",
                    "percentage": 4.155580546945717,
                    "percentageFormatted": "4.16%"
                }
            }, {
                "name": "Consultation, Engagement and the Duty to Consult",
                "value": {
                    "value": 5000,
                    "valueFormatted": "$5,000",
                    "percentage": 4.57159576121641,
                    "percentageFormatted": "4.57%"
                }
            }, {
                "name": "Cultural",
                "value": {
                    "value": 40000,
                    "valueFormatted": "$40,000",
                    "percentage": 36.57276608973128,
                    "percentageFormatted": "36.57%"
                }
            }
        ]
    }
    return reportData;
}
