









import { getIconUrl } from '@/lib/images';
import { Component, Vue, Prop } from 'vue-property-decorator';
import MenuComponent from '../components/MenuComponent.vue';

@Component({
  components: {
    MenuComponent,
  },
})
export default class MobileNavComponent extends Vue {
  @Prop() public menu: any;

  private isSelected: boolean = false;
  private getIcon(): string {
    return getIconUrl(this.isSelected ? 'icon_close_white.png' : 'icon_hamburger.png');
  }
  private hideShow() {
    this.isSelected = !this.isSelected;
  }
}
