






import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';

@Component
export default class NonAuthComponent extends BaseComponent {

  protected mounted() {
  }

}
