export const rules = {
    required: (v: any) => !!v && v !== null || 'Required',
    requiredPercentage: (v: any) => (v >= 0 && v <= 100) || 'Between 0 and 100',
    requiredPositiveNumber: (v: any) => ((!!v && v !== null && v !== '') || v >= 0) || 'Required',
    requiredObject: (v: any) => (!!v.identifier || !!v.code) || 'Required',
    requiredObjectMulti: (v: any) => !!v[0] || 'Required',
    requiredDelete: (v: any) => v.toLowerCase() === 'delete' || 'Enter the text: DELETE',
    min: (v: any) => (v && v.length >= 8) || 'Min 8 characters',
    email: (v: any) => (v ? v.match(regex.validEmail) != null : true) || 'Invalid Email address',
    phone: (v: any) => (v ? v.match(regex.validPhone) != null : true) || 'Invalid Phone number',
    currency: (v: any) => (v > 0) || 'Invalid amount',
    signedCurrency: (v: any) => (v > 0 || v < 0 ) || 'Invalid amount',
    rate: (v: any) => (v >= 0 || !v)  || 'Invalid amount',
    signedRate: (v: any) => ((v >= 0 || v <= 0 ) || !v)  || 'Invalid amount',
    daysPerWeek: (v: any) => v > 0 && v <= 7 && (v % 1 === 0 || v % 1 === 0.5)  || 'Invalid days worked',
    hoursPerDay: (v: any) => v >= 0 && (v % 1 === 0 || v % 0.25 === 0)  || 'Invalid day length',
    positiveInteger: (v: any) => v > 0 && Number.isInteger(Number(v))  || 'Invalid amount',
};

// Flatfile regex expressions.
export const regex = {
    validYear: /^([12]\d{3})$/,
    validDate: /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,
    validPercentage: /(^100$)|(^([1-9]([0-9])?|0)$)/,
    validPhone: /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/,
    validEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
